body {
  overflow-x: hidden;
}

.ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.ratio::before {
  display: block;
  width: 100%;
  content: "";
}

.ratio-1\:1::before {
  padding-bottom: 100%;
}

.ratio-2\:1::before {
  padding-bottom: 50%;
}

.ratio-3\:2::before {
  padding-bottom: 66.66667%;
}

.ratio-3\:4::before {
  padding-bottom: 133.33333%;
}

.ratio-4\:3::before {
  padding-bottom: 75%;
}

.ratio-4\:1::before {
  padding-bottom: 25%;
}

.ratio-5\:4::before {
  padding-bottom: 80%;
}

.ratio-8\:5::before {
  padding-bottom: 62.5%;
}

.ratio-15\:9::before {
  padding-bottom: 60%;
}

.ratio-16\:9::before {
  padding-bottom: 56.25%;
}

.ratio-64\:45::before {
  padding-bottom: 70.3125%;
}

.ratio-6\:5::before {
  padding-bottom: 83.33333%;
}

.ratio-23\:18::before {
  padding-bottom: 78.26087%;
}

.ratio-41\:35::before {
  padding-bottom: 85.36585%;
}

.ratio-41\:45::before {
  padding-bottom: 109.7561%;
}

.ratio-63\:55::before {
  padding-bottom: 87.30159%;
}

:root {
  --font-primary: "Jost", sans-serif;
}

:root {
  --text-4: 4px;
  --text-7: 7px;
  --text-10: 10px;
  --text-12: 12px;
  --text-13: 13px;
  --text-14: 14px;
  --text-15: 15px;
  --text-16: 16px;
  --text-18: 18px;
  --text-20: 20px;
  --text-22: 22px;
  --text-24: 24px;
  --text-25: 25px;
  --text-26: 26px;
  --text-30: 30px;
  --text-40: 40px;
  --text-50: 50px;
  --text-60: 60px;
  --text-80: 80px;
}

:root {
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-border: #DDDDDD;
  --color-dark-1: #051036;
  --color-dark-2: #0D2857;
  --color-dark-3: #13357B;
  --color-dark-4: #163C8C;
  --color-light-1: #697488;
  --color-light-2: #F5F5F5;
  --color-light-3: #FBFCFF;
  --color-blue-1: #3554D1;
  --color-blue-2: #E5F0FD;
  --color-green-1: #EBFCEA;
  --color-green-2: #008009;
  --color-yellow-1: #F8D448;
  --color-yellow-2: #E1C03F;
  --color-yellow-3: #FFC700;
  --color-yellow-4: #FFF8DD;
  --color-brown-1: #923E01;
  --color-purple-1: #7E53F9;
  --color-red-1: #D93025;
  --color-red-2: #F1416C;
  --color-red-3: #FFF5F8;
  --color-info-1: #CDE9F6;
  --color-info-2: #4780AA;
  --color-warning-1: #F7F3D7;
  --color-warning-2: #927238;
  --color-error-1: #ECC8C5;
  --color-error-2: #AB3331;
  --color-success-1: #DEF2D7;
  --color-success-2: #5B7052;
}

[data-anim-wrap] {
  pointer-events: none;
}

[data-anim-wrap].animated {
  pointer-events: auto;
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}

[data-anim^='slide-'],
[data-anim-child^='slide-'] {
  /* opacity: 0; */
  transition-property: opacity, transform;
  pointer-events: none;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-anim^='slide-'].is-in-view,
[data-anim-child^='slide-'].is-in-view {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}


[data-anim^='slide-up'],
[data-anim-child^='slide-up'] {
  transform: translate3d(0, 40px, 0);
}

[data-anim^='slide-down'],
[data-anim-child^='slide-down'] {
  transform: translate3d(0, -40px, 0);
}

[data-anim^='slide-right'],
[data-anim-child^='slide-right'] {
  transform: translate3d(-40px, 0, 0);
}

[data-anim^='slide-left'],
[data-anim-child^='slide-left'] {
  transform: translate3d(40px, 0, 0);
}

[data-anim^='fade'],
[data-anim-child^='fade'] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-anim^='fade'].is-in-view,
[data-anim-child^='fade'].is-in-view {
  opacity: 1;
  pointer-events: auto;
}

[data-anim*="delay-1"],
[data-anim-child*="delay-1"] {
  transition-delay: 0.1s;
  animation-delay: 0.1s;
}

[data-anim*="delay-1"]::after,
[data-anim-child*="delay-1"]::after {
  animation-delay: 0.1s;
}

[data-anim*="delay-1"]>*,
[data-anim-child*="delay-1"]>* {
  animation-delay: 0.1s;
}

[data-anim*="delay-2"],
[data-anim-child*="delay-2"] {
  transition-delay: 0.2s;
  animation-delay: 0.2s;
}

[data-anim*="delay-2"]::after,
[data-anim-child*="delay-2"]::after {
  animation-delay: 0.2s;
}

[data-anim*="delay-2"]>*,
[data-anim-child*="delay-2"]>* {
  animation-delay: 0.2s;
}

[data-anim*="delay-3"],
[data-anim-child*="delay-3"] {
  transition-delay: 0.3s;
  animation-delay: 0.3s;
}

[data-anim*="delay-3"]::after,
[data-anim-child*="delay-3"]::after {
  animation-delay: 0.3s;
}

[data-anim*="delay-3"]>*,
[data-anim-child*="delay-3"]>* {
  animation-delay: 0.3s;
}

[data-anim*="delay-4"],
[data-anim-child*="delay-4"] {
  transition-delay: 0.4s;
  animation-delay: 0.4s;
}

[data-anim*="delay-4"]::after,
[data-anim-child*="delay-4"]::after {
  animation-delay: 0.4s;
}

[data-anim*="delay-4"]>*,
[data-anim-child*="delay-4"]>* {
  animation-delay: 0.4s;
}

[data-anim*="delay-5"],
[data-anim-child*="delay-5"] {
  transition-delay: 0.5s;
  animation-delay: 0.5s;
}

[data-anim*="delay-5"]::after,
[data-anim-child*="delay-5"]::after {
  animation-delay: 0.5s;
}

[data-anim*="delay-5"]>*,
[data-anim-child*="delay-5"]>* {
  animation-delay: 0.5s;
}

[data-anim*="delay-6"],
[data-anim-child*="delay-6"] {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

[data-anim*="delay-6"]::after,
[data-anim-child*="delay-6"]::after {
  animation-delay: 0.6s;
}

[data-anim*="delay-6"]>*,
[data-anim-child*="delay-6"]>* {
  animation-delay: 0.6s;
}

[data-anim*="delay-7"],
[data-anim-child*="delay-7"] {
  transition-delay: 0.7s;
  animation-delay: 0.7s;
}

[data-anim*="delay-7"]::after,
[data-anim-child*="delay-7"]::after {
  animation-delay: 0.7s;
}

[data-anim*="delay-7"]>*,
[data-anim-child*="delay-7"]>* {
  animation-delay: 0.7s;
}

[data-anim*="delay-8"],
[data-anim-child*="delay-8"] {
  transition-delay: 0.8s;
  animation-delay: 0.8s;
}

[data-anim*="delay-8"]::after,
[data-anim-child*="delay-8"]::after {
  animation-delay: 0.8s;
}

[data-anim*="delay-8"]>*,
[data-anim-child*="delay-8"]>* {
  animation-delay: 0.8s;
}

[data-anim*="delay-9"],
[data-anim-child*="delay-9"] {
  transition-delay: 0.9s;
  animation-delay: 0.9s;
}

[data-anim*="delay-9"]::after,
[data-anim-child*="delay-9"]::after {
  animation-delay: 0.9s;
}

[data-anim*="delay-9"]>*,
[data-anim-child*="delay-9"]>* {
  animation-delay: 0.9s;
}

[data-anim*="delay-10"],
[data-anim-child*="delay-10"] {
  transition-delay: 1s;
  animation-delay: 1s;
}

[data-anim*="delay-10"]::after,
[data-anim-child*="delay-10"]::after {
  animation-delay: 1s;
}

[data-anim*="delay-10"]>*,
[data-anim-child*="delay-10"]>* {
  animation-delay: 1s;
}

[data-anim*="delay-11"],
[data-anim-child*="delay-11"] {
  transition-delay: 1.1s;
  animation-delay: 1.1s;
}

[data-anim*="delay-11"]::after,
[data-anim-child*="delay-11"]::after {
  animation-delay: 1.1s;
}

[data-anim*="delay-11"]>*,
[data-anim-child*="delay-11"]>* {
  animation-delay: 1.1s;
}

[data-anim*="delay-12"],
[data-anim-child*="delay-12"] {
  transition-delay: 1.2s;
  animation-delay: 1.2s;
}

[data-anim*="delay-12"]::after,
[data-anim-child*="delay-12"]::after {
  animation-delay: 1.2s;
}

[data-anim*="delay-12"]>*,
[data-anim-child*="delay-12"]>* {
  animation-delay: 1.2s;
}

[data-anim*="delay-13"],
[data-anim-child*="delay-13"] {
  transition-delay: 1.3s;
  animation-delay: 1.3s;
}

[data-anim*="delay-13"]::after,
[data-anim-child*="delay-13"]::after {
  animation-delay: 1.3s;
}

[data-anim*="delay-13"]>*,
[data-anim-child*="delay-13"]>* {
  animation-delay: 1.3s;
}

[data-anim*="delay-14"],
[data-anim-child*="delay-14"] {
  transition-delay: 1.4s;
  animation-delay: 1.4s;
}

[data-anim*="delay-14"]::after,
[data-anim-child*="delay-14"]::after {
  animation-delay: 1.4s;
}

[data-anim*="delay-14"]>*,
[data-anim-child*="delay-14"]>* {
  animation-delay: 1.4s;
}

[data-anim*="delay-15"],
[data-anim-child*="delay-15"] {
  transition-delay: 1.5s;
  animation-delay: 1.5s;
}

[data-anim*="delay-15"]::after,
[data-anim-child*="delay-15"]::after {
  animation-delay: 1.5s;
}

[data-anim*="delay-15"]>*,
[data-anim-child*="delay-15"]>* {
  animation-delay: 1.5s;
}

[data-anim*="delay-16"],
[data-anim-child*="delay-16"] {
  transition-delay: 1.6s;
  animation-delay: 1.6s;
}

[data-anim*="delay-16"]::after,
[data-anim-child*="delay-16"]::after {
  animation-delay: 1.6s;
}

[data-anim*="delay-16"]>*,
[data-anim-child*="delay-16"]>* {
  animation-delay: 1.6s;
}

[data-anim*="delay-17"],
[data-anim-child*="delay-17"] {
  transition-delay: 1.7s;
  animation-delay: 1.7s;
}

[data-anim*="delay-17"]::after,
[data-anim-child*="delay-17"]::after {
  animation-delay: 1.7s;
}

[data-anim*="delay-17"]>*,
[data-anim-child*="delay-17"]>* {
  animation-delay: 1.7s;
}

[data-anim*="delay-18"],
[data-anim-child*="delay-18"] {
  transition-delay: 1.8s;
  animation-delay: 1.8s;
}

[data-anim*="delay-18"]::after,
[data-anim-child*="delay-18"]::after {
  animation-delay: 1.8s;
}

[data-anim*="delay-18"]>*,
[data-anim-child*="delay-18"]>* {
  animation-delay: 1.8s;
}

[data-anim*="delay-19"],
[data-anim-child*="delay-19"] {
  transition-delay: 1.9s;
  animation-delay: 1.9s;
}

[data-anim*="delay-19"]::after,
[data-anim-child*="delay-19"]::after {
  animation-delay: 1.9s;
}

[data-anim*="delay-19"]>*,
[data-anim-child*="delay-19"]>* {
  animation-delay: 1.9s;
}

[data-anim*="delay-20"],
[data-anim-child*="delay-20"] {
  transition-delay: 2s;
  animation-delay: 2s;
}

[data-anim*="delay-20"]::after,
[data-anim-child*="delay-20"]::after {
  animation-delay: 2s;
}

[data-anim*="delay-20"]>*,
[data-anim-child*="delay-20"]>* {
  animation-delay: 2s;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  background-color: white;
  color: var(--color-dark-1);
  font-size: 16px;
  line-height: 1.875;
}

select,
input:not([type="range"]),
textarea {
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: 16px;
  color: var(--color-light-1);
}

button {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-decoration: none;
}

button:focus {
  outline: 0;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.button.-md {
  padding: 14px 30px;
}

.button.-lg {
  padding: 19px 30px;
}

.button.-min-180 {
  min-width: 180px;
}

.button.-underline:hover {
  text-decoration: underline;
}

.button.-white:hover {
  border-color: var(--color-white);
  background-color: var(--color-white) !important;
  color: white !important;
}

.button.-outline-white {
  border-color: var(--color-white);
}

.button.-outline-white:hover {
  background-color: var(--color-white);
  border-color: transparent;
  color: white !important;
}

.button.-black:hover {
  border-color: var(--color-black);
  background-color: var(--color-black) !important;
  color: white !important;
}

.button.-outline-black {
  border-color: var(--color-black);
}

.button.-outline-black:hover {
  background-color: var(--color-black);
  border-color: transparent;
  color: white !important;
}

.button.-border:hover {
  border-color: var(--color-border);
  background-color: var(--color-border) !important;
  color: white !important;
}

.button.-outline-border {
  border-color: var(--color-border);
}

.button.-outline-border:hover {
  background-color: var(--color-border);
  border-color: transparent;
  color: white !important;
}

.button.-dark-1:hover {
  border-color: var(--color-dark-1);
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.button.-outline-dark-1 {
  border-color: var(--color-dark-1);
}

.button.-outline-dark-1:hover {
  background-color: var(--color-dark-1);
  border-color: transparent;
  color: white !important;
}

.button.-dark-2:hover {
  border-color: var(--color-dark-2);
  background-color: var(--color-dark-2) !important;
  color: white !important;
}

.button.-outline-dark-2 {
  border-color: var(--color-dark-2);
}

.button.-outline-dark-2:hover {
  background-color: var(--color-dark-2);
  border-color: transparent;
  color: white !important;
}

.button.-dark-3:hover {
  border-color: var(--color-dark-3);
  background-color: var(--color-dark-3) !important;
  color: white !important;
}

.button.-outline-dark-3 {
  border-color: var(--color-dark-3);
}

.button.-outline-dark-3:hover {
  background-color: var(--color-dark-3);
  border-color: transparent;
  color: white !important;
}

.button.-dark-4:hover {
  border-color: var(--color-dark-4);
  background-color: var(--color-dark-4) !important;
  color: white !important;
}

.button.-outline-dark-4 {
  border-color: var(--color-dark-4);
}

.button.-outline-dark-4:hover {
  background-color: var(--color-dark-4);
  border-color: transparent;
  color: white !important;
}

.button.-light-1:hover {
  border-color: var(--color-light-1);
  background-color: var(--color-light-1) !important;
  color: white !important;
}

.button.-outline-light-1 {
  border-color: var(--color-light-1);
}

.button.-outline-light-1:hover {
  background-color: var(--color-light-1);
  border-color: transparent;
  color: white !important;
}

.button.-light-2:hover {
  border-color: var(--color-light-2);
  background-color: var(--color-light-2) !important;
  color: white !important;
}

.button.-outline-light-2 {
  border-color: var(--color-light-2);
}

.button.-outline-light-2:hover {
  background-color: var(--color-light-2);
  border-color: transparent;
  color: white !important;
}

.button.-light-3:hover {
  border-color: var(--color-light-3);
  background-color: var(--color-light-3) !important;
  color: white !important;
}

.button.-outline-light-3 {
  border-color: var(--color-light-3);
}

.button.-outline-light-3:hover {
  background-color: var(--color-light-3);
  border-color: transparent;
  color: white !important;
}

.button.-blue-1:hover {
  border-color: var(--color-blue-1);
  background-color: var(--color-blue-1) !important;
  color: white !important;
}

.button.-outline-blue-1 {
  border-color: var(--color-blue-1);
}

.button.-outline-blue-1:hover {
  background-color: var(--color-blue-1);
  border-color: transparent;
  color: white !important;
}

.button.-blue-2:hover {
  border-color: var(--color-blue-2);
  background-color: var(--color-blue-2) !important;
  color: white !important;
}

.button.-outline-blue-2 {
  border-color: var(--color-blue-2);
}

.button.-outline-blue-2:hover {
  background-color: var(--color-blue-2);
  border-color: transparent;
  color: white !important;
}

.button.-green-1:hover {
  border-color: var(--color-green-1);
  background-color: var(--color-green-1) !important;
  color: white !important;
}

.button.-outline-green-1 {
  border-color: var(--color-green-1);
}

.button.-outline-green-1:hover {
  background-color: var(--color-green-1);
  border-color: transparent;
  color: white !important;
}

.button.-green-2:hover {
  border-color: var(--color-green-2);
  background-color: var(--color-green-2) !important;
  color: white !important;
}

.button.-outline-green-2 {
  border-color: var(--color-green-2);
}

.button.-outline-green-2:hover {
  background-color: var(--color-green-2);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-1:hover {
  border-color: var(--color-yellow-1);
  background-color: var(--color-yellow-1) !important;
  color: white !important;
}

.button.-outline-yellow-1 {
  border-color: var(--color-yellow-1);
}

.button.-outline-yellow-1:hover {
  background-color: var(--color-yellow-1);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-2:hover {
  border-color: var(--color-yellow-2);
  background-color: var(--color-yellow-2) !important;
  color: white !important;
}

.button.-outline-yellow-2 {
  border-color: var(--color-yellow-2);
}

.button.-outline-yellow-2:hover {
  background-color: var(--color-yellow-2);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-3:hover {
  border-color: var(--color-yellow-3);
  background-color: var(--color-yellow-3) !important;
  color: white !important;
}

.button.-outline-yellow-3 {
  border-color: var(--color-yellow-3);
}

.button.-outline-yellow-3:hover {
  background-color: var(--color-yellow-3);
  border-color: transparent;
  color: white !important;
}

.button.-yellow-4:hover {
  border-color: var(--color-yellow-4);
  background-color: var(--color-yellow-4) !important;
  color: white !important;
}

.button.-outline-yellow-4 {
  border-color: var(--color-yellow-4);
}

.button.-outline-yellow-4:hover {
  background-color: var(--color-yellow-4);
  border-color: transparent;
  color: white !important;
}

.button.-brown-1:hover {
  border-color: var(--color-brown-1);
  background-color: var(--color-brown-1) !important;
  color: white !important;
}

.button.-outline-brown-1 {
  border-color: var(--color-brown-1);
}

.button.-outline-brown-1:hover {
  background-color: var(--color-brown-1);
  border-color: transparent;
  color: white !important;
}

.button.-purple-1:hover {
  border-color: var(--color-purple-1);
  background-color: var(--color-purple-1) !important;
  color: white !important;
}

.button.-outline-purple-1 {
  border-color: var(--color-purple-1);
}

.button.-outline-purple-1:hover {
  background-color: var(--color-purple-1);
  border-color: transparent;
  color: white !important;
}

.button.-red-1:hover {
  border-color: var(--color-red-1);
  background-color: var(--color-red-1) !important;
  color: white !important;
}

.button.-outline-red-1 {
  border-color: var(--color-red-1);
}

.button.-outline-red-1:hover {
  background-color: var(--color-red-1);
  border-color: transparent;
  color: white !important;
}

.button.-red-2:hover {
  border-color: var(--color-red-2);
  background-color: var(--color-red-2) !important;
  color: white !important;
}

.button.-outline-red-2 {
  border-color: var(--color-red-2);
}

.button.-outline-red-2:hover {
  background-color: var(--color-red-2);
  border-color: transparent;
  color: white !important;
}

.button.-red-3:hover {
  border-color: var(--color-red-3);
  background-color: var(--color-red-3) !important;
  color: white !important;
}

.button.-outline-red-3 {
  border-color: var(--color-red-3);
}

.button.-outline-red-3:hover {
  background-color: var(--color-red-3);
  border-color: transparent;
  color: white !important;
}

.button.-info-1:hover {
  border-color: var(--color-info-1);
  background-color: var(--color-info-1) !important;
  color: white !important;
}

.button.-outline-info-1 {
  border-color: var(--color-info-1);
}

.button.-outline-info-1:hover {
  background-color: var(--color-info-1);
  border-color: transparent;
  color: white !important;
}

.button.-info-2:hover {
  border-color: var(--color-info-2);
  background-color: var(--color-info-2) !important;
  color: white !important;
}

.button.-outline-info-2 {
  border-color: var(--color-info-2);
}

.button.-outline-info-2:hover {
  background-color: var(--color-info-2);
  border-color: transparent;
  color: white !important;
}

.button.-warning-1:hover {
  border-color: var(--color-warning-1);
  background-color: var(--color-warning-1) !important;
  color: white !important;
}

.button.-outline-warning-1 {
  border-color: var(--color-warning-1);
}

.button.-outline-warning-1:hover {
  background-color: var(--color-warning-1);
  border-color: transparent;
  color: white !important;
}

.button.-warning-2:hover {
  border-color: var(--color-warning-2);
  background-color: var(--color-warning-2) !important;
  color: white !important;
}

.button.-outline-warning-2 {
  border-color: var(--color-warning-2);
}

.button.-outline-warning-2:hover {
  background-color: var(--color-warning-2);
  border-color: transparent;
  color: white !important;
}

.button.-error-1:hover {
  border-color: var(--color-error-1);
  background-color: var(--color-error-1) !important;
  color: white !important;
}

.button.-outline-error-1 {
  border-color: var(--color-error-1);
}

.button.-outline-error-1:hover {
  background-color: var(--color-error-1);
  border-color: transparent;
  color: white !important;
}

.button.-error-2:hover {
  border-color: var(--color-error-2);
  background-color: var(--color-error-2) !important;
  color: white !important;
}

.button.-outline-error-2 {
  border-color: var(--color-error-2);
}

.button.-outline-error-2:hover {
  background-color: var(--color-error-2);
  border-color: transparent;
  color: white !important;
}

.button.-success-1:hover {
  border-color: var(--color-success-1);
  background-color: var(--color-success-1) !important;
  color: white !important;
}

.button.-outline-success-1 {
  border-color: var(--color-success-1);
}

.button.-outline-success-1:hover {
  background-color: var(--color-success-1);
  border-color: transparent;
  color: white !important;
}

.button.-success-2:hover {
  border-color: var(--color-success-2);
  background-color: var(--color-success-2) !important;
  color: white !important;
}

.button.-outline-success-2 {
  border-color: var(--color-success-2);
}

.button.-outline-success-2:hover {
  background-color: var(--color-success-2);
  border-color: transparent;
  color: white !important;
}

.button.-white:hover {
  background-color: var(--color-light-3) !important;
  color: var(--color-dark-1) !important;
}

.button.-blue-1-05:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
  color: var(--color-blue-1) !important;
}

.button.-outline-white:hover {
  background-color: white !important;
  color: var(--color-dark-1) !important;
}

.contact-form select,
.contact-form input:not([type="range"]),
.contact-form textarea {
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.contact-form select:focus,
.contact-form input:not([type="range"]):focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-form ::-moz-placeholder {
  font-weight: 400;
}

.contact-form ::placeholder {
  font-weight: 400;
}

input.text-white::-moz-placeholder {
  color: white;
}

input.text-white::placeholder {
  color: white;
}

.form-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
}

.form-checkbox label {
  margin: 0;
  margin-left: 9px;
  font-size: 14px;
}

.form-checkbox__mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-dark-1);
  border-radius: 4px;
}

.form-checkbox__icon {
  display: none;
  position: absolute;
  font-size: 8px;
  color: white;
}

.form-checkbox input {
  cursor: pointer;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.form-checkbox input:checked~.form-checkbox__mark {
  background-color: var(--color-blue-1);
  border-color: var(--color-blue-1);
}

.form-checkbox input:checked~.form-checkbox__mark .form-checkbox__icon {
  display: block;
}

.form-checkbox:hover input~.form-checkbox__mark {
  background-color: var(--color-blue-1);
}

.form-checkbox:hover input:checked~.form-checkbox__mark {
  background-color: var(--color-blue-1);
}

.form-radio {
  display: flex;
  align-items: center;
}

.form-radio .radio {
  cursor: pointer;
  position: relative;
  display: block;
}

.form-radio .radio__mark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  border: 1px solid var(--color-dark-1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-radio .radio__icon {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--color-white);
  transform-origin: center;
  opacity: 1;
  transform: scale(0.2);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-radio .radio input {
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.form-radio .radio input:checked~.radio__mark {
  border-color: var(--color-blue-1);
  background-color: var(--color-blue-1);
}

.form-radio .radio input:checked~.radio__mark .radio__icon {
  opacity: 1;
  transform: scale(1);
}

.form-switch .switch {
  position: relative;
  width: 60px;
  height: 30px;
}

.form-switch .switch__slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
  transition: .3s;
  border-radius: 30px;
  border: 1px solid var(--color-blue-1);
}

.form-switch .switch__slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: var(--color-blue-1);
  border-radius: 50%;
  transition: .3s;
}

.form-switch .switch input {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-switch .switch input:checked+.switch__slider {
  background-color: var(--color-blue-1);
}

.form-switch .switch input:checked+.switch__slider::before {
  transform: translateX(29px);
  background-color: white;
}

.single-field input {
  border-radius: 4px;
  padding: 17px 20px;
  font-size: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.single-field.-w-410 input {
  width: 410px;
}

@media (max-width: 991px) {
  .single-field.-w-410 input {
    width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.45;
  font-weight: 600;
}

.text-4 {
  font-size: var(--text-4) !important;
}

.text-7 {
  font-size: var(--text-7) !important;
}

.text-10 {
  font-size: var(--text-10) !important;
}

.text-12 {
  font-size: var(--text-12) !important;
}

.text-13 {
  font-size: var(--text-13) !important;
}

.text-14 {
  font-size: var(--text-14) !important;
}

.text-15 {
  font-size: var(--text-15) !important;
}

.text-16 {
  font-size: var(--text-16) !important;
}

.text-18 {
  font-size: var(--text-18) !important;
}

.text-20 {
  font-size: var(--text-20) !important;
}

.text-22 {
  font-size: var(--text-22) !important;
}

.text-24 {
  font-size: var(--text-24) !important;
}

.text-25 {
  font-size: var(--text-25) !important;
}

.text-26 {
  font-size: var(--text-26) !important;
}

.text-30 {
  font-size: var(--text-30) !important;
}

.text-40 {
  font-size: var(--text-40) !important;
}

.text-50 {
  font-size: var(--text-50) !important;
}

.text-60 {
  font-size: var(--text-60) !important;
}

.text-80 {
  font-size: var(--text-80) !important;
}

@media (max-width: 1199px) {
  .xl\:text-4 {
    font-size: var(--text-4) !important;
  }

  .xl\:text-7 {
    font-size: var(--text-7) !important;
  }

  .xl\:text-10 {
    font-size: var(--text-10) !important;
  }

  .xl\:text-12 {
    font-size: var(--text-12) !important;
  }

  .xl\:text-13 {
    font-size: var(--text-13) !important;
  }

  .xl\:text-14 {
    font-size: var(--text-14) !important;
  }

  .xl\:text-15 {
    font-size: var(--text-15) !important;
  }

  .xl\:text-16 {
    font-size: var(--text-16) !important;
  }

  .xl\:text-18 {
    font-size: var(--text-18) !important;
  }

  .xl\:text-20 {
    font-size: var(--text-20) !important;
  }

  .xl\:text-22 {
    font-size: var(--text-22) !important;
  }

  .xl\:text-24 {
    font-size: var(--text-24) !important;
  }

  .xl\:text-25 {
    font-size: var(--text-25) !important;
  }

  .xl\:text-26 {
    font-size: var(--text-26) !important;
  }

  .xl\:text-30 {
    font-size: var(--text-30) !important;
  }

  .xl\:text-40 {
    font-size: var(--text-40) !important;
  }

  .xl\:text-50 {
    font-size: var(--text-50) !important;
  }

  .xl\:text-60 {
    font-size: var(--text-60) !important;
  }

  .xl\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-4 {
    font-size: var(--text-4) !important;
  }

  .lg\:text-7 {
    font-size: var(--text-7) !important;
  }

  .lg\:text-10 {
    font-size: var(--text-10) !important;
  }

  .lg\:text-12 {
    font-size: var(--text-12) !important;
  }

  .lg\:text-13 {
    font-size: var(--text-13) !important;
  }

  .lg\:text-14 {
    font-size: var(--text-14) !important;
  }

  .lg\:text-15 {
    font-size: var(--text-15) !important;
  }

  .lg\:text-16 {
    font-size: var(--text-16) !important;
  }

  .lg\:text-18 {
    font-size: var(--text-18) !important;
  }

  .lg\:text-20 {
    font-size: var(--text-20) !important;
  }

  .lg\:text-22 {
    font-size: var(--text-22) !important;
  }

  .lg\:text-24 {
    font-size: var(--text-24) !important;
  }

  .lg\:text-25 {
    font-size: var(--text-25) !important;
  }

  .lg\:text-26 {
    font-size: var(--text-26) !important;
  }

  .lg\:text-30 {
    font-size: var(--text-30) !important;
  }

  .lg\:text-40 {
    font-size: var(--text-40) !important;
  }

  .lg\:text-50 {
    font-size: var(--text-50) !important;
  }

  .lg\:text-60 {
    font-size: var(--text-60) !important;
  }

  .lg\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 767px) {
  .md\:text-4 {
    font-size: var(--text-4) !important;
  }

  .md\:text-7 {
    font-size: var(--text-7) !important;
  }

  .md\:text-10 {
    font-size: var(--text-10) !important;
  }

  .md\:text-12 {
    font-size: var(--text-12) !important;
  }

  .md\:text-13 {
    font-size: var(--text-13) !important;
  }

  .md\:text-14 {
    font-size: var(--text-14) !important;
  }

  .md\:text-15 {
    font-size: var(--text-15) !important;
  }

  .md\:text-16 {
    font-size: var(--text-16) !important;
  }

  .md\:text-18 {
    font-size: var(--text-18) !important;
  }

  .md\:text-20 {
    font-size: var(--text-20) !important;
  }

  .md\:text-22 {
    font-size: var(--text-22) !important;
  }

  .md\:text-24 {
    font-size: var(--text-24) !important;
  }

  .md\:text-25 {
    font-size: var(--text-25) !important;
  }

  .md\:text-26 {
    font-size: var(--text-26) !important;
  }

  .md\:text-30 {
    font-size: var(--text-30) !important;
  }

  .md\:text-40 {
    font-size: var(--text-40) !important;
  }

  .md\:text-50 {
    font-size: var(--text-50) !important;
  }

  .md\:text-60 {
    font-size: var(--text-60) !important;
  }

  .md\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-4 {
    font-size: var(--text-4) !important;
  }

  .sm\:text-7 {
    font-size: var(--text-7) !important;
  }

  .sm\:text-10 {
    font-size: var(--text-10) !important;
  }

  .sm\:text-12 {
    font-size: var(--text-12) !important;
  }

  .sm\:text-13 {
    font-size: var(--text-13) !important;
  }

  .sm\:text-14 {
    font-size: var(--text-14) !important;
  }

  .sm\:text-15 {
    font-size: var(--text-15) !important;
  }

  .sm\:text-16 {
    font-size: var(--text-16) !important;
  }

  .sm\:text-18 {
    font-size: var(--text-18) !important;
  }

  .sm\:text-20 {
    font-size: var(--text-20) !important;
  }

  .sm\:text-22 {
    font-size: var(--text-22) !important;
  }

  .sm\:text-24 {
    font-size: var(--text-24) !important;
  }

  .sm\:text-25 {
    font-size: var(--text-25) !important;
  }

  .sm\:text-26 {
    font-size: var(--text-26) !important;
  }

  .sm\:text-30 {
    font-size: var(--text-30) !important;
  }

  .sm\:text-40 {
    font-size: var(--text-40) !important;
  }

  .sm\:text-50 {
    font-size: var(--text-50) !important;
  }

  .sm\:text-60 {
    font-size: var(--text-60) !important;
  }

  .sm\:text-80 {
    font-size: var(--text-80) !important;
  }
}

@media (max-width: 479px) {
  .xs\:text-4 {
    font-size: var(--text-4) !important;
  }

  .xs\:text-7 {
    font-size: var(--text-7) !important;
  }

  .xs\:text-10 {
    font-size: var(--text-10) !important;
  }

  .xs\:text-12 {
    font-size: var(--text-12) !important;
  }

  .xs\:text-13 {
    font-size: var(--text-13) !important;
  }

  .xs\:text-14 {
    font-size: var(--text-14) !important;
  }

  .xs\:text-15 {
    font-size: var(--text-15) !important;
  }

  .xs\:text-16 {
    font-size: var(--text-16) !important;
  }

  .xs\:text-18 {
    font-size: var(--text-18) !important;
  }

  .xs\:text-20 {
    font-size: var(--text-20) !important;
  }

  .xs\:text-22 {
    font-size: var(--text-22) !important;
  }

  .xs\:text-24 {
    font-size: var(--text-24) !important;
  }

  .xs\:text-25 {
    font-size: var(--text-25) !important;
  }

  .xs\:text-26 {
    font-size: var(--text-26) !important;
  }

  .xs\:text-30 {
    font-size: var(--text-30) !important;
  }

  .xs\:text-40 {
    font-size: var(--text-40) !important;
  }

  .xs\:text-50 {
    font-size: var(--text-50) !important;
  }

  .xs\:text-60 {
    font-size: var(--text-60) !important;
  }

  .xs\:text-80 {
    font-size: var(--text-80) !important;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

@media (max-width: 1199px) {
  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 991px) {
  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 767px) {
  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-right {
    text-align: right !important;
  }

  .md\:text-left {
    text-align: left !important;
  }
}

@media (max-width: 575px) {
  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.sectionTitle.-md .sectionTitle__title {
  font-size: var(--text-30);
}


.sectionTitle.-md .sectionTitle__text {
  max-width: 900px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .sectionTitle.-md .sectionTitle__title {
    font-size: var(--text-26);
  }
}

.sectionTitle.-lg .sectionTitle__title {
  font-size: var(--text-40);
}

.masthead.-type-1 {
  position: relative;
  padding-top: 330px;
  padding-bottom: 210px;
}

@media (max-width: 991px) {
  .masthead.-type-1 {
    padding-top: 260px;
    padding-bottom: 180px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-1 {
    padding-top: 160px;
    padding-bottom: 20px;
  }
}

.masthead.-type-1 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-1 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-1 .masthead__bg::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5) 0%, #051036 72.43%);
  opacity: 0.85;
}

.masthead.-type-2 {
  position: relative;
}

.masthead.-type-2 .mainSearch {
  position: absolute;
  bottom: 30px;
}

@media (max-width: 1199px) {
  .masthead.-type-2 .mainSearch {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}

.masthead.-type-2 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
}

@media (max-width: 1199px) {
  .masthead.-type-2 .masthead__bg {
    height: 100%;
  }
}

.masthead.-type-2 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-2 .masthead__tabs {
  padding-top: 90px;
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__tabs {
    padding-top: 74px;
  }
}

.masthead.-type-2 .masthead__content {
  padding-top: 120px;
}

@media (max-width: 1199px) {
  .masthead.-type-2 .masthead__content {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-2 .masthead__content {
    padding-top: 40px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__content {
    padding-bottom: 20px;
  }
}

.masthead.-type-2 .masthead__images {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
}

@media (max-width: 575px) {
  .masthead.-type-2 .masthead__images {
    gap: 12px;
  }
}

.masthead.-type-2 .masthead__images img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-2 .masthead__images>*:nth-child(1) {
  grid-row: span 2;
}

.masthead.-type-3 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .masthead.-type-3 {
    padding-top: 220px;
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-3 {
    padding-top: 160px;
    padding-bottom: 30px;
  }
}

.masthead.-type-3 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-3 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-3 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-3 .masthead__tabs {
  margin-top: 110px;
}

@media (max-width: 767px) {
  .masthead.-type-3 .masthead__tabs {
    margin-top: 40px;
  }
}

.masthead.-type-3 .masthead__tabs .tabs__controls {
  bottom: -5px;
}

.masthead.-type-3 .masthead__tabs .tabs__button {
  height: 64px;
}

.masthead.-type-4 {
  position: relative;
  margin: 20px 20px 0 20px;
}

@media (max-width: 1199px) {
  .masthead.-type-4 {
    margin: 0;
  }
}

.masthead.-type-4 .masthead__content {
  position: relative;
  z-index: 1;
  padding-top: 360px;
  padding-bottom: 140px;
}

@media (max-width: 991px) {
  .masthead.-type-4 .masthead__content {
    padding-top: 220px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__content {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

.masthead.-type-4 .masthead__scroll {
  position: absolute;
  z-index: 5;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead__scroll {
    display: none;
  }
}

.masthead.-type-4 .masthead__scroll .-line {
  width: 1px;
  height: 48px;
  background-color: white;
  margin-top: 3px;
}

.masthead.-type-4 .masthead-slider {
  position: relative;
  z-index: 0;
}

.masthead.-type-4 .masthead-slider .masthead__image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.masthead.-type-4 .masthead-slider .masthead__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-4 .masthead-slider img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-4 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.masthead.-type-4 .masthead-slider__nav.-prev {
  left: 40px;
}

.masthead.-type-4 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-4 .masthead-slider__nav {
    top: unset;
    bottom: 30px;
    transform: none;
  }

  .masthead.-type-4 .masthead-slider__nav.-prev {
    left: unset;
    right: calc(50% + 10px);
  }

  .masthead.-type-4 .masthead-slider__nav.-next {
    right: unset;
    left: calc(50% + 10px);
  }
}

.masthead.-type-4 .mainSearch {
  margin-top: 90px;
}

@media (max-width: 767px) {
  .masthead.-type-4 .mainSearch {
    margin-top: 40px;
  }
}

.masthead.-type-5 {
  position: relative;
  padding-top: 260px;
  padding-bottom: 200px;
}

@media (max-width: 991px) {
  .masthead.-type-5 {
    padding-top: 220px;
    padding-bottom: 160px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-5 {
    padding-top: 160px;
    padding-bottom: 40px;
  }
}

.masthead.-type-5 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 58%;
  z-index: -1;
}

@media (max-width: 767px) {
  .masthead.-type-5 .masthead__bg {
    width: 100%;
  }
}

.masthead.-type-5 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-5 .masthead__image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 42%;
  z-index: -2;
}

.masthead.-type-5 .masthead__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-5 .-line {
  position: absolute;
  bottom: -40%;
  left: 0;
  width: 100%;
}

.masthead.-type-5 .-line img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-6 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 300px;
}

@media (max-width: 1199px) {
  .masthead.-type-6 {
    padding-top: 240px;
    padding-bottom: 180px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-6 {
    padding-top: 200px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-6 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.masthead.-type-6 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.masthead.-type-6 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-6 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-7 {
  position: relative;
  padding-top: 90px;
}

@media (max-width: 991px) {
  .masthead.-type-7 {
    padding-bottom: 200px;
  }
}

.masthead.-type-7 .masthead__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.masthead.-type-7 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-7 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.5);
}

.masthead.-type-7 .masthead__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 650px;
}

@media (max-width: 991px) {
  .masthead.-type-7 .masthead__content {
    height: auto;
    width: 400px;
    max-width: 100%;
    padding-top: 100px;
    padding-bottom: 220px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead__content {
    padding-top: 60px;
    padding-bottom: 260px;
  }
}

.masthead.-type-7 .masthead-slider {
  overflow: hidden;
  position: relative;
  width: 1720px;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 4px;
}

.masthead.-type-7 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.masthead.-type-7 .masthead-slider__nav.-prev {
  left: 40px;
}

.masthead.-type-7 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-7 .masthead-slider__nav {
    top: 52%;
  }

  .masthead.-type-7 .masthead-slider__nav.-prev {
    left: unset;
    right: 51%;
  }

  .masthead.-type-7 .masthead-slider__nav.-next {
    right: unset;
    left: 51%;
  }
}

.masthead.-type-7 .mainSearch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  z-index: 1;
}

@media (max-width: 991px) {
  .masthead.-type-7 .mainSearch {
    bottom: 0;
    width: 480px;
    max-width: calc(100% - 40px);
  }
}

.masthead.-type-8 {
  position: relative;
  padding-top: 200px;
}

@media (max-width: 991px) {
  .masthead.-type-8 {
    padding-top: 160px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-8 {
    padding-top: 120px;
  }
}

.masthead.-type-8 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  z-index: -1;
}

.masthead.-type-8 .masthead-bottom {
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
}

.masthead.-type-8 .mainSearch {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  z-index: 2;
}

@media (max-width: 991px) {
  .masthead.-type-8 .mainSearch {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    max-width: 600px;
  }
}

.masthead.-type-8 .masthead-slider {
  position: relative;
  border-radius: 16px;
  margin: 0 24px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider {
    border-radius: 0;
    margin: 0;
  }
}

.masthead.-type-8 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.masthead.-type-8 .masthead-slider__nav.-prev {
  left: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider__nav.-prev {
    left: 12px;
  }
}

.masthead.-type-8 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-8 .masthead-slider__nav.-next {
    right: 12px;
  }
}

.masthead.-type-9 {
  position: relative;
  z-index: 0;
}

.masthead.-type-9 .masthead__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.masthead.-type-9 .masthead__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.masthead.-type-9 .masthead__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 6, 20, 0.3);
}

.masthead.-type-9 .masthead__scroll {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

@media (max-width: 1199px) {
  .masthead.-type-9 .masthead__scroll {
    display: none;
  }
}

.masthead.-type-9 .masthead__scroll .-icon {
  position: relative;
}

.masthead.-type-9 .masthead__scroll .-icon>*:nth-child(1) {
  width: 28px;
  height: 40px;
  border: 2px solid white;
  border-radius: 200px;
}

.masthead.-type-9 .masthead__scroll .-icon>*:nth-child(2) {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 8px;
  height: 8px;
  border: 2px solid white;
  border-radius: 100%;
  transition: all 0.5s ease;
}

.masthead.-type-9 .masthead__scroll:hover .-icon>*:nth-child(2) {
  transform: translateY(16px);
}

.masthead.-type-9 .masthead-slider {
  position: relative;
  z-index: -1;
}

.masthead.-type-9 .masthead-slider .swiper-slide {
  padding-top: 385px;
  padding-bottom: 285px;
}

@media (max-width: 991px) {
  .masthead.-type-9 .masthead-slider .swiper-slide {
    padding-top: 200px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider .swiper-slide {
    padding-top: 160px;
    padding-bottom: 40px;
  }
}

.masthead.-type-9 .masthead-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.masthead.-type-9 .masthead-slider__nav.-prev {
  left: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider__nav.-prev {
    left: 12px;
  }
}

.masthead.-type-9 .masthead-slider__nav.-next {
  right: 40px;
}

@media (max-width: 767px) {
  .masthead.-type-9 .masthead-slider__nav.-next {
    right: 12px;
  }
}

.masthead.-type-9 .mainSearch-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  width: 50vw;
  padding: 8px;
}

@media (max-width: 1300px) {
  .masthead.-type-9 .mainSearch-wrap {
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    width: 100%;
    background-color: transparent !important;
  }
}

@media (max-width: 991px) {
  .masthead.-type-9 .mainSearch-wrap {
    left: unset;
    transform: none;
    position: relative;
    margin-top: 50px;
  }
}

.masthead.-type-10 {
  position: relative;
  padding-top: 360px;
  padding-bottom: 205px;
  background: linear-gradient(180deg, rgba(53, 84, 209, 0.1) 0%, rgba(196, 196, 196, 0) 100%);
}

@media (max-width: 991px) {
  .masthead.-type-10 {
    padding-top: 140px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-10 {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.masthead.-type-10 .masthead__content {
  position: relative;
  z-index: 1;
}

.masthead.-type-10 .masthead__image {
  position: absolute;
  z-index: 0;
  display: flex;
  top: 160px;
  right: 0;
}

@media (max-width: 991px) {
  .masthead.-type-10 .masthead__image {
    top: unset;
    position: relative;
    margin-top: 30px;
  }
}

.masthead.-type-10 .container-1500 {
  margin: 0 auto;
  max-width: 1530px;
  padding: 0 15px;
}

@media (max-width: 1600px) {
  .masthead.-type-10 .container-1500 {
    max-width: 1320px;
  }
}

@media (max-width: 1399px) {
  .masthead.-type-10 .container-1500 {
    max-width: 1140px;
  }
}

@media (max-width: 1199px) {
  .masthead.-type-10 .container-1500 {
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .masthead.-type-10 .container-1500 {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .masthead.-type-10 .container-1500 {
    max-width: 540px;
  }
}

@media (max-width: 575px) {
  .masthead.-type-10 .container-1500 {
    max-width: 100%;
  }
}

.masthead.-type-10 .mainSearch .button-grid {
  grid-template-columns: 1fr 1fr auto auto auto auto;
}

@media (max-width: 991px) {
  .masthead.-type-10 .mainSearch .button-grid {
    grid-template-columns: 1fr;
  }
}

.citiesCard.-type-1 {
  position: relative;
  z-index: 0;
}

.citiesCard.-type-1 .citiesCard__image {
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__content {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-1 .citiesCard__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
  border-radius: inherit;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.citiesCard.-type-1 .citiesCard__top {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.citiesCard.-type-1 .citiesCard__bottom {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(80px);
}

.citiesCard.-type-1:hover {
  cursor: pointer;
}

.citiesCard.-type-1:hover .citiesCard__top {
  opacity: 1;
}

.citiesCard.-type-1:hover .citiesCard__bottom {
  transform: translateY(0);
}

.citiesCard.-type-1:hover .citiesCard__bg {
  opacity: 1;
}

.citiesCard.-type-2 {
  position: relative;
  z-index: 0;
}

.citiesCard.-type-2 .citiesCard__image {
  overflow: hidden;
}

.citiesCard.-type-2 .citiesCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-2:hover {
  cursor: pointer;
}

.citiesCard.-type-2:hover .citiesCard__image img {
  transform: scale(1.15);
}

.citiesCard.-type-3 {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

@media (max-width: 575px) {
  .citiesCard.-type-3 {
    width: 100%;
    padding-bottom: 100%;
  }

  .citiesCard.-type-3>* {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.citiesCard.-type-3 .citiesCard__image {
  overflow: hidden;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.citiesCard.-type-3 .citiesCard__image img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: inherit;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-3 .citiesCard__content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.citiesCard.-type-3 .citiesCard__content::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0.6) 0%, rgba(5, 16, 54, 0) 63.5%);
}

.citiesCard.-type-3:hover .citiesCard__image img {
  transform: scale(1.1);
}

.citiesCard.-type-4 .citiesCard__image {
  overflow: hidden;
}

.citiesCard.-type-4 .citiesCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-4:hover .citiesCard__image img {
  transform: scale(1.1);
}

.citiesCard.-type-5 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.citiesCard.-type-5:hover {
  background-color: var(--color-blue-1) !important;
}

.citiesCard.-type-5:hover * {
  color: white !important;
}

.ctaCard.-type-1 {
  position: relative;
  z-index: 0;
}

.ctaCard.-type-1 .ctaCard__image {
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ctaCard.-type-1 .ctaCard__content::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0.7) 0%, rgba(5, 16, 54, 0) 100%);
}

.ctaCard.-type-1.-no-overlay .ctaCard__content::before {
  display: none;
}

.tabs__content {
  position: relative;
}

.tabs__pane {
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: none;
  opacity: 0;
}

.tabs__pane.is-tab-el-active {
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.tabs.-pills .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-pills .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-blue-1) !important;
  background-color: rgba(53, 84, 209, 0.05);
}

.tabs.-pills-2 .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills-2 .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-pills-2 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-white) !important;
  background-color: var(--color-blue-1) !important;
}

.tabs.-pills-3 .tabs__controls .tabs__button {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-pills-3 .tabs__controls .tabs__button:hover {
  color: var(--color-dark-3) !important;
}

.tabs.-pills-3 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-white) !important;
  background-color: var(--color-dark-3) !important;
}

.tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}

.tabs__controls>div {
  scroll-snap-align: center;
}

.tabs.-bookmark .tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.tabs.-bookmark .tabs__controls>div {
  scroll-snap-align: center;
}

.tabs.-bookmark .tabs__button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-bookmark .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-bookmark .tabs__button.is-tab-el-active {
  color: var(--color-dark-1) !important;
  background-color: white;
}

.tabs.-bookmark-2 .tabs__controls {
  position: relative;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.tabs.-bookmark-2 .tabs__controls>div {
  scroll-snap-align: center;
}

.tabs.-bookmark-2 .tabs__button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.3;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-bookmark-2 .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-bookmark-2 .tabs__button.is-tab-el-active {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.tabs.-underline .tabs__controls .tabs__button {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-underline .tabs__controls .tabs__button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tabs.-underline .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-underline .tabs__controls .tabs__button.is-tab-el-active::after {
  opacity: 1;
}

.tabs.-underline-2 .tabs__controls .tabs__button {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tabs.-underline-2 .tabs__controls .tabs__button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tabs.-underline-2 .tabs__controls .tabs__button:hover {
  color: var(--color-blue-1) !important;
}

.tabs.-underline-2 .tabs__controls .tabs__button.is-tab-el-active {
  color: var(--color-blue-1) !important;
}

.tabs.-underline-2 .tabs__controls .tabs__button.is-tab-el-active::after {
  opacity: 1;
}

.accordion__icon {
  position: relative;
}

.accordion__icon .icon {
  transition: all 0.2s ease-out;
}

.accordion__icon .icon:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
}

.accordion__icon .icon:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon>*:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion__item.is-active .accordion__icon>*:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion__button {
  cursor: pointer;
}

.accordion.-simple .accordion__button {
  cursor: pointer;
  transition: 0.4s;
}

.accordion.-simple .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-simple .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon>*:nth-child(1) {
  position: absolute;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__icon>*:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.accordion.-simple .accordion__item.is-active .accordion__icon {
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.accordion.-simple .accordion__item.is-active .accordion__icon>*:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-simple .accordion__item.is-active .accordion__icon>*:nth-child(2) {
  transform: none;
  opacity: 1;
}

.accordion.-map .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-map .accordion__item.is-active .accordion__icon {
  background-color: var(--color-blue-1) !important;
  color: white !important;
}

.accordion.-map .accordion__item.is-active .accordion__icon>* {
  transform: none !important;
  opacity: 1 !important;
}

.accordion.-db-sidebar .accordion__button {
  cursor: pointer;
  transition: 0.4s;
}

.accordion.-db-sidebar .accordion__button button {
  color: var(--text-dark-1);
}

.accordion.-db-sidebar .accordion__icon {
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__icon>*:nth-child(1) {
  position: absolute;
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__icon>*:nth-child(2) {
  position: relative;
  transform: rotate(-90deg);
  opacity: 0;
  transition: all 0.2s ease-out;
}

.accordion.-db-sidebar .accordion__item.is-active .sidebar__button {
  background-color: rgba(53, 84, 209, 0.05) !important;
  color: var(--color-blue-1) !important;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon {
  background-color: var(--color-dark-1) !important;
  color: white !important;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon>*:nth-child(1) {
  transform: rotate(90deg);
  opacity: 0;
}

.accordion.-db-sidebar .accordion__item.is-active .accordion__icon>*:nth-child(2) {
  transform: none;
  opacity: 1;
}

.header-margin {
  margin-top: 90px;
}

@media (max-width: 767px) {
  .header-margin {
    margin-top: 80px;
  }
}

.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 767px) {
  .header {
    height: 80px;
  }
}

.header.-fixed {
  position: absolute !important;
}

.header .is-menu-opened-hide {
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header.is-menu-opened {
  background-color: white !important;
  border-bottom: 1px solid var(--color-border);
}

.header.is-menu-opened .is-menu-opened-hide {
  opacity: 0;
  pointer-events: none;
}

.header.is-sticky {
  margin: 0 !important;
}

.header .row {
  flex-wrap: nowrap;
}

.header__container {
  width: 100%;
}

.header__container-1500 {
  max-width: 1560px;
}

.header .header-logo {
  position: relative;
}

.header .header-logo img {
  width: 140px;
}

@media (max-width: 767px) {
  .header .header-logo img {
    max-width: 120px;
  }
}

.header .header-logo img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.header .header-logo.is-logo-dark img:nth-child(1) {
  opacity: 0;
}

.header .header-logo.is-logo-dark img:nth-child(2) {
  opacity: 1;
}

.header.-type-2 {
  margin-top: 20px;
  transition: background-color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), margin 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 1199px) {
  .header.-type-2 {
    margin-top: 0;
  }
}

.header.-type-2.is-sticky {
  margin: 0;
}

@media (max-width: 1199px) {
  .header.-type-2 .col-auto-menu {
    position: absolute;
  }
}

@media (max-width: 767px) {
  .header.-type-5 .header__buttons>*:nth-child(1) {
    color: white !important;
    background-color: var(--color-dark-1) !important;
    border-color: var(--color-dark-1) !important;
  }

  .header.-type-5 .header__buttons>*:nth-child(1):hover {
    background-color: var(--color-blue-1) !important;
    color: white !important;
    border-color: var(--color-blue-1) !important;
  }

  .header.-type-5 .header__buttons>*:nth-child(2) {
    color: var(--color-dark-1) !important;
    border-color: var(--color-dark-1) !important;
  }

  .header.-type-5 .header__buttons>*:nth-child(2):hover {
    background-color: var(--color-blue-1) !important;
    color: white !important;
    border-color: var(--color-blue-1) !important;
  }
}

.header.-type-5.-header-5-sticky {
  background-color: white !important;
  box-shadow: 0px 10px 30px 0px #05103608;
}

.header.-type-5.-header-5-sticky .header__buttons>*:nth-child(1) {
  color: white !important;
  background-color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons>*:nth-child(1):hover {
  background-color: var(--color-blue-1) !important;
  color: white !important;
  border-color: var(--color-blue-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons>*:nth-child(2) {
  color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
}

.header.-type-5.-header-5-sticky .header__buttons>*:nth-child(2):hover {
  background-color: var(--color-blue-1) !important;
  color: white !important;
  border-color: var(--color-blue-1) !important;
}

@media (max-width: 1199px) {
  .header.-type-9 .header-menu {
    top: 120px;
    height: calc(100vh - 120px);
  }
}

.header.-dashboard .header__container {
  display: flex;
  align-items: center;
}

.header.-dashboard .header__container>.row {
  width: 100%;
}

.header.-dashboard .-left-side {
  flex-shrink: 0;
  width: calc(var(--dashboard-width) - 30px);
}

@media (max-width: 991px) {
  .header.-dashboard .-left-side {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .header .header-menu .menu li {
    position: relative;
  }

  .header .header-menu .menu li a {
    cursor: pointer;
  }

  .header .header-menu .menu li:hover>.subnav {
    opacity: 1;
    pointer-events: auto;
  }

  .header .header-menu .menu li.-has-mega-menu:hover>.mega {
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  .header .header-menu .menu a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .header .header-menu .menu__nav {
    display: flex;
  }

  .header .header-menu .menu__nav>li {
    padding: 25px 0;
  }

  .header .header-menu .menu__nav>li.menu-item-has-children>a .icon {
    font-size: 7px;
  }

  .header .header-menu .menu__nav>li>.subnav::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 20px;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: rotate(45deg);
  }

  .header .header-menu .menu__nav a {
    padding: 0 10px;
  }

  .header .header-menu .menu .subnav {
    position: absolute;
    top: 100%;
    background-color: white;
    border-radius: 4px;
    color: var(--color-dark-1) !important;
    min-width: 240px;
    padding: 20px;
    box-shadow: 0px 10px 60px 0px #0510360D;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .header .header-menu .menu .subnav__backBtn {
    display: none;
  }

  .header .header-menu .menu .subnav .subnav {
    top: 0;
    left: 100%;
  }

  .header .header-menu .menu .subnav>li>a {
    padding: 5px 15px;
    border-radius: 4px;
  }

  .header .header-menu .menu .subnav>li>a:hover {
    color: var(--color-blue-1);
    background-color: rgba(53, 84, 209, 0.05);
  }

  .header .header-menu .menu .mega-mobile {
    display: none;
  }

  .header .header-menu .menu .mega {
    position: absolute;
    top: 100%;
    background-color: white;
    padding: 30px;
    border-radius: 4px;
    color: var(--color-dark-1);
    width: 800px;
    box-shadow: 0px 10px 30px 0px #05103608;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    pointer-events: none;
  }

  .header .header-menu .menu .mega__content {
    display: flex;
    justify-content: space-between;
  }

  .header .header-menu .menu .mega__grid {
    display: grid;
    gap: 60px;
    grid-template-columns: auto auto auto;
  }

  .header .header-menu .menu .mega__image {
    width: 270px;
  }

  .header .header-menu .menu .mega__image img {
    width: 100%;
    min-height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .header .header-menu .menu .mega a {
    padding-left: 0px;
    padding-right: 0px;
  }

  .header .header-menu .menu__backButton {
    display: none;
  }

  .header .header-menu .mobile-overlay {
    display: none;
  }

  .header .header-menu .mobile-bg {
    display: none;
  }

  .header .header-menu .mobile-back-button {
    display: none;
  }

  .header .header-menu .mobile-footer {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header .header-menu {
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: calc(100vh - 80px);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    pointer-events: none;
  }

  .header .header-menu.is-menu-active {
    opacity: 1;
    pointer-events: auto;
  }

  .header .header-menu.is-menu-active .-is-active {
    pointer-events: auto !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .header .header-menu .mega {
    display: none;
  }

  .header .header-menu ul {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .header .header-menu__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header .header-menu .mobile-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(5, 16, 54, 0.7);
    z-index: -2;
  }

  .header .header-menu .mobile-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: white;
  }

  .header .header-menu .menu {
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    height: 100%;
    padding: 20px;
    padding-top: 40px;
  }

  .header .header-menu .menu ul {
    pointer-events: none;
  }

  .header .header-menu .menu li {
    overflow: hidden;
  }

  .header .header-menu .menu a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-radius: 4px;
    font-weight: 500;
    color: var(--color-dark-1);
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .header .header-menu .menu a .icon {
    font-size: 8px;
    transform: rotate(-90deg);
  }

  .header .header-menu .menu a:hover {
    color: var(--color-blue-1);
  }

  .header .header-menu .menu__nav {
    display: flex;
    flex-direction: column;
  }

  .header .header-menu .menu__nav>li>a {
    font-size: 20px;
  }

  .header .header-menu .menu__nav>li>a:hover {
    background-color: rgba(53, 84, 209, 0.05) !important;
  }

  .header .header-menu .menu .subnav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px;
  }

  .header .header-menu .menu .subnav li {
    padding: 0 20px;
  }

  .header .header-menu .menu .subnav a {
    height: 40px;
    font-size: 18px;
    padding: 0 20px;
    transform: translateY(100%);
  }

  .header .header-menu .menu .subnav__backBtn {
    margin-bottom: 20px;
  }

  .header .header-menu .menu .subnav__backBtn li {
    padding: 0;
  }

  .header .header-menu .menu .subnav__backBtn a {
    justify-content: flex-start;
    height: 50px;
    font-size: 20px;
    color: var(--color-blue-1);
    background-color: rgba(53, 84, 209, 0.05) !important;
  }

  .header .header-menu .menu .subnav__backBtn a .icon {
    margin-right: 12px;
    transform: rotate(90deg);
  }
}

.cardImage {
  position: relative;
  z-index: 0;
  border-radius: inherit;
}

.cardImage:hover .cardImage-slider__nav {
  opacity: 1 !important;
}

.cardImage:hover .pagination__item {
  opacity: 1 !important;
}

.cardImage__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: inherit;
}

.cardImage__content>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: inherit;
}

.cardImage .cardImage-slider .swiper-wrapper {
  position: relative;
  z-index: 0;
}

.cardImage .cardImage-slider__pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  color: rgba(255, 255, 255, 0.7);
}

.cardImage .cardImage-slider__pagination.-sm {
  bottom: 15px;
}

.cardImage .cardImage-slider__pagination>*+* {
  margin-left: 7px;
}

.cardImage .cardImage-slider__pagination .pagination__item {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: currentColor;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0.75);
  opacity: 0;
}

.cardImage .cardImage-slider__pagination .pagination__item.is-active {
  transform: scale(1);
  background-color: var(--color-white);
  opacity: 1;
}

.cardImage .cardImage-slider__nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.cardImage .cardImage-slider__nav.-prev {
  left: 20px;
}

.cardImage .cardImage-slider__nav.-next {
  right: 20px;
}

.cardImage__wishlist {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cardImage__wishlist.-sm {
  top: 10px;
  right: 10px;
}

.cardImage__leftBadge {
  position: absolute;
  top: 20px;
  left: 0;
}

.footer.-dashboard {
  border-top: 1px solid var(--color-border);
}

.footer.-dashboard .footer__row {
  padding: 15px 0;
}

.mainSearch {
  position: relative;
  z-index: 20;
}

@media (max-width: 991px) {
  .mainSearch {
    width: 100%;
    border-radius: 4px !important;
  }
}

.mainSearch .button-grid {
  display: grid;
  grid-template-columns: 1fr 250px 290px auto;
}

.mainSearch .button-grid>*+* {
  border-left: 1px solid var(--color-border);
}

@media (max-width: 991px) {
  .mainSearch .button-grid {
    grid-template-columns: 1fr;
  }

  .mainSearch .button-grid>*+* {
    border-left: 0;
    border-top: 1px solid var(--color-border);
  }
}

.mainSearch .button-item {
  border: none !important;
}

@media (max-width: 991px) {
  .mainSearch__submit {
    border-radius: 4px !important;
  }
}

.mainSearch.-col-2 .button-grid {
  grid-template-columns: 1fr 1fr min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-2 .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-4 .button-grid {
  grid-template-columns: auto auto 200px 200px min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-4 .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-3-big .button-grid {
  grid-template-columns: 1fr 0.6fr 0.6fr auto;
}

@media (max-width: 991px) {
  .mainSearch.-col-3-big .button-grid {
    grid-template-columns: 1fr;
  }
}

.mainSearch.-col-5 .button-grid {
  grid-template-columns: auto auto auto auto auto min-content;
}

@media (max-width: 991px) {
  .mainSearch.-col-5 .button-grid {
    grid-template-columns: 1fr;
  }

  .mainSearch.-col-5 .button-grid>*+* {
    border-left: 0;
    border-top: 1px solid var(--color-border);
  }
}

.mainSearch.-w-900 {
  width: 900px;
}

@media (max-width: 991px) {
  .mainSearch.-w-900 {
    width: auto;
  }
}

.mainSearch.-w-1070 {
  width: 1070px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .mainSearch.-w-1070 {
    width: auto;
  }
}

.searchMenu-loc {
  position: relative;
}

.searchMenu-loc__field {
  position: absolute;
  margin-top: 30px;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 500px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .searchMenu-loc__field {
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-loc__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-loc__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.searchMenu-loc .-link:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.searchMenu-date {
  position: relative;
}

.searchMenu-date.-left .searchMenu-date__field {
  transform: none !important;
}

.searchMenu-date.-right .searchMenu-date__field {
  left: unset;
  right: 0;
  transform: none !important;
}

.searchMenu-date__field {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 900px;
  max-width: 100vw;
  margin-top: 30px;
  box-shadow: 0px 10px 60px 0px #0510360D;
  transform: translateX(-31%);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.searchMenu-date__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

@media (max-width: 991px) {
  .searchMenu-date__field {
    transform: none;
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-date__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-guests {
  position: relative;
}

.searchMenu-guests__field {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 400px;
  max-width: 100vw;
  margin-top: 30px;
  box-shadow: 0px 10px 60px 0px #0510360D;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .searchMenu-guests__field {
    width: auto;
    left: -20px;
    right: -20px;
  }
}

@media (max-width: 575px) {
  .searchMenu-guests__field {
    left: -35px;
    right: -35px;
  }
}

.searchMenu-guests__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.featureIcon.-type-1.-hover-shadow {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.featureIcon.-type-1.-hover-shadow:hover {
  box-shadow: 0px 20px 40px 0px #05103612;
}

.testimonials-slider-2 .swiper-slide {
  opacity: 0.8;
}

.testimonials-slider-2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.testimonials.-type-2 .testimonials__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.testimonials.-type-2 .testimonials__image>img:nth-child(2) {
  position: absolute;
  z-index: 1;
  width: 96px;
  height: 96px;
  -o-object-fit: cover;
  object-fit: cover;
}

.pagination.-dots {
  display: flex;
}

.pagination.-dots>* {
  margin: 0 5px;
}

.pagination.-dots .pagination__item {
  background-color: currentColor;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scale(0.75);
}

.pagination.-dots .pagination__item.is-active {
  background-color: var(--color-blue-1);
  transform: scale(1);
}

.arrow-left-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-left-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(-5px) scale(1.25);
}

.arrow-right-hover {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.arrow-right-hover:hover {
  color: var(--color-purple-1);
  transform: translateX(5px) scale(1.25);
}

.pagination.-buttons {
  display: flex;
  align-items: center;
}

.pagination.-buttons>*+* {
  margin-left: 40px;
}

.pagination.-buttons .pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  background-color: var(--color-light-7);
  color: var(--color-purple-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pagination.-buttons .pagination__button .icon {
  font-size: 12px;
}

.pagination.-buttons .pagination__button:hover {
  background-color: var(--color-purple-1);
  color: white;
}

.pagination.-buttons .pagination__count>* {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-dark-1);
}

.pagination.-buttons .pagination__count>*+* {
  margin-left: 30px;
}

.pagination.-buttons .pagination__count a::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 16px;
  height: 2px;
  transform: translateX(-50%);
  background-color: var(--color-purple-1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-buttons .pagination__count a.-count-is-active {
  color: var(--color-purple-1);
}

.pagination.-buttons .pagination__count a.-count-is-active::after {
  opacity: 1;
}

.pagination.-avatars {
  display: flex;
  align-items: center;
}

.pagination.-avatars .pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination.-avatars .pagination__item::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 92px;
  height: 92px;
  border: 2px solid var(--color-dark-1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
}

.pagination.-avatars .pagination__item.is-active::before {
  opacity: 1;
}

:root {
  --dashboard-width: 300px;
}

@media (max-width: 1199px) {
  :root {
    --dashboard-width: 250px;
  }
}

.dashboard {
  display: flex;
}

@media (max-width: 991px) {
  .dashboard {
    flex-direction: column;
  }
}

.dashboard__main {
  overflow: hidden;
  width: 100%;
  padding-left: var(--dashboard-width);
  will-change: padding-left;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dashboard__main::after {
  content: "";
  display: none;
  position: fixed;
  z-index: 50;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 991px) {
  .dashboard__main {
    padding-left: 0;
  }

  .dashboard__main::after {
    display: block;
  }
}

.dashboard__content {
  width: 100%;
  padding: 60px;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .dashboard__content {
    padding: 30px;
    padding-bottom: 0;
  }
}

@media (max-width: 575px) {
  .dashboard__content {
    padding: 20px;
    padding-bottom: 0;
  }
}

.dashboard__sidebar {
  overflow-y: scroll;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: var(--dashboard-width);
  height: calc(100% - 90px);
  padding: 30px;
  padding-top: 60px;
  padding-bottom: 40px;
  will-change: transform;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 991px) {
  .dashboard__sidebar {
    transform: translateX(-100%);
  }
}

@media (max-width: 767px) {
  .dashboard__sidebar {
    width: 80%;
    height: calc(100% - 80px);
  }
}

@media (max-width: 991px) {
  .dashboard.-is-sidebar-open .dashboard__sidebar {
    transform: none;
  }

  .dashboard.-is-sidebar-open .dashboard__main::after {
    pointer-events: auto;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .dashboard.-is-sidebar-open .dashboard__sidebar {
    transform: translateX(-100%);
  }

  .dashboard.-is-sidebar-open .dashboard__main {
    padding-left: 0;
  }

  .dashboard.-is-sidebar-open .dashboard__main::after {
    pointer-events: auto;
    opacity: 1;
  }
}

.sidebar.-dashboard .sidebar__item {
  border: none;
}

.sidebar.-dashboard .sidebar__button {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 15px;
  height: 55px;
}

@media (max-width: 575px) {
  .sidebar.-dashboard .sidebar__button {
    height: 45px;
  }
}

.sidebar.-dashboard .sidebar__button.-is-active {
  background-color: rgba(53, 84, 209, 0.05);
  color: var(--color-blue-1) !important;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.dropdown__button {
  height: 40px;
}

.dropdown .icon {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dropdown.-is-dd-active .border-light {
  border: 2px solid var(--color-dark-1) !important;
}

.dropdown.-is-dd-active .icon {
  transform: rotate(180deg);
}

.dropdown__item {
  position: absolute;
  z-index: 500;
  pointer-events: none;
  top: 100%;
  left: 50%;
  padding: 26px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 180px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
  opacity: 0;
}

.dropdown:hover .dropdown__item {
  z-index: 20;
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: auto;
}

.toggle-element {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  pointer-events: none;
}

.toggle-element.-down {
  transform: translateY(20px);
}

.toggle-element.-is-el-visible {
  z-index: 25;
  transform: none;
  opacity: 1;
  pointer-events: auto;
}

.toggle-element.-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  padding: 14px 20px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 160px;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  font-weight: 500;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

.toggle-element.-dropdown-2 {
  position: absolute;
  top: 100%;
  left: 50%;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  min-width: 120px;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateX(-50%);
}

.toggle-element.-dropdown-2 a {
  padding: 10px;
}

.toggle-element.-dropdown-2 a:hover {
  background-color: var(--color-light-2);
  color: var(--color-dark-1);
}

.blogCard.-type-1 .blogCard__image {
  overflow: hidden;
}

.blogCard.-type-1 .blogCard__image img {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-1:hover .blogCard__image img {
  transform: scale(1.1);
}

.blogCard.-type-2 .blogCard__image {
  overflow: hidden;
}

.blogCard.-type-2 .blogCard__image img {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-2:hover .blogCard__image img {
  transform: scale(1.15);
}

.blogCard.-type-3 {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.blogCard.-type-3 .blogCard__image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.blogCard.-type-3 .blogCard__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blogCard.-type-3 .blogCard__content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 16, 54, 0) 0%, rgba(5, 16, 54, 0.7) 100%);
  padding-left: 50px;
  padding-bottom: 30px;
  padding: 20px 20px;
}

.blogCard.-type-3:hover .blogCard__image img {
  transform: scale(1.1);
}

[data-slider]>div>[data-sign] {
  background-color: unset;
  width: unset;
  height: unset;
  border-radius: unset;
  display: inline-block;
  text-align: unset;
  margin-top: 22px;
  color: black;
}

[data-slider]>div>[data-sign]:last-child {
  float: right;
}

[data-slider]>div>[data-sign] span::before {
  content: 'Min Price: $';
  display: inline-block;
}

[data-slider]>div>[data-sign]:last-child span::before {
  content: 'Max Price: $';
  display: inline-block;
}

[data-slider]>div>[data-sign]>span {
  font-size: 14px;
  line-height: 16px;
}

[data-slider] {
  position: relative;
  border-radius: 10px;
  text-align: left;
}

[data-slider]>div {
  width: 100%;
}

[data-slider]>div>[data-inverse-left] {
  position: absolute;
  left: 0;
  height: 5px;
  border-radius: 10px;
  background-color: var(--color-blue-2);
}

[data-slider]>div>[data-inverse-right] {
  position: absolute;
  right: 0;
  height: 5px;
  border-radius: 10px;
  background-color: var(--color-blue-2);
}

[data-slider]>div>[data-range] {
  position: absolute;
  left: 0;
  height: 5px;
  border-radius: 14px;
  background-color: var(--color-blue-1);
}

[data-slider]>div>[data-thumb] {
  position: absolute;
  top: -7px;
  z-index: 2;
  height: 17px;
  width: 17px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  background-color: #FFF;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--color-blue-1);
}

div[data-slider]>input[type=range]::-ms-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider]>input[type=range]::-moz-range-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div[data-slider]>input[type=range]::-webkit-slider-thumb {
  pointer-events: all;
  width: 17px;
  height: 17px;
  border-radius: 0px;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div[data-slider]>input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[data-slider]>input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

[data-slider]>input[type=range] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 5px;
  top: -2px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div[data-slider]>input[type=range]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider]>input[type=range]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[data-slider]>input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[data-slider]>input[type=range]:focus {
  outline: none;
}

div[data-slider]>input[type=range]::-ms-tooltip {
  display: none;
}

[data-slider]>div>[data-sign] {
  display: inline-block;
  text-align: center;
  padding: 4px 16px;
  border-radius: 8px;
  margin-top: 22px;
}

[data-slider]>div>[data-sign]>span {
  font-size: 12px;
  line-height: 28px;
}

[data-slider]:hover>div>[data-sign] {
  opacity: 1;
}

.select {
  position: relative;
}

.select__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  background-color: white;
  text-align: left;
}

.select__icon {
  width: 20px;
  height: 20px;
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--color-light-8);
  background-color: white;
  padding: 20px;
  width: 100%;
  min-height: 150px;
  max-height: 300px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #DDDDDD;
  transition: all 0.2s ease;
  pointer-events: none;
  opacity: 0;
}

.select__dropdown.-is-visible {
  opacity: 1;
  pointer-events: auto;
}

.select__options {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.select__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.select__options::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.select__options__button {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 6px 0;
  text-align: left;
  transition: all 0.15s ease;
}

.select__options__button:hover {
  cursor: pointer;
  color: var(--color-purple-1) !important;
}

.select__options__button:focus {
  color: var(--color-purple-1) !important;
}

.select__search {
  flex-shrink: 0;
  height: 50px;
  border-radius: 4px;
  background-color: #F5F5F5 !important;
  color: black;
  padding: 0 20px;
  margin-bottom: 12px;
}

.mapFilter {
  overflow: hidden;
  position: fixed;
  top: 30px;
  bottom: 70px;
  left: 30px;
  right: 30px;
  z-index: 1000;
  border-radius: 4px;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 1400px) {
  .mapFilter {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}

@media (max-width: 1199px) {
  .mapFilter {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.mapFilter.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.mapFilter__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.mapFilter__close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}

@media (max-width: 575px) {
  .mapFilter__close {
    right: 50%;
    transform: translateX(50%);
  }
}

.mapFilter__grid {
  display: grid;
  gap: 0;
  grid-template-columns: 515px 1fr;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 340px;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 991px) {
  .mapFilter__grid {
    grid-template-columns: 420px 1fr;
  }
}

@media (max-width: 767px) {
  .mapFilter__grid {
    grid-template-columns: 250px 1fr;
    padding-left: 330px;
  }

  .mapFilter__grid .ratio {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .mapFilter__grid {
    grid-template-columns: 1fr;
  }
}

.mapFilter__grid.-filters-hidden {
  padding-left: 80px;
}

.mapFilter__grid.-filters-hidden .mapFilter-filter {
  transform: translateX(-255px);
}

@media (max-width: 767px) {
  .mapFilter__grid.-filters-hidden {
    padding-left: 70px;
  }
}

.mapFilter-filter {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 10px 35px 0px #0510361A;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mapFilter-filter__item {
  border-top: 1px solid var(--color-border);
  padding-top: 20px;
  margin-top: 20px;
}

.mapFilter-results {
  overflow-y: scroll;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 575px) {
  .mapFilter-results {
    display: none;
  }
}

.mapFilter-results__item {
  border-top: 1px solid var(--color-border);
  padding-top: 20px;
  margin-top: 20px;
}

.mapFilter-map {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mapFilter-map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.scroll-bar-1::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scroll-bar-1::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border-radius: 4px;
}

.hotelsCard.-type-1 .hotelsCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.hotelsCard.-type-1 .hotelsCard__image .cardImage__content .swiper-slide,
.hotelsCard.-type-1 .hotelsCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hotelsCard.-type-1 .hotelsCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.hotelsCard.-type-1 .hotelsCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.hotelsCard.-type-1:hover .hotelsCard__image .cardImage__content>img,
.hotelsCard.-type-1:hover .hotelsCard__image .swiper-slide {
  transform: scale(1.15);
}

.hotelsCard.-type-1:hover .hotelsCard__title span {
  background-size: 100% !important;
}

.tourCard.-type-1 .tourCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.tourCard.-type-1 .tourCard__image .cardImage__content .swiper-slide,
.tourCard.-type-1 .tourCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tourCard.-type-1 .tourCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.tourCard.-type-1 .tourCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.tourCard.-type-1:hover .tourCard__image .cardImage__content>img,
.tourCard.-type-1:hover .tourCard__image .swiper-slide {
  transform: scale(1.15);
}

.tourCard.-type-1:hover .tourCard__title span {
  background-size: 100% !important;
}

.activityCard.-type-1 .activityCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.activityCard.-type-1 .activityCard__image .cardImage__content .swiper-slide,
.activityCard.-type-1 .activityCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.activityCard.-type-1 .activityCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.activityCard.-type-1 .activityCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.activityCard.-type-1:hover .activityCard__image .cardImage__content>img,
.activityCard.-type-1:hover .activityCard__image .swiper-slide {
  transform: scale(1.15);
}

.activityCard.-type-1:hover .activityCard__title span {
  background-size: 100% !important;
}

.rentalCard.-type-1 .rentalCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.rentalCard.-type-1 .rentalCard__image .cardImage__content .swiper-slide,
.rentalCard.-type-1 .rentalCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rentalCard.-type-1 .rentalCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.rentalCard.-type-1 .rentalCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.rentalCard.-type-1:hover .rentalCard__image .cardImage__content>img,
.rentalCard.-type-1:hover .rentalCard__image .swiper-slide {
  transform: scale(1.15);
}

.rentalCard.-type-1:hover .rentalCard__title span {
  background-size: 100% !important;
}

.rentalCard.-type-2 .rentalCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.rentalCard.-type-2 .rentalCard__image .cardImage__content .swiper-slide,
.rentalCard.-type-2 .rentalCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.rentalCard.-type-2 .rentalCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.rentalCard.-type-2 .rentalCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.rentalCard.-type-2:hover .rentalCard__image .cardImage__content>img,
.rentalCard.-type-2:hover .rentalCard__image .swiper-slide {
  transform: scale(1.15);
}

.rentalCard.-type-2:hover .rentalCard__title span {
  background-size: 100% !important;
}

.carCard.-type-1 .carCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.carCard.-type-1 .carCard__image .cardImage__content .swiper-slide,
.carCard.-type-1 .carCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.carCard.-type-1 .carCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.carCard.-type-1:hover .carCard__image .cardImage__content>img,
.carCard.-type-1:hover .carCard__image .swiper-slide {
  transform: scale(1.05);
}

.cruiseCard.-type-1 .cruiseCard__image {
  overflow: hidden;
  border-radius: 4px;
}

.cruiseCard.-type-1 .cruiseCard__image .cardImage__content .swiper-slide,
.cruiseCard.-type-1 .cruiseCard__image .cardImage__content>img {
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cruiseCard.-type-1 .cruiseCard__image .swiper-slide-active {
  position: relative;
  z-index: 2;
}

.cruiseCard.-type-1 .cruiseCard__title span {
  background-image: linear-gradient(transparent 24px, black 20px);
  background-size: 0;
  background-repeat: no-repeat;
  transition: background-size 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.cruiseCard.-type-1:hover .cruiseCard__image .cardImage__content>img,
.cruiseCard.-type-1:hover .cruiseCard__image .swiper-slide {
  transform: scale(1.15);
}

.cruiseCard.-type-1:hover .cruiseCard__title span {
  background-size: 100% !important;
}

.tourTypeCard.-type-1 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tourTypeCard.-type-1:hover {
  background-color: var(--color-blue-1) !important;
  transform: translateY(-10px);
}

.tourTypeCard.-type-1:hover .tourTypeCard__content>* {
  color: white !important;
}

.destCard.-type-1 {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.destCard.-type-1 .destCard__image {
  overflow: hidden;
}

.destCard.-type-1 .destCard__image img {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.destCard.-type-1:hover .destCard__image img {
  transform: scale(1.15);
}

.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.preloader__wrap {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 30px;
  background-color: white;
  box-shadow: 0px 2px 24px 0px #00000014;
  animation-name: preloaderWraper;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
  animation-timing-function: ease;
}

.preloader__icon {
  position: absolute;
  animation-name: preloader;
  animation-iteration-count: infinite;
  animation-duration: 1.8s;
  animation-timing-function: ease;
}

.preloader__title {
  font-size: 47px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 0em;
  margin-top: 16px;
}

.preloader.-is-hidden {
  opacity: 0;
  pointer-events: none;
}

@keyframes preloaderWraper {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: none;
  }
}

@keyframes preloader {
  0% {
    transform: translateY(150%) scale(1);
  }

  50% {
    transform: translateY(0%) scale(1.2) rotate(20deg);
  }

  100% {
    transform: translateY(-150%) rotate(-20deg);
  }
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.py-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.px-28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

@media (max-width: 1199px) {
  .xl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .xl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .xl\:pt-0 {
    padding-top: 0px !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0px !important;
  }

  .xl\:pl-0 {
    padding-left: 0px !important;
  }

  .xl\:pr-0 {
    padding-right: 0px !important;
  }

  .xl\:mt-0 {
    margin-top: 0px !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0px !important;
  }

  .xl\:ml-0 {
    margin-left: 0px !important;
  }

  .xl\:mr-0 {
    margin-right: 0px !important;
  }

  .xl\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .xl\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .xl\:pt-4 {
    padding-top: 4px !important;
  }

  .xl\:pb-4 {
    padding-bottom: 4px !important;
  }

  .xl\:pl-4 {
    padding-left: 4px !important;
  }

  .xl\:pr-4 {
    padding-right: 4px !important;
  }

  .xl\:mt-4 {
    margin-top: 4px !important;
  }

  .xl\:mb-4 {
    margin-bottom: 4px !important;
  }

  .xl\:ml-4 {
    margin-left: 4px !important;
  }

  .xl\:mr-4 {
    margin-right: 4px !important;
  }

  .xl\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .xl\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .xl\:pt-5 {
    padding-top: 5px !important;
  }

  .xl\:pb-5 {
    padding-bottom: 5px !important;
  }

  .xl\:pl-5 {
    padding-left: 5px !important;
  }

  .xl\:pr-5 {
    padding-right: 5px !important;
  }

  .xl\:mt-5 {
    margin-top: 5px !important;
  }

  .xl\:mb-5 {
    margin-bottom: 5px !important;
  }

  .xl\:ml-5 {
    margin-left: 5px !important;
  }

  .xl\:mr-5 {
    margin-right: 5px !important;
  }

  .xl\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .xl\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .xl\:pt-10 {
    padding-top: 10px !important;
  }

  .xl\:pb-10 {
    padding-bottom: 10px !important;
  }

  .xl\:pl-10 {
    padding-left: 10px !important;
  }

  .xl\:pr-10 {
    padding-right: 10px !important;
  }

  .xl\:mt-10 {
    margin-top: 10px !important;
  }

  .xl\:mb-10 {
    margin-bottom: 10px !important;
  }

  .xl\:ml-10 {
    margin-left: 10px !important;
  }

  .xl\:mr-10 {
    margin-right: 10px !important;
  }

  .xl\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .xl\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .xl\:pt-15 {
    padding-top: 15px !important;
  }

  .xl\:pb-15 {
    padding-bottom: 15px !important;
  }

  .xl\:pl-15 {
    padding-left: 15px !important;
  }

  .xl\:pr-15 {
    padding-right: 15px !important;
  }

  .xl\:mt-15 {
    margin-top: 15px !important;
  }

  .xl\:mb-15 {
    margin-bottom: 15px !important;
  }

  .xl\:ml-15 {
    margin-left: 15px !important;
  }

  .xl\:mr-15 {
    margin-right: 15px !important;
  }

  .xl\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .xl\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .xl\:pt-20 {
    padding-top: 20px !important;
  }

  .xl\:pb-20 {
    padding-bottom: 20px !important;
  }

  .xl\:pl-20 {
    padding-left: 20px !important;
  }

  .xl\:pr-20 {
    padding-right: 20px !important;
  }

  .xl\:mt-20 {
    margin-top: 20px !important;
  }

  .xl\:mb-20 {
    margin-bottom: 20px !important;
  }

  .xl\:ml-20 {
    margin-left: 20px !important;
  }

  .xl\:mr-20 {
    margin-right: 20px !important;
  }

  .xl\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .xl\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .xl\:pt-24 {
    padding-top: 24px !important;
  }

  .xl\:pb-24 {
    padding-bottom: 24px !important;
  }

  .xl\:pl-24 {
    padding-left: 24px !important;
  }

  .xl\:pr-24 {
    padding-right: 24px !important;
  }

  .xl\:mt-24 {
    margin-top: 24px !important;
  }

  .xl\:mb-24 {
    margin-bottom: 24px !important;
  }

  .xl\:ml-24 {
    margin-left: 24px !important;
  }

  .xl\:mr-24 {
    margin-right: 24px !important;
  }

  .xl\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .xl\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .xl\:pt-28 {
    padding-top: 28px !important;
  }

  .xl\:pb-28 {
    padding-bottom: 28px !important;
  }

  .xl\:pl-28 {
    padding-left: 28px !important;
  }

  .xl\:pr-28 {
    padding-right: 28px !important;
  }

  .xl\:mt-28 {
    margin-top: 28px !important;
  }

  .xl\:mb-28 {
    margin-bottom: 28px !important;
  }

  .xl\:ml-28 {
    margin-left: 28px !important;
  }

  .xl\:mr-28 {
    margin-right: 28px !important;
  }

  .xl\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .xl\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .xl\:pt-30 {
    padding-top: 30px !important;
  }

  .xl\:pb-30 {
    padding-bottom: 30px !important;
  }

  .xl\:pl-30 {
    padding-left: 30px !important;
  }

  .xl\:pr-30 {
    padding-right: 30px !important;
  }

  .xl\:mt-30 {
    margin-top: 30px !important;
  }

  .xl\:mb-30 {
    margin-bottom: 30px !important;
  }

  .xl\:ml-30 {
    margin-left: 30px !important;
  }

  .xl\:mr-30 {
    margin-right: 30px !important;
  }

  .xl\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .xl\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .xl\:pt-35 {
    padding-top: 35px !important;
  }

  .xl\:pb-35 {
    padding-bottom: 35px !important;
  }

  .xl\:pl-35 {
    padding-left: 35px !important;
  }

  .xl\:pr-35 {
    padding-right: 35px !important;
  }

  .xl\:mt-35 {
    margin-top: 35px !important;
  }

  .xl\:mb-35 {
    margin-bottom: 35px !important;
  }

  .xl\:ml-35 {
    margin-left: 35px !important;
  }

  .xl\:mr-35 {
    margin-right: 35px !important;
  }

  .xl\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .xl\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .xl\:pt-40 {
    padding-top: 40px !important;
  }

  .xl\:pb-40 {
    padding-bottom: 40px !important;
  }

  .xl\:pl-40 {
    padding-left: 40px !important;
  }

  .xl\:pr-40 {
    padding-right: 40px !important;
  }

  .xl\:mt-40 {
    margin-top: 40px !important;
  }

  .xl\:mb-40 {
    margin-bottom: 40px !important;
  }

  .xl\:ml-40 {
    margin-left: 40px !important;
  }

  .xl\:mr-40 {
    margin-right: 40px !important;
  }

  .xl\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .xl\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .xl\:pt-45 {
    padding-top: 45px !important;
  }

  .xl\:pb-45 {
    padding-bottom: 45px !important;
  }

  .xl\:pl-45 {
    padding-left: 45px !important;
  }

  .xl\:pr-45 {
    padding-right: 45px !important;
  }

  .xl\:mt-45 {
    margin-top: 45px !important;
  }

  .xl\:mb-45 {
    margin-bottom: 45px !important;
  }

  .xl\:ml-45 {
    margin-left: 45px !important;
  }

  .xl\:mr-45 {
    margin-right: 45px !important;
  }

  .xl\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .xl\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .xl\:pt-50 {
    padding-top: 50px !important;
  }

  .xl\:pb-50 {
    padding-bottom: 50px !important;
  }

  .xl\:pl-50 {
    padding-left: 50px !important;
  }

  .xl\:pr-50 {
    padding-right: 50px !important;
  }

  .xl\:mt-50 {
    margin-top: 50px !important;
  }

  .xl\:mb-50 {
    margin-bottom: 50px !important;
  }

  .xl\:ml-50 {
    margin-left: 50px !important;
  }

  .xl\:mr-50 {
    margin-right: 50px !important;
  }

  .xl\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .xl\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .xl\:pt-60 {
    padding-top: 60px !important;
  }

  .xl\:pb-60 {
    padding-bottom: 60px !important;
  }

  .xl\:pl-60 {
    padding-left: 60px !important;
  }

  .xl\:pr-60 {
    padding-right: 60px !important;
  }

  .xl\:mt-60 {
    margin-top: 60px !important;
  }

  .xl\:mb-60 {
    margin-bottom: 60px !important;
  }

  .xl\:ml-60 {
    margin-left: 60px !important;
  }

  .xl\:mr-60 {
    margin-right: 60px !important;
  }

  .xl\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .xl\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .xl\:pt-70 {
    padding-top: 70px !important;
  }

  .xl\:pb-70 {
    padding-bottom: 70px !important;
  }

  .xl\:pl-70 {
    padding-left: 70px !important;
  }

  .xl\:pr-70 {
    padding-right: 70px !important;
  }

  .xl\:mt-70 {
    margin-top: 70px !important;
  }

  .xl\:mb-70 {
    margin-bottom: 70px !important;
  }

  .xl\:ml-70 {
    margin-left: 70px !important;
  }

  .xl\:mr-70 {
    margin-right: 70px !important;
  }

  .xl\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .xl\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .xl\:pt-80 {
    padding-top: 80px !important;
  }

  .xl\:pb-80 {
    padding-bottom: 80px !important;
  }

  .xl\:pl-80 {
    padding-left: 80px !important;
  }

  .xl\:pr-80 {
    padding-right: 80px !important;
  }

  .xl\:mt-80 {
    margin-top: 80px !important;
  }

  .xl\:mb-80 {
    margin-bottom: 80px !important;
  }

  .xl\:ml-80 {
    margin-left: 80px !important;
  }

  .xl\:mr-80 {
    margin-right: 80px !important;
  }

  .xl\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .xl\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .xl\:pt-90 {
    padding-top: 90px !important;
  }

  .xl\:pb-90 {
    padding-bottom: 90px !important;
  }

  .xl\:pl-90 {
    padding-left: 90px !important;
  }

  .xl\:pr-90 {
    padding-right: 90px !important;
  }

  .xl\:mt-90 {
    margin-top: 90px !important;
  }

  .xl\:mb-90 {
    margin-bottom: 90px !important;
  }

  .xl\:ml-90 {
    margin-left: 90px !important;
  }

  .xl\:mr-90 {
    margin-right: 90px !important;
  }

  .xl\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .xl\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .xl\:pt-120 {
    padding-top: 120px !important;
  }

  .xl\:pb-120 {
    padding-bottom: 120px !important;
  }

  .xl\:pl-120 {
    padding-left: 120px !important;
  }

  .xl\:pr-120 {
    padding-right: 120px !important;
  }

  .xl\:mt-120 {
    margin-top: 120px !important;
  }

  .xl\:mb-120 {
    margin-bottom: 120px !important;
  }

  .xl\:ml-120 {
    margin-left: 120px !important;
  }

  .xl\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 991px) {
  .lg\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .lg\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .lg\:pt-0 {
    padding-top: 0px !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0px !important;
  }

  .lg\:pl-0 {
    padding-left: 0px !important;
  }

  .lg\:pr-0 {
    padding-right: 0px !important;
  }

  .lg\:mt-0 {
    margin-top: 0px !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0px !important;
  }

  .lg\:ml-0 {
    margin-left: 0px !important;
  }

  .lg\:mr-0 {
    margin-right: 0px !important;
  }

  .lg\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .lg\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .lg\:pt-4 {
    padding-top: 4px !important;
  }

  .lg\:pb-4 {
    padding-bottom: 4px !important;
  }

  .lg\:pl-4 {
    padding-left: 4px !important;
  }

  .lg\:pr-4 {
    padding-right: 4px !important;
  }

  .lg\:mt-4 {
    margin-top: 4px !important;
  }

  .lg\:mb-4 {
    margin-bottom: 4px !important;
  }

  .lg\:ml-4 {
    margin-left: 4px !important;
  }

  .lg\:mr-4 {
    margin-right: 4px !important;
  }

  .lg\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .lg\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .lg\:pt-5 {
    padding-top: 5px !important;
  }

  .lg\:pb-5 {
    padding-bottom: 5px !important;
  }

  .lg\:pl-5 {
    padding-left: 5px !important;
  }

  .lg\:pr-5 {
    padding-right: 5px !important;
  }

  .lg\:mt-5 {
    margin-top: 5px !important;
  }

  .lg\:mb-5 {
    margin-bottom: 5px !important;
  }

  .lg\:ml-5 {
    margin-left: 5px !important;
  }

  .lg\:mr-5 {
    margin-right: 5px !important;
  }

  .lg\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .lg\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .lg\:pt-10 {
    padding-top: 10px !important;
  }

  .lg\:pb-10 {
    padding-bottom: 10px !important;
  }

  .lg\:pl-10 {
    padding-left: 10px !important;
  }

  .lg\:pr-10 {
    padding-right: 10px !important;
  }

  .lg\:mt-10 {
    margin-top: 10px !important;
  }

  .lg\:mb-10 {
    margin-bottom: 10px !important;
  }

  .lg\:ml-10 {
    margin-left: 10px !important;
  }

  .lg\:mr-10 {
    margin-right: 10px !important;
  }

  .lg\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .lg\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .lg\:pt-15 {
    padding-top: 15px !important;
  }

  .lg\:pb-15 {
    padding-bottom: 15px !important;
  }

  .lg\:pl-15 {
    padding-left: 15px !important;
  }

  .lg\:pr-15 {
    padding-right: 15px !important;
  }

  .lg\:mt-15 {
    margin-top: 15px !important;
  }

  .lg\:mb-15 {
    margin-bottom: 15px !important;
  }

  .lg\:ml-15 {
    margin-left: 15px !important;
  }

  .lg\:mr-15 {
    margin-right: 15px !important;
  }

  .lg\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .lg\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .lg\:pt-20 {
    padding-top: 20px !important;
  }

  .lg\:pb-20 {
    padding-bottom: 20px !important;
  }

  .lg\:pl-20 {
    padding-left: 20px !important;
  }

  .lg\:pr-20 {
    padding-right: 20px !important;
  }

  .lg\:mt-20 {
    margin-top: 20px !important;
  }

  .lg\:mb-20 {
    margin-bottom: 20px !important;
  }

  .lg\:ml-20 {
    margin-left: 20px !important;
  }

  .lg\:mr-20 {
    margin-right: 20px !important;
  }

  .lg\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .lg\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .lg\:pt-24 {
    padding-top: 24px !important;
  }

  .lg\:pb-24 {
    padding-bottom: 24px !important;
  }

  .lg\:pl-24 {
    padding-left: 24px !important;
  }

  .lg\:pr-24 {
    padding-right: 24px !important;
  }

  .lg\:mt-24 {
    margin-top: 24px !important;
  }

  .lg\:mb-24 {
    margin-bottom: 24px !important;
  }

  .lg\:ml-24 {
    margin-left: 24px !important;
  }

  .lg\:mr-24 {
    margin-right: 24px !important;
  }

  .lg\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .lg\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .lg\:pt-28 {
    padding-top: 28px !important;
  }

  .lg\:pb-28 {
    padding-bottom: 28px !important;
  }

  .lg\:pl-28 {
    padding-left: 28px !important;
  }

  .lg\:pr-28 {
    padding-right: 28px !important;
  }

  .lg\:mt-28 {
    margin-top: 28px !important;
  }

  .lg\:mb-28 {
    margin-bottom: 28px !important;
  }

  .lg\:ml-28 {
    margin-left: 28px !important;
  }

  .lg\:mr-28 {
    margin-right: 28px !important;
  }

  .lg\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .lg\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .lg\:pt-30 {
    padding-top: 30px !important;
  }

  .lg\:pb-30 {
    padding-bottom: 30px !important;
  }

  .lg\:pl-30 {
    padding-left: 30px !important;
  }

  .lg\:pr-30 {
    padding-right: 30px !important;
  }

  .lg\:mt-30 {
    margin-top: 30px !important;
  }

  .lg\:mb-30 {
    margin-bottom: 30px !important;
  }

  .lg\:ml-30 {
    margin-left: 30px !important;
  }

  .lg\:mr-30 {
    margin-right: 30px !important;
  }

  .lg\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .lg\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .lg\:pt-35 {
    padding-top: 35px !important;
  }

  .lg\:pb-35 {
    padding-bottom: 35px !important;
  }

  .lg\:pl-35 {
    padding-left: 35px !important;
  }

  .lg\:pr-35 {
    padding-right: 35px !important;
  }

  .lg\:mt-35 {
    margin-top: 35px !important;
  }

  .lg\:mb-35 {
    margin-bottom: 35px !important;
  }

  .lg\:ml-35 {
    margin-left: 35px !important;
  }

  .lg\:mr-35 {
    margin-right: 35px !important;
  }

  .lg\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .lg\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .lg\:pt-40 {
    padding-top: 40px !important;
  }

  .lg\:pb-40 {
    padding-bottom: 40px !important;
  }

  .lg\:pl-40 {
    padding-left: 40px !important;
  }

  .lg\:pr-40 {
    padding-right: 40px !important;
  }

  .lg\:mt-40 {
    margin-top: 40px !important;
  }

  .lg\:mb-40 {
    margin-bottom: 40px !important;
  }

  .lg\:ml-40 {
    margin-left: 40px !important;
  }

  .lg\:mr-40 {
    margin-right: 40px !important;
  }

  .lg\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .lg\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .lg\:pt-45 {
    padding-top: 45px !important;
  }

  .lg\:pb-45 {
    padding-bottom: 45px !important;
  }

  .lg\:pl-45 {
    padding-left: 45px !important;
  }

  .lg\:pr-45 {
    padding-right: 45px !important;
  }

  .lg\:mt-45 {
    margin-top: 45px !important;
  }

  .lg\:mb-45 {
    margin-bottom: 45px !important;
  }

  .lg\:ml-45 {
    margin-left: 45px !important;
  }

  .lg\:mr-45 {
    margin-right: 45px !important;
  }

  .lg\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .lg\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .lg\:pt-50 {
    padding-top: 50px !important;
  }

  .lg\:pb-50 {
    padding-bottom: 50px !important;
  }

  .lg\:pl-50 {
    padding-left: 50px !important;
  }

  .lg\:pr-50 {
    padding-right: 50px !important;
  }

  .lg\:mt-50 {
    margin-top: 50px !important;
  }

  .lg\:mb-50 {
    margin-bottom: 50px !important;
  }

  .lg\:ml-50 {
    margin-left: 50px !important;
  }

  .lg\:mr-50 {
    margin-right: 50px !important;
  }

  .lg\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .lg\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .lg\:pt-60 {
    padding-top: 60px !important;
  }

  .lg\:pb-60 {
    padding-bottom: 60px !important;
  }

  .lg\:pl-60 {
    padding-left: 60px !important;
  }

  .lg\:pr-60 {
    padding-right: 60px !important;
  }

  .lg\:mt-60 {
    margin-top: 60px !important;
  }

  .lg\:mb-60 {
    margin-bottom: 60px !important;
  }

  .lg\:ml-60 {
    margin-left: 60px !important;
  }

  .lg\:mr-60 {
    margin-right: 60px !important;
  }

  .lg\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .lg\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .lg\:pt-70 {
    padding-top: 70px !important;
  }

  .lg\:pb-70 {
    padding-bottom: 70px !important;
  }

  .lg\:pl-70 {
    padding-left: 70px !important;
  }

  .lg\:pr-70 {
    padding-right: 70px !important;
  }

  .lg\:mt-70 {
    margin-top: 70px !important;
  }

  .lg\:mb-70 {
    margin-bottom: 70px !important;
  }

  .lg\:ml-70 {
    margin-left: 70px !important;
  }

  .lg\:mr-70 {
    margin-right: 70px !important;
  }

  .lg\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .lg\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .lg\:pt-80 {
    padding-top: 80px !important;
  }

  .lg\:pb-80 {
    padding-bottom: 80px !important;
  }

  .lg\:pl-80 {
    padding-left: 80px !important;
  }

  .lg\:pr-80 {
    padding-right: 80px !important;
  }

  .lg\:mt-80 {
    margin-top: 80px !important;
  }

  .lg\:mb-80 {
    margin-bottom: 80px !important;
  }

  .lg\:ml-80 {
    margin-left: 80px !important;
  }

  .lg\:mr-80 {
    margin-right: 80px !important;
  }

  .lg\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .lg\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .lg\:pt-90 {
    padding-top: 90px !important;
  }

  .lg\:pb-90 {
    padding-bottom: 90px !important;
  }

  .lg\:pl-90 {
    padding-left: 90px !important;
  }

  .lg\:pr-90 {
    padding-right: 90px !important;
  }

  .lg\:mt-90 {
    margin-top: 90px !important;
  }

  .lg\:mb-90 {
    margin-bottom: 90px !important;
  }

  .lg\:ml-90 {
    margin-left: 90px !important;
  }

  .lg\:mr-90 {
    margin-right: 90px !important;
  }

  .lg\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .lg\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .lg\:pt-120 {
    padding-top: 120px !important;
  }

  .lg\:pb-120 {
    padding-bottom: 120px !important;
  }

  .lg\:pl-120 {
    padding-left: 120px !important;
  }

  .lg\:pr-120 {
    padding-right: 120px !important;
  }

  .lg\:mt-120 {
    margin-top: 120px !important;
  }

  .lg\:mb-120 {
    margin-bottom: 120px !important;
  }

  .lg\:ml-120 {
    margin-left: 120px !important;
  }

  .lg\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 767px) {
  .md\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:pt-0 {
    padding-top: 0px !important;
  }

  .md\:pb-0 {
    padding-bottom: 0px !important;
  }

  .md\:pl-0 {
    padding-left: 0px !important;
  }

  .md\:pr-0 {
    padding-right: 0px !important;
  }

  .md\:mt-0 {
    margin-top: 0px !important;
  }

  .md\:mb-0 {
    margin-bottom: 0px !important;
  }

  .md\:ml-0 {
    margin-left: 0px !important;
  }

  .md\:mr-0 {
    margin-right: 0px !important;
  }

  .md\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .md\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .md\:pt-4 {
    padding-top: 4px !important;
  }

  .md\:pb-4 {
    padding-bottom: 4px !important;
  }

  .md\:pl-4 {
    padding-left: 4px !important;
  }

  .md\:pr-4 {
    padding-right: 4px !important;
  }

  .md\:mt-4 {
    margin-top: 4px !important;
  }

  .md\:mb-4 {
    margin-bottom: 4px !important;
  }

  .md\:ml-4 {
    margin-left: 4px !important;
  }

  .md\:mr-4 {
    margin-right: 4px !important;
  }

  .md\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .md\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .md\:pt-5 {
    padding-top: 5px !important;
  }

  .md\:pb-5 {
    padding-bottom: 5px !important;
  }

  .md\:pl-5 {
    padding-left: 5px !important;
  }

  .md\:pr-5 {
    padding-right: 5px !important;
  }

  .md\:mt-5 {
    margin-top: 5px !important;
  }

  .md\:mb-5 {
    margin-bottom: 5px !important;
  }

  .md\:ml-5 {
    margin-left: 5px !important;
  }

  .md\:mr-5 {
    margin-right: 5px !important;
  }

  .md\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .md\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .md\:pt-10 {
    padding-top: 10px !important;
  }

  .md\:pb-10 {
    padding-bottom: 10px !important;
  }

  .md\:pl-10 {
    padding-left: 10px !important;
  }

  .md\:pr-10 {
    padding-right: 10px !important;
  }

  .md\:mt-10 {
    margin-top: 10px !important;
  }

  .md\:mb-10 {
    margin-bottom: 10px !important;
  }

  .md\:ml-10 {
    margin-left: 10px !important;
  }

  .md\:mr-10 {
    margin-right: 10px !important;
  }

  .md\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .md\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .md\:pt-15 {
    padding-top: 15px !important;
  }

  .md\:pb-15 {
    padding-bottom: 15px !important;
  }

  .md\:pl-15 {
    padding-left: 15px !important;
  }

  .md\:pr-15 {
    padding-right: 15px !important;
  }

  .md\:mt-15 {
    margin-top: 15px !important;
  }

  .md\:mb-15 {
    margin-bottom: 15px !important;
  }

  .md\:ml-15 {
    margin-left: 15px !important;
  }

  .md\:mr-15 {
    margin-right: 15px !important;
  }

  .md\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .md\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .md\:pt-20 {
    padding-top: 20px !important;
  }

  .md\:pb-20 {
    padding-bottom: 20px !important;
  }

  .md\:pl-20 {
    padding-left: 20px !important;
  }

  .md\:pr-20 {
    padding-right: 20px !important;
  }

  .md\:mt-20 {
    margin-top: 20px !important;
  }

  .md\:mb-20 {
    margin-bottom: 20px !important;
  }

  .md\:ml-20 {
    margin-left: 20px !important;
  }

  .md\:mr-20 {
    margin-right: 20px !important;
  }

  .md\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .md\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .md\:pt-24 {
    padding-top: 24px !important;
  }

  .md\:pb-24 {
    padding-bottom: 24px !important;
  }

  .md\:pl-24 {
    padding-left: 24px !important;
  }

  .md\:pr-24 {
    padding-right: 24px !important;
  }

  .md\:mt-24 {
    margin-top: 24px !important;
  }

  .md\:mb-24 {
    margin-bottom: 24px !important;
  }

  .md\:ml-24 {
    margin-left: 24px !important;
  }

  .md\:mr-24 {
    margin-right: 24px !important;
  }

  .md\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .md\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .md\:pt-28 {
    padding-top: 28px !important;
  }

  .md\:pb-28 {
    padding-bottom: 28px !important;
  }

  .md\:pl-28 {
    padding-left: 28px !important;
  }

  .md\:pr-28 {
    padding-right: 28px !important;
  }

  .md\:mt-28 {
    margin-top: 28px !important;
  }

  .md\:mb-28 {
    margin-bottom: 28px !important;
  }

  .md\:ml-28 {
    margin-left: 28px !important;
  }

  .md\:mr-28 {
    margin-right: 28px !important;
  }

  .md\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .md\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .md\:pt-30 {
    padding-top: 30px !important;
  }

  .md\:pb-30 {
    padding-bottom: 30px !important;
  }

  .md\:pl-30 {
    padding-left: 30px !important;
  }

  .md\:pr-30 {
    padding-right: 30px !important;
  }

  .md\:mt-30 {
    margin-top: 30px !important;
  }

  .md\:mb-30 {
    margin-bottom: 30px !important;
  }

  .md\:ml-30 {
    margin-left: 30px !important;
  }

  .md\:mr-30 {
    margin-right: 30px !important;
  }

  .md\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .md\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .md\:pt-35 {
    padding-top: 35px !important;
  }

  .md\:pb-35 {
    padding-bottom: 35px !important;
  }

  .md\:pl-35 {
    padding-left: 35px !important;
  }

  .md\:pr-35 {
    padding-right: 35px !important;
  }

  .md\:mt-35 {
    margin-top: 35px !important;
  }

  .md\:mb-35 {
    margin-bottom: 35px !important;
  }

  .md\:ml-35 {
    margin-left: 35px !important;
  }

  .md\:mr-35 {
    margin-right: 35px !important;
  }

  .md\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .md\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .md\:pt-40 {
    padding-top: 40px !important;
  }

  .md\:pb-40 {
    padding-bottom: 40px !important;
  }

  .md\:pl-40 {
    padding-left: 40px !important;
  }

  .md\:pr-40 {
    padding-right: 40px !important;
  }

  .md\:mt-40 {
    margin-top: 40px !important;
  }

  .md\:mb-40 {
    margin-bottom: 40px !important;
  }

  .md\:ml-40 {
    margin-left: 40px !important;
  }

  .md\:mr-40 {
    margin-right: 40px !important;
  }

  .md\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .md\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .md\:pt-45 {
    padding-top: 45px !important;
  }

  .md\:pb-45 {
    padding-bottom: 45px !important;
  }

  .md\:pl-45 {
    padding-left: 45px !important;
  }

  .md\:pr-45 {
    padding-right: 45px !important;
  }

  .md\:mt-45 {
    margin-top: 45px !important;
  }

  .md\:mb-45 {
    margin-bottom: 45px !important;
  }

  .md\:ml-45 {
    margin-left: 45px !important;
  }

  .md\:mr-45 {
    margin-right: 45px !important;
  }

  .md\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .md\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .md\:pt-50 {
    padding-top: 50px !important;
  }

  .md\:pb-50 {
    padding-bottom: 50px !important;
  }

  .md\:pl-50 {
    padding-left: 50px !important;
  }

  .md\:pr-50 {
    padding-right: 50px !important;
  }

  .md\:mt-50 {
    margin-top: 50px !important;
  }

  .md\:mb-50 {
    margin-bottom: 50px !important;
  }

  .md\:ml-50 {
    margin-left: 50px !important;
  }

  .md\:mr-50 {
    margin-right: 50px !important;
  }

  .md\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .md\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .md\:pt-60 {
    padding-top: 60px !important;
  }

  .md\:pb-60 {
    padding-bottom: 60px !important;
  }

  .md\:pl-60 {
    padding-left: 60px !important;
  }

  .md\:pr-60 {
    padding-right: 60px !important;
  }

  .md\:mt-60 {
    margin-top: 60px !important;
  }

  .md\:mb-60 {
    margin-bottom: 60px !important;
  }

  .md\:ml-60 {
    margin-left: 60px !important;
  }

  .md\:mr-60 {
    margin-right: 60px !important;
  }

  .md\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .md\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .md\:pt-70 {
    padding-top: 70px !important;
  }

  .md\:pb-70 {
    padding-bottom: 70px !important;
  }

  .md\:pl-70 {
    padding-left: 70px !important;
  }

  .md\:pr-70 {
    padding-right: 70px !important;
  }

  .md\:mt-70 {
    margin-top: 70px !important;
  }

  .md\:mb-70 {
    margin-bottom: 70px !important;
  }

  .md\:ml-70 {
    margin-left: 70px !important;
  }

  .md\:mr-70 {
    margin-right: 70px !important;
  }

  .md\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .md\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .md\:pt-80 {
    padding-top: 80px !important;
  }

  .md\:pb-80 {
    padding-bottom: 80px !important;
  }

  .md\:pl-80 {
    padding-left: 80px !important;
  }

  .md\:pr-80 {
    padding-right: 80px !important;
  }

  .md\:mt-80 {
    margin-top: 80px !important;
  }

  .md\:mb-80 {
    margin-bottom: 80px !important;
  }

  .md\:ml-80 {
    margin-left: 80px !important;
  }

  .md\:mr-80 {
    margin-right: 80px !important;
  }

  .md\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .md\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .md\:pt-90 {
    padding-top: 90px !important;
  }

  .md\:pb-90 {
    padding-bottom: 90px !important;
  }

  .md\:pl-90 {
    padding-left: 90px !important;
  }

  .md\:pr-90 {
    padding-right: 90px !important;
  }

  .md\:mt-90 {
    margin-top: 90px !important;
  }

  .md\:mb-90 {
    margin-bottom: 90px !important;
  }

  .md\:ml-90 {
    margin-left: 90px !important;
  }

  .md\:mr-90 {
    margin-right: 90px !important;
  }

  .md\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .md\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .md\:pt-120 {
    padding-top: 120px !important;
  }

  .md\:pb-120 {
    padding-bottom: 120px !important;
  }

  .md\:pl-120 {
    padding-left: 120px !important;
  }

  .md\:pr-120 {
    padding-right: 120px !important;
  }

  .md\:mt-120 {
    margin-top: 120px !important;
  }

  .md\:mb-120 {
    margin-bottom: 120px !important;
  }

  .md\:ml-120 {
    margin-left: 120px !important;
  }

  .md\:mr-120 {
    margin-right: 120px !important;
  }
}

@media (max-width: 575px) {
  .sm\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .sm\:pt-0 {
    padding-top: 0px !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0px !important;
  }

  .sm\:pl-0 {
    padding-left: 0px !important;
  }

  .sm\:pr-0 {
    padding-right: 0px !important;
  }

  .sm\:mt-0 {
    margin-top: 0px !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }

  .sm\:ml-0 {
    margin-left: 0px !important;
  }

  .sm\:mr-0 {
    margin-right: 0px !important;
  }

  .sm\:py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .sm\:px-4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .sm\:pt-4 {
    padding-top: 4px !important;
  }

  .sm\:pb-4 {
    padding-bottom: 4px !important;
  }

  .sm\:pl-4 {
    padding-left: 4px !important;
  }

  .sm\:pr-4 {
    padding-right: 4px !important;
  }

  .sm\:mt-4 {
    margin-top: 4px !important;
  }

  .sm\:mb-4 {
    margin-bottom: 4px !important;
  }

  .sm\:ml-4 {
    margin-left: 4px !important;
  }

  .sm\:mr-4 {
    margin-right: 4px !important;
  }

  .sm\:py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .sm\:px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .sm\:pt-5 {
    padding-top: 5px !important;
  }

  .sm\:pb-5 {
    padding-bottom: 5px !important;
  }

  .sm\:pl-5 {
    padding-left: 5px !important;
  }

  .sm\:pr-5 {
    padding-right: 5px !important;
  }

  .sm\:mt-5 {
    margin-top: 5px !important;
  }

  .sm\:mb-5 {
    margin-bottom: 5px !important;
  }

  .sm\:ml-5 {
    margin-left: 5px !important;
  }

  .sm\:mr-5 {
    margin-right: 5px !important;
  }

  .sm\:py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .sm\:px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .sm\:pt-10 {
    padding-top: 10px !important;
  }

  .sm\:pb-10 {
    padding-bottom: 10px !important;
  }

  .sm\:pl-10 {
    padding-left: 10px !important;
  }

  .sm\:pr-10 {
    padding-right: 10px !important;
  }

  .sm\:mt-10 {
    margin-top: 10px !important;
  }

  .sm\:mb-10 {
    margin-bottom: 10px !important;
  }

  .sm\:ml-10 {
    margin-left: 10px !important;
  }

  .sm\:mr-10 {
    margin-right: 10px !important;
  }

  .sm\:py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .sm\:px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .sm\:pt-15 {
    padding-top: 15px !important;
  }

  .sm\:pb-15 {
    padding-bottom: 15px !important;
  }

  .sm\:pl-15 {
    padding-left: 15px !important;
  }

  .sm\:pr-15 {
    padding-right: 15px !important;
  }

  .sm\:mt-15 {
    margin-top: 15px !important;
  }

  .sm\:mb-15 {
    margin-bottom: 15px !important;
  }

  .sm\:ml-15 {
    margin-left: 15px !important;
  }

  .sm\:mr-15 {
    margin-right: 15px !important;
  }

  .sm\:py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .sm\:px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .sm\:pt-20 {
    padding-top: 20px !important;
  }

  .sm\:pb-20 {
    padding-bottom: 20px !important;
  }

  .sm\:pl-20 {
    padding-left: 20px !important;
  }

  .sm\:pr-20 {
    padding-right: 20px !important;
  }

  .sm\:mt-20 {
    margin-top: 20px !important;
  }

  .sm\:mb-20 {
    margin-bottom: 20px !important;
  }

  .sm\:ml-20 {
    margin-left: 20px !important;
  }

  .sm\:mr-20 {
    margin-right: 20px !important;
  }

  .sm\:py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .sm\:px-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .sm\:pt-24 {
    padding-top: 24px !important;
  }

  .sm\:pb-24 {
    padding-bottom: 24px !important;
  }

  .sm\:pl-24 {
    padding-left: 24px !important;
  }

  .sm\:pr-24 {
    padding-right: 24px !important;
  }

  .sm\:mt-24 {
    margin-top: 24px !important;
  }

  .sm\:mb-24 {
    margin-bottom: 24px !important;
  }

  .sm\:ml-24 {
    margin-left: 24px !important;
  }

  .sm\:mr-24 {
    margin-right: 24px !important;
  }

  .sm\:py-28 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .sm\:px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .sm\:pt-28 {
    padding-top: 28px !important;
  }

  .sm\:pb-28 {
    padding-bottom: 28px !important;
  }

  .sm\:pl-28 {
    padding-left: 28px !important;
  }

  .sm\:pr-28 {
    padding-right: 28px !important;
  }

  .sm\:mt-28 {
    margin-top: 28px !important;
  }

  .sm\:mb-28 {
    margin-bottom: 28px !important;
  }

  .sm\:ml-28 {
    margin-left: 28px !important;
  }

  .sm\:mr-28 {
    margin-right: 28px !important;
  }

  .sm\:py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .sm\:px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .sm\:pt-30 {
    padding-top: 30px !important;
  }

  .sm\:pb-30 {
    padding-bottom: 30px !important;
  }

  .sm\:pl-30 {
    padding-left: 30px !important;
  }

  .sm\:pr-30 {
    padding-right: 30px !important;
  }

  .sm\:mt-30 {
    margin-top: 30px !important;
  }

  .sm\:mb-30 {
    margin-bottom: 30px !important;
  }

  .sm\:ml-30 {
    margin-left: 30px !important;
  }

  .sm\:mr-30 {
    margin-right: 30px !important;
  }

  .sm\:py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .sm\:px-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .sm\:pt-35 {
    padding-top: 35px !important;
  }

  .sm\:pb-35 {
    padding-bottom: 35px !important;
  }

  .sm\:pl-35 {
    padding-left: 35px !important;
  }

  .sm\:pr-35 {
    padding-right: 35px !important;
  }

  .sm\:mt-35 {
    margin-top: 35px !important;
  }

  .sm\:mb-35 {
    margin-bottom: 35px !important;
  }

  .sm\:ml-35 {
    margin-left: 35px !important;
  }

  .sm\:mr-35 {
    margin-right: 35px !important;
  }

  .sm\:py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .sm\:px-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .sm\:pt-40 {
    padding-top: 40px !important;
  }

  .sm\:pb-40 {
    padding-bottom: 40px !important;
  }

  .sm\:pl-40 {
    padding-left: 40px !important;
  }

  .sm\:pr-40 {
    padding-right: 40px !important;
  }

  .sm\:mt-40 {
    margin-top: 40px !important;
  }

  .sm\:mb-40 {
    margin-bottom: 40px !important;
  }

  .sm\:ml-40 {
    margin-left: 40px !important;
  }

  .sm\:mr-40 {
    margin-right: 40px !important;
  }

  .sm\:py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .sm\:px-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .sm\:pt-45 {
    padding-top: 45px !important;
  }

  .sm\:pb-45 {
    padding-bottom: 45px !important;
  }

  .sm\:pl-45 {
    padding-left: 45px !important;
  }

  .sm\:pr-45 {
    padding-right: 45px !important;
  }

  .sm\:mt-45 {
    margin-top: 45px !important;
  }

  .sm\:mb-45 {
    margin-bottom: 45px !important;
  }

  .sm\:ml-45 {
    margin-left: 45px !important;
  }

  .sm\:mr-45 {
    margin-right: 45px !important;
  }

  .sm\:py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .sm\:px-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .sm\:pt-50 {
    padding-top: 50px !important;
  }

  .sm\:pb-50 {
    padding-bottom: 50px !important;
  }

  .sm\:pl-50 {
    padding-left: 50px !important;
  }

  .sm\:pr-50 {
    padding-right: 50px !important;
  }

  .sm\:mt-50 {
    margin-top: 50px !important;
  }

  .sm\:mb-50 {
    margin-bottom: 50px !important;
  }

  .sm\:ml-50 {
    margin-left: 50px !important;
  }

  .sm\:mr-50 {
    margin-right: 50px !important;
  }

  .sm\:py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .sm\:px-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .sm\:pt-60 {
    padding-top: 60px !important;
  }

  .sm\:pb-60 {
    padding-bottom: 60px !important;
  }

  .sm\:pl-60 {
    padding-left: 60px !important;
  }

  .sm\:pr-60 {
    padding-right: 60px !important;
  }

  .sm\:mt-60 {
    margin-top: 60px !important;
  }

  .sm\:mb-60 {
    margin-bottom: 60px !important;
  }

  .sm\:ml-60 {
    margin-left: 60px !important;
  }

  .sm\:mr-60 {
    margin-right: 60px !important;
  }

  .sm\:py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .sm\:px-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .sm\:pt-70 {
    padding-top: 70px !important;
  }

  .sm\:pb-70 {
    padding-bottom: 70px !important;
  }

  .sm\:pl-70 {
    padding-left: 70px !important;
  }

  .sm\:pr-70 {
    padding-right: 70px !important;
  }

  .sm\:mt-70 {
    margin-top: 70px !important;
  }

  .sm\:mb-70 {
    margin-bottom: 70px !important;
  }

  .sm\:ml-70 {
    margin-left: 70px !important;
  }

  .sm\:mr-70 {
    margin-right: 70px !important;
  }

  .sm\:py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .sm\:px-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .sm\:pt-80 {
    padding-top: 80px !important;
  }

  .sm\:pb-80 {
    padding-bottom: 80px !important;
  }

  .sm\:pl-80 {
    padding-left: 80px !important;
  }

  .sm\:pr-80 {
    padding-right: 80px !important;
  }

  .sm\:mt-80 {
    margin-top: 80px !important;
  }

  .sm\:mb-80 {
    margin-bottom: 80px !important;
  }

  .sm\:ml-80 {
    margin-left: 80px !important;
  }

  .sm\:mr-80 {
    margin-right: 80px !important;
  }

  .sm\:py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .sm\:px-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .sm\:pt-90 {
    padding-top: 90px !important;
  }

  .sm\:pb-90 {
    padding-bottom: 90px !important;
  }

  .sm\:pl-90 {
    padding-left: 90px !important;
  }

  .sm\:pr-90 {
    padding-right: 90px !important;
  }

  .sm\:mt-90 {
    margin-top: 90px !important;
  }

  .sm\:mb-90 {
    margin-bottom: 90px !important;
  }

  .sm\:ml-90 {
    margin-left: 90px !important;
  }

  .sm\:mr-90 {
    margin-right: 90px !important;
  }

  .sm\:py-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .sm\:px-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .sm\:pt-120 {
    padding-top: 120px !important;
  }

  .sm\:pb-120 {
    padding-bottom: 120px !important;
  }

  .sm\:pl-120 {
    padding-left: 120px !important;
  }

  .sm\:pr-120 {
    padding-right: 120px !important;
  }

  .sm\:mt-120 {
    margin-top: 120px !important;
  }

  .sm\:mb-120 {
    margin-bottom: 120px !important;
  }

  .sm\:ml-120 {
    margin-left: 120px !important;
  }

  .sm\:mr-120 {
    margin-right: 120px !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.xl\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.lg\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.md\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.sm\:mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.x-gap-0 {
  margin-left: -0px;
  margin-right: -0px;
}

.x-gap-0>* {
  padding-left: 0px;
  padding-right: 0px;
}

.y-gap-0 {
  margin-top: -0px;
  margin-bottom: -0px;
}

.y-gap-0>* {
  padding-top: 0px;
  padding-bottom: 0px;
}

.x-gap-5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.x-gap-5>* {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.y-gap-5 {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.y-gap-5>* {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.x-gap-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.x-gap-10>* {
  padding-left: 5px;
  padding-right: 5px;
}

.y-gap-10 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.y-gap-10>* {
  padding-top: 5px;
  padding-bottom: 5px;
}

.x-gap-15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.x-gap-15>* {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.y-gap-15 {
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.y-gap-15>* {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.x-gap-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.x-gap-20>* {
  padding-left: 10px;
  padding-right: 10px;
}

.y-gap-20 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.y-gap-20>* {
  padding-top: 10px;
  padding-bottom: 10px;
}

.x-gap-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.x-gap-30>* {
  padding-left: 15px;
  padding-right: 15px;
}

.y-gap-30 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.y-gap-30>* {
  padding-top: 15px;
  padding-bottom: 15px;
}

.x-gap-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.x-gap-40>* {
  padding-left: 20px;
  padding-right: 20px;
}

.y-gap-40 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.y-gap-40>* {
  padding-top: 20px;
  padding-bottom: 20px;
}

.x-gap-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.x-gap-50>* {
  padding-left: 25px;
  padding-right: 25px;
}

.y-gap-50 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.y-gap-50>* {
  padding-top: 25px;
  padding-bottom: 25px;
}

.x-gap-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.x-gap-60>* {
  padding-left: 30px;
  padding-right: 30px;
}

.y-gap-60 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.y-gap-60>* {
  padding-top: 30px;
  padding-bottom: 30px;
}

.x-gap-80 {
  margin-left: -40px;
  margin-right: -40px;
}

.x-gap-80>* {
  padding-left: 40px;
  padding-right: 40px;
}

.y-gap-80 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.y-gap-80>* {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .md\:x-gap-0 {
    margin-left: -0px;
    margin-right: -0px;
  }

  .md\:x-gap-0>* {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:y-gap-0 {
    margin-top: -0px;
    margin-bottom: -0px;
  }

  .md\:y-gap-0>* {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .md\:x-gap-5>* {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .md\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }

  .md\:y-gap-5>* {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  .md\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .md\:x-gap-10>* {
    padding-left: 5px;
    padding-right: 5px;
  }

  .md\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .md\:y-gap-10>* {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .md\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .md\:x-gap-15>* {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .md\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }

  .md\:y-gap-15>* {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }

  .md\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .md\:x-gap-20>* {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .md\:y-gap-20>* {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .md\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .md\:x-gap-30>* {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .md\:y-gap-30>* {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .md\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .md\:x-gap-40>* {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .md\:y-gap-40>* {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .md\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .md\:x-gap-50>* {
    padding-left: 25px;
    padding-right: 25px;
  }

  .md\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .md\:y-gap-50>* {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .md\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .md\:x-gap-60>* {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .md\:y-gap-60>* {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md\:x-gap-80 {
    margin-left: -40px;
    margin-right: -40px;
  }

  .md\:x-gap-80>* {
    padding-left: 40px;
    padding-right: 40px;
  }

  .md\:y-gap-80 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .md\:y-gap-80>* {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .sm\:x-gap-0 {
    margin-left: -0px;
    margin-right: -0px;
  }

  .sm\:x-gap-0>* {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:y-gap-0 {
    margin-top: -0px;
    margin-bottom: -0px;
  }

  .sm\:y-gap-0>* {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm\:x-gap-5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }

  .sm\:x-gap-5>* {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  .sm\:y-gap-5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }

  .sm\:y-gap-5>* {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  .sm\:x-gap-10 {
    margin-left: -5px;
    margin-right: -5px;
  }

  .sm\:x-gap-10>* {
    padding-left: 5px;
    padding-right: 5px;
  }

  .sm\:y-gap-10 {
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .sm\:y-gap-10>* {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sm\:x-gap-15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }

  .sm\:x-gap-15>* {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .sm\:y-gap-15 {
    margin-top: -7.5px;
    margin-bottom: -7.5px;
  }

  .sm\:y-gap-15>* {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
  }

  .sm\:x-gap-20 {
    margin-left: -10px;
    margin-right: -10px;
  }

  .sm\:x-gap-20>* {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm\:y-gap-20 {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .sm\:y-gap-20>* {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .sm\:x-gap-30 {
    margin-left: -15px;
    margin-right: -15px;
  }

  .sm\:x-gap-30>* {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sm\:y-gap-30 {
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .sm\:y-gap-30>* {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sm\:x-gap-40 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .sm\:x-gap-40>* {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sm\:y-gap-40 {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .sm\:y-gap-40>* {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sm\:x-gap-50 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .sm\:x-gap-50>* {
    padding-left: 25px;
    padding-right: 25px;
  }

  .sm\:y-gap-50 {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .sm\:y-gap-50>* {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .sm\:x-gap-60 {
    margin-left: -30px;
    margin-right: -30px;
  }

  .sm\:x-gap-60>* {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm\:y-gap-60 {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .sm\:y-gap-60>* {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:x-gap-80 {
    margin-left: -40px;
    margin-right: -40px;
  }

  .sm\:x-gap-80>* {
    padding-left: 40px;
    padding-right: 40px;
  }

  .sm\:y-gap-80 {
    margin-top: -40px;
    margin-bottom: -40px;
  }

  .sm\:y-gap-80>* {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.layout-pt-sm {
  padding-top: 30px;
}

.layout-pb-sm {
  padding-bottom: 30px;
}

.layout-pt-md {
  padding-top: 60px;
}

.layout-pb-md {
  padding-bottom: 60px;
}

.layout-pt-lg {
  padding-top: 54px;
}

.layout-pb-lg {
  padding-bottom: 120px;
}

.layout-pt-xl {
  padding-top: 140px;
}

.layout-pb-xl {
  padding-bottom: 140px;
}

.multi-range-slider .thumb:before {
  border: 2px solid #3554d1 !important;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb !important;
}

.multi-range-slider {
  border: 0 !important;
  box-shadow: none !important;
  padding: 13px 0 !important;
}

.multi-range-slider .bar-left,
.multi-range-slider .bar-right {
  background: #fafafa !important;
  box-shadow: inset 0 7px 2px #f0f0f0, 0 4px 3px -2px #bbb !important;
  padding: 3px 0 !important;
}

.multi-range-slider .bar-inner {
  background-color: #3554d1 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.multi-range-slider .ruler {
  display: none !important;
}

.multi-range-slider .bar {
  width: auto;
  height: auto;
  background-color: transparent;
}

.multi-range-slider .labels {
  display: none !important;
}

@media (max-width: 767px) {
  .layout-pt-sm {
    padding-top: 30px;
  }

  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .layout-pt-md {
    padding-top: 60px;
  }

  .layout-pb-md {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .layout-pt-lg {
    padding-top: 100px;
  }

  .layout-pb-lg {
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  .layout-pt-xl {
    padding-top: 120px;
  }

  .layout-pb-xl {
    padding-bottom: 120px;
  }
}

@media (max-width: 575px) {
  .layout-pt-sm {
    padding-top: 30px;
  }

  .layout-pb-sm {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .layout-pt-md {
    padding-top: 30px;
  }

  .layout-pb-md {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .layout-pt-lg {
    padding-top: 15px;
  }

  .layout-pb-lg {
    padding-bottom: 30px;
  }

  .y-gap-60>* {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .layout-pt-xl {
    padding-top: 60px;
  }

  .layout-pb-xl {
    padding-bottom: 60px;
  }
}

.w-1\/7 {
  width: 14.285714% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/1 {
  width: 100% !important;
}

@media (max-width: 1199px) {
  .xl\:w-1\/5 {
    width: 20% !important;
  }

  .xl\:w-1\/4 {
    width: 25% !important;
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xl\:w-1\/2 {
    width: 50% !important;
  }

  .xl\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .md\:w-1\/5 {
    width: 20% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/1 {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .sm\:w-1\/5 {
    width: 20% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/1 {
    width: 100% !important;
  }
}

.flex-column {
  flex-direction: column;
}

@media (max-width: 1199px) {
  .xl\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 991px) {
  .lg\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .md\:flex-column {
    flex-direction: column !important;
  }
}

@media (max-width: 575px) {
  .sm\:flex-column {
    flex-direction: column !important;
  }
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

@media (max-width: 1199px) {
  .xl\:justify-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-center {
    justify-content: center !important;
  }

  .xl\:justify-between {
    justify-content: space-between !important;
  }

  .xl\:items-start {
    align-items: flex-start !important;
  }

  .xl\:items-end {
    align-items: flex-end !important;
  }

  .xl\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 991px) {
  .lg\:justify-start {
    justify-content: flex-start !important;
  }

  .lg\:justify-end {
    justify-content: flex-end !important;
  }

  .lg\:justify-center {
    justify-content: center !important;
  }

  .lg\:justify-between {
    justify-content: space-between !important;
  }

  .lg\:items-start {
    align-items: flex-start !important;
  }

  .lg\:items-end {
    align-items: flex-end !important;
  }

  .lg\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 767px) {
  .md\:justify-start {
    justify-content: flex-start !important;
  }

  .md\:justify-end {
    justify-content: flex-end !important;
  }

  .md\:justify-center {
    justify-content: center !important;
  }

  .md\:justify-between {
    justify-content: space-between !important;
  }

  .md\:items-start {
    align-items: flex-start !important;
  }

  .md\:items-end {
    align-items: flex-end !important;
  }

  .md\:items-center {
    align-items: center !important;
  }
}

@media (max-width: 575px) {
  .sm\:justify-start {
    justify-content: flex-start !important;
  }

  .sm\:justify-end {
    justify-content: flex-end !important;
  }

  .sm\:justify-center {
    justify-content: center !important;
  }

  .sm\:justify-between {
    justify-content: space-between !important;
  }

  .sm\:items-start {
    align-items: flex-start !important;
  }

  .sm\:items-end {
    align-items: flex-end !important;
  }

  .sm\:items-center {
    align-items: center !important;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

@media (max-width: 1199px) {
  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }
}

@media (max-width: 991px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }
}

@media (max-width: 767px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }
}

@media (max-width: 575px) {
  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-border {
  background-color: var(--color-border) !important;
}

.bg-dark-1 {
  background-color: var(--color-dark-1) !important;
}

.bg-dark-2 {
  background-color: var(--color-dark-2) !important;
}

.bg-dark-3 {
  background-color: var(--color-dark-3) !important;
}

.bg-dark-4 {
  background-color: var(--color-dark-4) !important;
}

.bg-light-1 {
  background-color: var(--color-light-1) !important;
}

.bg-light-2 {
  background-color: var(--color-light-2) !important;
}

.bg-light-3 {
  background-color: var(--color-light-3) !important;
}

.bg-blue-1 {
  background-color: var(--color-blue-1) !important;
}

.bg-blue-2 {
  background-color: var(--color-blue-2) !important;
}

.bg-green-1 {
  background-color: var(--color-green-1) !important;
}

.bg-green-2 {
  background-color: var(--color-green-2) !important;
}

.bg-yellow-1 {
  background-color: var(--color-yellow-1) !important;
}

.bg-yellow-2 {
  background-color: var(--color-yellow-2) !important;
}

.bg-yellow-3 {
  background-color: var(--color-yellow-3) !important;
}

.bg-yellow-4 {
  background-color: var(--color-yellow-4) !important;
}

.bg-brown-1 {
  background-color: var(--color-brown-1) !important;
}

.bg-purple-1 {
  background-color: var(--color-purple-1) !important;
}

.bg-red-1 {
  background-color: var(--color-red-1) !important;
}

.bg-red-2 {
  background-color: var(--color-red-2) !important;
}

.bg-red-3 {
  background-color: var(--color-red-3) !important;
}

.bg-info-1 {
  background-color: var(--color-info-1) !important;
}

.bg-info-2 {
  background-color: var(--color-info-2) !important;
}

.bg-warning-1 {
  background-color: var(--color-warning-1) !important;
}

.bg-warning-2 {
  background-color: var(--color-warning-2) !important;
}

.bg-error-1 {
  background-color: var(--color-error-1) !important;
}

.bg-error-2 {
  background-color: var(--color-error-2) !important;
}

.bg-success-1 {
  background-color: var(--color-success-1) !important;
}

.bg-success-2 {
  background-color: var(--color-success-2) !important;
}

.bg-blue-1-05 {
  background-color: rgba(53, 84, 209, 0.05);
}

.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.text-white {
  color: var(--color-white);
}

.text-black {
  color: var(--color-black);
}

.text-border {
  color: var(--color-border);
}

.text-dark-1 {
  color: var(--color-dark-1);
}

.text-dark-2 {
  color: var(--color-dark-2);
}

.text-dark-3 {
  color: var(--color-dark-3);
}

.text-dark-4 {
  color: var(--color-dark-4);
}

.text-light-1 {
  color: var(--color-light-1);
}

.text-light-2 {
  color: var(--color-light-2);
}

.text-light-3 {
  color: var(--color-light-3);
}

.text-blue-1 {
  color: var(--color-blue-1);
}

.text-blue-2 {
  color: var(--color-blue-2);
}

.text-green-1 {
  color: var(--color-green-1);
}

.text-green-2 {
  color: var(--color-green-2);
}

.text-yellow-1 {
  color: var(--color-yellow-1);
}

.text-yellow-2 {
  color: var(--color-yellow-2);
}

.text-yellow-3 {
  color: var(--color-yellow-3);
}

.text-yellow-4 {
  color: var(--color-yellow-4);
}

.text-brown-1 {
  color: var(--color-brown-1);
}

.text-purple-1 {
  color: var(--color-purple-1);
}

.text-red-1 {
  color: var(--color-red-1);
}

.text-red-2 {
  color: var(--color-red-2);
}

.text-red-3 {
  color: var(--color-red-3);
}

.text-info-1 {
  color: var(--color-info-1);
}

.text-info-2 {
  color: var(--color-info-2);
}

.text-warning-1 {
  color: var(--color-warning-1);
}

.text-warning-2 {
  color: var(--color-warning-2);
}

.text-error-1 {
  color: var(--color-error-1);
}

.text-error-2 {
  color: var(--color-error-2);
}

.text-success-1 {
  color: var(--color-success-1);
}

.text-success-2 {
  color: var(--color-success-2);
}

.text-inherit {
  color: inherit;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (max-width: 1199px) {
  .xl\:d-none {
    display: none !important;
  }

  .xl\:d-inline-block {
    display: inline-block !important;
  }

  .xl\:d-block {
    display: block !important;
  }

  .xl\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 991px) {
  .lg\:d-none {
    display: none !important;
  }

  .lg\:d-inline-block {
    display: inline-block !important;
  }

  .lg\:d-block {
    display: block !important;
  }

  .lg\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .md\:d-none {
    display: none !important;
  }

  .md\:d-inline-block {
    display: inline-block !important;
  }

  .md\:d-block {
    display: block !important;
  }

  .md\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 575px) {
  .sm\:d-none {
    display: none !important;
  }

  .sm\:d-inline-block {
    display: inline-block !important;
  }

  .sm\:d-block {
    display: block !important;
  }

  .sm\:d-flex {
    display: flex !important;
  }
}

@media (max-width: 1300px) {
  .xxl\:d-none {
    display: none !important;
  }
}

.html-overflow-hidden {
  overflow: hidden !important;
}

.html-overflow-hidden body {
  overflow-y: scroll;
}

.origin-top {
  transform-origin: top !important;
}

.origin-left {
  transform-origin: left !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-normal {
  overflow: initial !important;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.img-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  text-transform: none;
}

.rounded-0 {
  border-radius: 0;
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-22 {
  border-radius: 22px;
}

.rounded-100 {
  border-radius: 100px;
}

.rounded-full {
  border-radius: 100%;
}

.rounded-right-4 {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 991px) {
  .lg\:border-none {
    border: none !important;
  }
}

.border-type-1 {
  border: 1px dashed var(--color-blue-1);
}

.border-top-light {
  border-top: 1px solid var(--color-border);
}

.border-left-light {
  border-left: 1px solid var(--color-border);
}

.border-bottom-light {
  border-bottom: 1px solid var(--color-border);
}

.border-white {
  border: 1px solid white;
}

.border-dark-1 {
  border: 1px solid var(--color-dark-1);
}

.border-dark-4 {
  border: 1px solid var(--color-dark-4);
}

.border-blue-1 {
  border: 1px solid var(--color-blue-1);
}

.border-white-15 {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.border-top-white-15 {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.border-light {
  border: 1px solid var(--color-border) !important;
}

.size-3 {
  flex-shrink: 0;
  width: 3px;
  height: 3px;
}

.size-4 {
  flex-shrink: 0;
  width: 4px;
  height: 4px;
}

.size-10 {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
}

.size-30 {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
}

.size-38 {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
}

.size-35 {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.size-40 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}

.size-180 {
  flex-shrink: 0;
  width: 180px;
  height: 180px;
}

.size-140 {
  flex-shrink: 0;
  width: 140px;
  height: 140px;
}

.size-80 {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
}

.size-50 {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.size-60 {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}

.size-65 {
  flex-shrink: 0;
  width: 65px;
  height: 65px;
}

.size-20 {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.size-70 {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
}

.size-100 {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}

.size-120 {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
}

.size-250 {
  flex-shrink: 0;
  width: 250px;
  height: 250px;
}

.size-160 {
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.size-130 {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
}

.shadow-1 {
  box-shadow: 0px 20px 40px 0px #05103612;
}

.shadow-2 {
  box-shadow: 0px 10px 35px 0px #0510361A;
}

.shadow-3 {
  box-shadow: 0px 10px 30px 0px #05103608;
}

.shadow-4 {
  box-shadow: 0px 10px 60px 0px #0510360D;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.h-full {
  height: 100% !important;
}

.h-34 {
  height: 34px !important;
}

.h-60 {
  height: 60px !important;
}

.h-1 {
  height: 1px !important;
}

.h-68 {
  height: 68px !important;
}

.h-50 {
  height: 50px !important;
}

.h-20 {
  height: 20px !important;
}

.h-40 {
  height: 40px !important;
}

.w-48 {
  width: 48px;
}

.w-28 {
  width: 28px;
}

.w-max-300 {
  max-width: 300px;
}

.slider-scrollbar {
  width: 100%;
  border-radius: 4px;
  height: 3px;
}

.slider-scrollbar .swiper-scrollbar-drag {
  cursor: grab;
  background-color: var(--color-dark-1);
}

.slider-scrollbar .swiper-scrollbar-drag:active:hover {
  cursor: grabbing;
}

.slider-scrollbar.-light .swiper-scrollbar-drag {
  background-color: var(--color-white);
}

.pointer {
  cursor: pointer;
}

.section-bg {
  position: relative;
  z-index: 0;
}

.section-bg__item {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
}

.section-bg__item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.section-bg__item.-w-1500 {
  width: 1500px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .section-bg__item.-w-1500 {
    width: 100%;
  }
}

.section-bg__item.-w-1165 {
  width: 64vw;
}

@media (max-width: 1199px) {
  .section-bg__item.-w-1165 {
    width: 100%;
  }
}

.section-bg__item.-video-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40vw;
  height: unset;
}

@media (max-width: 1199px) {
  .section-bg__item.-video-left {
    top: unset;
    width: 100%;
    transform: none;
    position: relative;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 960px;
  }
}

@media (max-width: 991px) {
  .section-bg__item.-video-left {
    max-width: 720px;
  }
}

@media (max-width: 767px) {
  .section-bg__item.-video-left {
    max-width: 540px;
  }
}

.section-bg__item.-left {
  left: 0;
}

.section-bg__item.-right {
  right: 0;
}

.section-bg__item.-left-100 {
  left: 100px;
}

@media (max-width: 991px) {
  .section-bg__item.-left-100 {
    left: 0;
  }
}

.section-bg__item.-right-100 {
  right: 100px;
}

@media (max-width: 991px) {
  .section-bg__item.-right-100 {
    right: 0;
  }
}

.section-bg__item.-mx-20 {
  left: 20px;
  right: 20px;
}

@media (max-width: 767px) {
  .section-bg__item.-mx-20 {
    left: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .section-bg__item.-image {
    position: relative;
    width: 100%;
  }
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.currencyMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  z-index: 1100;
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.currencyMenu.is-hidden {
  pointer-events: none;
  opacity: 0;
}

.currencyMenu__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.currencyMenu__content {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 1070px;
  height: auto;
  transform: translateX(-50%);
}

.modalGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

@media (max-width: 991px) {
  .modalGrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .modalGrid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
}

.modalGrid__item {
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modalGrid__item>*>*:nth-child(1) {
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modalGrid__item:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.modalGrid__item:hover>*>*:nth-child(1) {
  color: var(--color-blue-1);
}

.modalGrid__item.-is-active {
  background-color: #F5F5F5;
}

.langMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  pointer-events: auto;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.langMenu.is-hidden {
  pointer-events: none;
  opacity: 0;
}

.langMenu__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 16, 54, 0.4);
}

.langMenu__content {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 1070px;
  max-width: 100%;
  height: auto;
  transform: translateX(-50%);
}

.relative {
  position: relative;
}

.ph-white::-moz-placeholder {
  color: var(--color-white);
}

.ph-white::placeholder {
  color: var(--color-white);
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.flightLine {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}

.flightLine>* {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 9px;
  border: 1px solid var(--color-border);
  border-radius: 100%;
}

.flightLine>*:nth-child(1) {
  left: 0;
  background-color: var(--color-white);
}

.flightLine>*:nth-child(2) {
  right: 0;
  background-color: var(--color-border);
}

.blog-grid-1 {
  display: grid;
  grid-template-columns: 0.66fr 0.33fr;
  grid-template-rows: 250px 250px;
  gap: 30px;
}

@media (max-width: 991px) {
  .blog-grid-1 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.blog-grid-1>*:nth-child(1) {
  grid-row: span 2;
}

@media (max-width: 991px) {
  .blog-grid-1>*:nth-child(1) {
    grid-row: unset;
  }
}

.border-center>*:nth-child(2n + 2) {
  border-left: 1px solid var(--color-light-2);
}

.border-center>*:nth-child(1n + 3) {
  border-top: 1px solid var(--color-light-2);
}

@media (max-width: 991px) {
  .border-center>*:nth-child(1n + 2) {
    border-top: 1px solid var(--color-light-2);
  }

  .border-center>* {
    border-left: 0;
  }
}

.header-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.section-slider-nav.-prev {
  left: -20px;
}

.section-slider-nav.-next {
  right: -20px;
}

@media (max-width: 767px) {
  .md\:size-full {
    width: 100%;
    height: auto;
  }
}

.sidebar__item:not(.-no-border) {
  border-top: 1px solid var(--color-border);
}

.sidebar.-mobile-filter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-top: 70px;
}

.sidebar.-mobile-filter .-icon-close {
  position: absolute;
  top: 20px;
  left: 24px;
}

@media (max-width: 767px) {
  .sidebar.-mobile-filter {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .sidebar.-mobile-filter {
    grid-template-columns: 1fr;
  }
}

.sidebar.-mobile-filter>* {
  border-top: none !important;
}

.w-250 {
  width: 250px;
  max-width: 100%;
}

.w-1 {
  width: 1px;
  max-width: 100%;
}

.w-200 {
  width: 200px;
  max-width: 100%;
}

.w-180 {
  width: 180px;
  max-width: 100%;
}

.halfMap {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 90px);
  margin-top: 90px;
}

@media (max-width: 991px) {
  .halfMap {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .halfMap {
    margin-top: 80px;
  }
}

.halfMap__content {
  width: 865px;
  max-width: 100%;
  flex-shrink: 0;
  padding: 30px;
  min-height: 100%;
}

@media (max-width: 1199px) {
  .halfMap__content {
    width: 660px;
  }
}

@media (max-width: 991px) {
  .halfMap__content {
    width: 100%;
    order: 2;
  }
}

@media (max-width: 767px) {
  .halfMap__content {
    padding: 20px;
  }
}

.halfMap__map {
  width: 100%;
  min-height: 100%;
}

@media (max-width: 991px) {
  .halfMap__map {
    order: 1;
  }
}

.halfMap__map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.galleryGrid.-type-1 {
  display: grid;
  grid-template-columns: 0.52fr 0.24fr 0.24fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .galleryGrid.-type-1 {
    grid-template-columns: 1fr;
  }
}

.galleryGrid.-type-1>*:nth-child(1) {
  grid-row: 2 span;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1>*:nth-child(1) {
    grid-row: 1;
  }
}

.galleryGrid.-type-1 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 991px) {
  .galleryGrid.-type-1 img {
    width: 100%;
  }
}

.galleryGrid.-type-2 {
  display: grid;
  grid-template-columns: 0.6fr 0.2fr 0.2fr;
  grid-template-rows: 0.4fr 0.6fr;
  gap: 10px;
}

@media (max-width: 991px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .galleryGrid.-type-2 {
    grid-template-columns: 1fr;
  }
}

.galleryGrid.-type-2>*:nth-child(1) {
  grid-row: 2 span;
}

.galleryGrid.-type-2>*:nth-child(4) {
  grid-column: 2 span;
}

.galleryGrid.-type-2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 991px) {
  .galleryGrid.-type-2 img {
    width: 100%;
  }
}

.min-h-250 {
  min-height: 250px;
}

.roomGrid {
  width: 100%;
}

.roomGrid__header {
  display: grid;
  grid-template-columns: 180px 1fr 60px 170px 140px 270px;
  gap: 30px;
  background-color: var(--color-dark-3);
  color: white;
  font-weight: 500;
  padding: 15px 0;
}

@media (max-width: 1400px) {
  .roomGrid__header {
    grid-template-columns: 140px 1fr 60px 170px 140px 180px;
  }
}

@media (max-width: 1199px) {
  .roomGrid__header {
    display: none;
  }
}

.roomGrid__header>*:first-child {
  padding-left: 30px;
}

.roomGrid__grid {
  display: grid;
  gap: 30px;
  grid-template-columns: 180px auto 270px;
}

@media (max-width: 1400px) {
  .roomGrid__grid {
    grid-template-columns: 140px auto 180px;
  }
}

@media (max-width: 1199px) {
  .roomGrid__grid {
    grid-template-columns: 140px auto;
  }

  .roomGrid__grid>*:nth-child(3) {
    grid-column: 2 span;
  }
}

@media (max-width: 991px) {
  .roomGrid__grid {
    grid-template-columns: 1fr;
  }

  .roomGrid__grid>*:nth-child(1) {
    display: flex;
    align-items: center;
  }

  .roomGrid__grid>*:nth-child(1)>*:first-child {
    min-width: 200px;
    margin-right: 20px;
  }

  .roomGrid__grid>*:nth-child(2) {
    grid-column: 2 span;
  }
}

@media (max-width: 767px) {
  .roomGrid__grid>*:nth-child(1) {
    display: block;
    align-items: unset;
  }

  .roomGrid__grid>*:nth-child(1)>*:first-child {
    min-width: auto;
    width: 100%;
    margin-right: 0;
  }
}

.roomGrid__grid>* {
  padding-top: 20px;
}

.roomGrid__grid>*:nth-child(3) {
  background-color: rgba(53, 84, 209, 0.05);
  padding: 20px 30px;
}

.roomGrid__content {
  display: grid;
  grid-template-columns: 1fr 60px 170px 140px;
  gap: 30px;
}

@media (max-width: 767px) {
  .roomGrid__content {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.roomGrid__content:nth-child(1n + 2) {
  border-top: 1px solid var(--color-border);
}

.progressBar {
  position: relative;
}

.progressBar__bg {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}

.progressBar__bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  border-radius: 4px;
}

.progressBar__bar span {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #615E5E;
}

.tooltip__content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.2;
  background-color: var(--color-dark-1);
  color: white;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.tooltip__content::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: black;
  transform: rotate(45deg);
}

.tooltip.-top .tooltip__content {
  bottom: calc(100% + 6px);
  transform: translateY(8px);
}

.tooltip.-top .tooltip__content::after {
  bottom: -4px;
}

.tooltip.-bottom .tooltip__content {
  top: calc(100% + 6px);
  transform: translateY(-8px);
}

.tooltip.-bottom .tooltip__content::after {
  top: -4px;
}

.tooltip.-left .tooltip__content {
  right: calc(100% + 6px);
  transform: translateX(8px);
}

.tooltip.-left .tooltip__content::after {
  right: -4px;
}

.tooltip.-right .tooltip__content {
  left: calc(100% + 6px);
  transform: translateX(-8px);
}

.tooltip.-right .tooltip__content::after {
  left: -4px;
}

.tooltip:hover .tooltip__content {
  transform: none;
  opacity: 1;
}

.gallery__item {
  position: relative;
  display: block;
}

.gallery__item:hover .gallery__button {
  opacity: 1;
}

.gallery__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: black;
  border-radius: 100%;
  width: rem(52px);
  height: rem(52px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

.gallery__button .icon {
  color: white;
  stroke-width: 1;
  width: rem(28px);
  height: rem(28px);
}

.gallery__button.-bottom-right {
  top: unset;
  left: unset;
  transform: unset;
  bottom: rem(30px);
  right: rem(30px);
}

.form-input {
  position: relative;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
}

.form-input label {
  position: absolute;
  top: 0;
  top: 26px;
  padding: 0 15px;
  pointer-events: none;
  font-size: 14px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input textarea,
.form-input input {
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 0 15px;
  padding-top: 25px;
  min-height: 70px;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.form-input textarea:focus,
.form-input input:focus {
  border: 2px solid #051036 !important;
}

.form-input textarea:focus~label,
.form-input textarea:valid~label,
.form-input input:focus~label,
.form-input input:valid~label {
  transform: translateY(-10px);
}

.sidebar.-blog>*+* {
  padding-top: 30px;
  margin-top: 30px;
}

.list-disc li {
  list-style-type: disc;
  list-style-position: inside;
}

.w-360 {
  width: 360px;
}

@media (max-width: 991px) {
  .lg\:w-full {
    width: 100% !important;
  }
}

@media (max-width: 575px) {
  .sm\:w-full {
    width: 100% !important;
  }
}

.w-140 {
  width: 140px;
  max-width: 100%;
}

.w-210 {
  width: 210px;
  max-width: 100%;
}

.w-230 {
  width: 230px;
  max-width: 100%;
}

.no-page__title {
  font-size: 200px;
  font-weight: 600;
  line-height: 1.2;
}

table.table th {
  padding: 25px 40px;
  line-height: 1;
  font-weight: 500;
}

table.table th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table tbody>tr+tr {
  border-top: 1px solid var(--color-border);
}

table.table td {
  padding: 20px 40px;
  font-size: 15px;
  border-bottom: 1px solid var(--color-light-5);
}

table.table-2 th {
  font-weight: 500;
  padding: 15px 10px;
  line-height: 1.2;
}

table.table-2 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-2 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-2 th:nth-child(1) {
  padding-left: 0;
}

table.table-2 th:last-child {
  text-align: right;
  padding-right: 0;
}

table.table-2 tbody>tr {
  border-top: 1px dashed var(--color-border);
  vertical-align: top;
}

table.table-2 td {
  padding: 15px 10px;
  font-size: 15px;
  line-height: 1.2;
}

table.table-2 td:nth-child(1) {
  padding-left: 0;
}

table.table-2 td:last-child {
  text-align: right;
  padding-right: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

table.table-3 th {
  font-weight: 500;
  padding: 20px 30px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-3 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-3 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-3 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-3 tbody tr {
  vertical-align: top;
}

table.table-3 tbody>tr+tr {
  border-top: 1px dashed var(--color-border);
}

table.table-3 td {
  padding: 15px 30px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-3 td {
    padding: 15px 15px;
  }
}

table.table-3.-border-bottom tbody>tr {
  border-top: none;
  border-bottom: 1px dashed var(--color-border);
}

table.table-4 th {
  font-weight: 500;
  padding: 20px 30px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-4 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-4 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-4 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-4 tbody tr {
  vertical-align: top;
}

table.table-4 tbody>tr+tr {
  border-top: 1px dashed var(--color-border);
}

table.table-4 td {
  padding: 20px 30px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-4 td {
    padding: 15px 15px;
  }
}

table.table-4.-border-bottom tbody>tr {
  border-top: none;
  border-bottom: 1px dashed var(--color-border);
}

table.table-5 {
  width: 100%;
}

table.table-5 th {
  font-weight: 500;
  padding: 20px 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-5 th {
    padding: 15px 15px;
    min-width: 100px;
  }
}

table.table-5 th:first-child {
  border-radius: 8px 0 0 8px;
}

table.table-5 th:last-child {
  border-radius: 0 8px 8px 0;
}

table.table-5 tbody tr {
  vertical-align: top;
}

table.table-5 tbody>tr {
  border-bottom: 1px solid var(--color-border);
}

table.table-5 td {
  padding: 20px 15px;
  font-size: 15px;
  line-height: 1.2;
  min-width: 130px;
}

@media (max-width: 1199px) {
  table.table-5 td {
    padding: 15px 15px;
  }
}

.z--1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.side-badge {
  position: absolute;
  top: -7px;
  left: -3px;
}

.side-badge>* {
  border: 3px solid white;
}

.map-500 {
  min-height: 500px;
  height: 500px;
}

@media (max-width: 575px) {
  .map-500 {
    min-height: 400px;
    height: 400px;
  }
}

.map-form {
  position: relative;
  margin-top: -680px;
}

@media (max-width: 991px) {
  .map-form {
    margin-top: 40px;
  }
}

.h-400 {
  height: 400px;
  min-height: 400px;
}

.table-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.table-calendar__header {
  font-size: 14px;
  font-weight: 600;
}

.table-calendar__grid {
  font-size: 15px;
}

.table-calendar__cell {
  cursor: pointer;
  position: relative;
  z-index: 0;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.table-calendar__cell:hover {
  background-color: var(--color-dark-3);
  color: white;
}

.table-calendar__cell.-is-in-path {
  position: relative;
  z-index: 0;
  color: var(--color-dark-1) !important;
}

.table-calendar__cell.-is-in-path::before {
  content: "";
  background-color: var(--color-light-2);
  position: absolute;
  top: 0;
  left: -16px;
  right: -16px;
  z-index: -2;
  height: 100%;
}

.table-calendar__cell.-is-active {
  position: relative;
  z-index: 2;
  background-color: var(--color-dark-3);
  color: white;
}

.table-calendar__header,
.table-calendar__grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
}

.table-calendar__header>*,
.table-calendar__grid>* {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

@media (max-width: 767px) {

  .table-calendar__header>*,
  .table-calendar__grid>* {
    width: 32px;
    height: 32px;
  }
}

.desktopMenu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 450px;
  max-width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.desktopMenu .mobile-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: white;
}

.desktopMenu__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.desktopMenu-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background-color: rgba(5, 16, 54, 0.5);
}

.desktopMenu.is-menu-active {
  opacity: 1;
  pointer-events: auto;
}

.desktopMenu.is-menu-active .-is-active {
  pointer-events: auto !important;
  overflow-x: hidden;
}

.desktopMenu .menu {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  height: 100%;
}

.desktopMenu .menu ul {
  pointer-events: none;
}

.desktopMenu .menu li {
  overflow: hidden;
}

.desktopMenu .menu a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--color-dark-1);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.desktopMenu .menu a .icon {
  font-size: 8px;
  transform: rotate(-90deg);
}

.desktopMenu .menu a:hover {
  color: var(--color-blue-1);
}

.desktopMenu .menu__nav {
  display: flex;
  flex-direction: column;
}

.desktopMenu .menu__nav>li>a {
  font-size: 20px;
}

.desktopMenu .menu__nav>li>a:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.desktopMenu .menu .subnav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desktopMenu .menu .subnav a {
  height: 40px;
  font-size: 18px;
  padding: 0 20px;
  transform: translateY(100%);
}

.desktopMenu .menu .subnav__backBtn {
  margin-bottom: 10px;
}

.desktopMenu .menu .subnav__backBtn li {
  padding: 0;
}

.desktopMenu .menu .subnav__backBtn a {
  justify-content: flex-start;
  height: 50px;
  font-size: 20px;
  color: var(--color-blue-1);
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.desktopMenu .menu .subnav__backBtn a .icon {
  margin-right: 12px;
  transform: rotate(90deg);
}

.searchMenu {
  position: relative;
}

.searchMenu__field {
  position: absolute;
  padding-top: 30px;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 500px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.searchMenu__field.is-visible {
  pointer-events: auto;
  opacity: 1;
}

.searchMenu .-link:hover {
  background-color: rgba(53, 84, 209, 0.05) !important;
}

.h-34 {
  height: 34px;
}

.dropRating {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 10;
  width: 340px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.dropRating.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.quote {
  position: relative;
  padding: 20px 0;
  padding-left: 75px;
  width: 740px;
  max-width: 100%;
}

.quote__line {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.quote__icon {
  position: absolute;
  top: 12px;
  left: 22px;
  z-index: -1;
}

.hotelSingleGrid {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 360px;
}



@media (max-width: 767px) {
  .hotelSingleGrid {
    grid-template-columns: 1fr;
  }
}

.border-test {
  position: absolute;
  top: 0;
  bottom: 40px;
  left: 19px;
  z-index: -1;
  border-left: 2px dashed #E5F0FD;
}

.border-line-2 {
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 13px;
  z-index: -1;
  border-left: 1px solid var(--color-border);
}

@media (max-width: 575px) {
  .border-line-2 {
    display: none;
  }
}

.map {
  width: 100%;
  height: 100%;
  background-color: #a3a3a3;
}

.carsSlider {
  display: grid;
  gap: 10px;
  grid-template-columns: 120px calc(100% - 130px);
}

@media (max-width: 767px) {
  .carsSlider {
    grid-template-columns: 1fr;
  }
}

.carsSlider .carsSlider-slides {
  display: flex;
  flex-direction: column;
}

.carsSlider .carsSlider-slides>*+* {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .carsSlider .carsSlider-slides {
    flex-direction: row;
    order: 2;
  }

  .carsSlider .carsSlider-slides>*+* {
    margin-top: 0;
    margin-left: 10px;
  }
}

.carsSlider .carsSlider-slides__item {
  border: 1px solid #DDDDDD;
}

.carsSlider .carsSlider-slides__item.-is-active {
  border: 1px solid #051036;
}

.carsSlider .carsSlider-slider {
  overflow: hidden;
  width: 100%;
}

@media (max-width: 767px) {
  .carsSlider .carsSlider-slider {
    order: 1;
  }
}

.carsSlider .carsSlider-slider img {
  border: 1px solid #DDDDDD;
  width: 100%;
  max-height: 510px;
}

.cruiseSlider .cruiseSlider-slides__item {
  border: 1px solid #DDDDDD;
}

.cruiseSlider .cruiseSlider-slides__item.-is-active {
  border: 1px solid #051036;
}

.cruiseSlider .cruiseSlider-slider {
  overflow: hidden;
  width: 100%;
}

@media (max-width: 767px) {
  .cruiseSlider .cruiseSlider-slider {
    order: 1;
  }
}

.cruiseSlider .cruiseSlider-slider img {
  border: 1px solid #DDDDDD;
  width: 100%;
  max-height: 510px;
}

.cruiseSlider__nav {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.cruiseSlider__nav.-prev {
  left: 20px;
}

.cruiseSlider__nav.-next {
  right: 20px;
}

@media (max-width: 767px) {
  .cruiseSlider__nav {
    top: unset;
    bottom: 30px;
    transform: none;
  }

  .cruiseSlider__nav.-prev {
    left: unset;
    right: calc(50% + 10px);
  }

  .cruiseSlider__nav.-next {
    right: unset;
    left: calc(50% + 10px);
  }
}

.w-max-120 {
  max-width: 120px;
}

.singleMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  top: 0;
  background-color: white;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  transition: all 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.singleMenu.-is-active {
  opacity: 1;
  pointer-events: auto;
}

.singleMenu__content {
  width: 100%;
  min-height: 80px;
  padding: 15px 0;
}

.singleMenu__links a {
  font-size: 15px;
  font-weight: 500;
}

.overflow-x-hidden {
  overflow-x: clip;
}

.-is-dd-wrap-active .-dd-button {
  border-color: var(--color-dark-1) !important;
  outline: 1px solid var(--color-dark-1) !important;
}

.-is-dd-wrap-active .-dd-button .icon {
  transform: rotate(180deg);
}

.noUi-target {
  height: 4px;
  border: none;
  outline: 1px solid rgba(0, 0, 0, 0.08);
}

.noUi-connect {
  background-color: var(--color-blue-1) !important;
  box-shadow: 0 !important;
}

.noUi-handle {
  border-radius: 100%;
  width: 20px !important;
  height: 20px !important;
  top: 50% !important;
  transform: translateY(-50%);
  border: 2px solid var(--color-blue-1);
  box-shadow: 0 !important;
}

.noUi-handle::before {
  display: none;
}

.noUi-handle::after {
  display: none;
}

.filterPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  overflow-y: scroll;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  pointer-events: none;
}

.filterPopup.-is-active {
  opacity: 1;
  pointer-events: auto;
}

.base-tr {
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.date-input {
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
}

.date-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  background: none;
  z-index: 2;
  margin-right: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.calendar-icon {
  position: absolute;
  top: 28px;
}

.calendar-icon.-left {
  left: 40px;
}

.calendar-icon.-right {
  right: 40px;
}

.gm-style-iw-t {
  right: unset !important;
  left: 25px;
  bottom: 20px !important;
}

.mapMarker {
  position: relative;
  z-index: 100;
}

.mapMarker:hover {
  background-color: var(--color-dark-1) !important;
  border-color: var(--color-dark-1) !important;
  color: white !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 4px;
}

.mapItem__img {
  width: 100px !important;
  height: 100px !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.mapItem__content {
  height: 100px;
}

.map-ratio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 700px;
}

.show-more__content {
  overflow: hidden;
  max-height: 0;
  transition: .2s;
}

.show-more.-h-60 .show-more__content {
  max-height: 80px;
}

.elCalendar__slider {
  overflow: hidden;
}

.elCalendar__sliderNav {
  position: absolute;
  top: 40px;
  z-index: 10;
}

.elCalendar__sliderNav.-prev {
  left: 60px;
}

.elCalendar__sliderNav.-next {
  right: 60px;
}

@media (max-width: 767px) {
  .elCalendar__sliderNav.-prev {
    left: 54px;
  }

  .elCalendar__sliderNav.-next {
    right: 54px;
  }
}

.elCalendar__month {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.elCalendar__header {
  display: grid;
  grid-template-columns: repeat(7, min-content);
  gap: 8px;
}

.elCalendar__header__sell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .elCalendar__header__sell {
    width: 32px;
    height: 32px;
  }
}

.elCalendar__body {
  display: grid;
  grid-template-columns: repeat(7, min-content);
  gap: 8px;
}

.elCalendar__sell {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .elCalendar__sell {
    width: 32px;
    height: 32px;
  }
}

.elCalendar__sell.-dark {
  opacity: 0.4;
}

.elCalendar__sell:hover {
  background-color: rgba(0, 0, 0, 0.041);
}

.elCalendar__sell.-is-active {
  position: relative;
  color: white;
  background-color: var(--color-dark-3);
  z-index: 1;
}

.elCalendar__sell.-is-in-path {
  position: relative;
  color: var(--color-dark-1) !important;
  z-index: 0;
}

.elCalendar__sell.-is-in-path::before {
  content: "";
  position: absolute;
  top: 0;
  left: -12px;
  right: -12px;
  height: 100%;
  background-color: var(--color-light-2);
  z-index: -2;
}

@media (max-width: 480px) {
  .sectionTitle.-md .sectionTitle__title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .sectionTitle.-md .sectionTitle__text {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .section-bg {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
}