body{
    overflow-x: hidden;
  }
:root{
    --bs-blue:#0d6efd;
    --bs-indigo:#6610f2;
    --bs-purple:#6f42c1;
    --bs-pink:#d63384;
    --bs-red:#dc3545;
    --bs-orange:#fd7e14;
    --bs-yellow:#ffc107;
    --bs-green:#198754;
    --bs-teal:#20c997;
    --bs-cyan:#0dcaf0;
    --bs-white:#fff;
    --bs-gray:#6c757d;
    --bs-gray-dark:#343a40;
    --bs-gray-100:#f8f9fa;
    --bs-gray-200:#e9ecef;
    --bs-gray-300:#dee2e6;
    --bs-gray-400:#ced4da;
    --bs-gray-500:#adb5bd;
    --bs-gray-600:#6c757d;
    --bs-gray-700:#495057;
    --bs-gray-800:#343a40;
    --bs-gray-900:#212529;
    --bs-primary:#0d6efd;
    --bs-secondary:#6c757d;
    --bs-success:#198754;
    --bs-info:#0dcaf0;
    --bs-warning:#ffc107;
    --bs-danger:#dc3545;
    --bs-light:#f8f9fa;
    --bs-dark:#212529;
    --bs-primary-rgb:13,110,253;
    --bs-secondary-rgb:108,117,125;
    --bs-success-rgb:25,135,84;
    --bs-info-rgb:13,202,240;
    --bs-warning-rgb:255,193,7;
    --bs-danger-rgb:220,53,69;
    --bs-light-rgb:248,249,250;
    --bs-dark-rgb:33,37,41;
    --bs-white-rgb:255,255,255;
    --bs-black-rgb:0,0,0;
    --bs-body-color-rgb:33,37,41;
    --bs-body-bg-rgb:255,255,255;
    --bs-font-sans-serif:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient:linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
    --bs-body-font-family:var(--bs-font-sans-serif);
    --bs-body-font-size:1rem;
    --bs-body-font-weight:400;
    --bs-body-line-height:1.5;
    --bs-body-color:#212529;
    --bs-body-bg:#fff
}
.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
    width:100%;
    padding-right:var(--bs-gutter-x,15px);
    padding-left:var(--bs-gutter-x,15px);
    margin-right:auto;
    margin-left:auto
}
@media (min-width:576px){
    .container,.container-sm{
        max-width:540px
    }
}
@media (min-width:768px){
    .container,.container-md,.container-sm{
        max-width:720px
    }
}
@media (min-width:992px){
    .container,.container-lg,.container-md,.container-sm{
        max-width:960px
    }
}
@media (min-width:1200px){
    .container,.container-lg,.container-md,.container-sm,.container-xl{
        max-width:1140px
    }
}
@media (min-width:1400px){
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
        max-width:1320px
    }
}
.row{
    --bs-gutter-x:30px;
    --bs-gutter-y:0;
    display:flex;
    flex-wrap:wrap;
    margin-top:calc(var(--bs-gutter-y)*-1);
    margin-right:calc(var(--bs-gutter-x)*-0.5);
    margin-left:calc(var(--bs-gutter-x)*-0.5)
}
.row>*{
    box-sizing:border-box;
    flex-shrink:0;
    width:100%;
    max-width:100%;
    padding-right:calc(var(--bs-gutter-x)*0.5);
    padding-left:calc(var(--bs-gutter-x)*0.5);
    margin-top:var(--bs-gutter-y)
}
.col{
    flex:1 0 0%
}
.row-cols-auto>*{
    flex:0 0 auto;
    width:auto
}
.row-cols-1>*{
    flex:0 0 auto;
    width:100%
}
.row-cols-2>*{
    flex:0 0 auto;
    width:50%
}
.row-cols-3>*{
    flex:0 0 auto;
    width:33.33333%
}
.row-cols-4>*{
    flex:0 0 auto;
    width:25%
}
.row-cols-5>*{
    flex:0 0 auto;
    width:20%
}
.row-cols-6>*{
    flex:0 0 auto;
    width:16.66667%
}
.col-auto{
    flex:0 0 auto;
    width:auto
}
.col-1{
    flex:0 0 auto;
    width:8.33333%
}
.col-2{
    flex:0 0 auto;
    width:16.66667%
}
.col-3{
    flex:0 0 auto;
    width:25%
}
.col-4{
    flex:0 0 auto;
    width:33.33333%
}
.col-5{
    flex:0 0 auto;
    width:41.66667%
}
.col-6{
    flex:0 0 auto;
    width:50%
}
.col-7{
    flex:0 0 auto;
    width:58.33333%
}
.col-8{
    flex:0 0 auto;
    width:66.66667%
}
.col-9{
    flex:0 0 auto;
    width:75%
}
.col-10{
    flex:0 0 auto;
    width:83.33333%
}
.col-11{
    flex:0 0 auto;
    width:91.66667%
}
.col-12{
    flex:0 0 auto;
    width:100%
}
.offset-1{
    margin-left:8.33333%
}
.offset-2{
    margin-left:16.66667%
}
.offset-3{
    margin-left:25%
}
.offset-4{
    margin-left:33.33333%
}
.offset-5{
    margin-left:41.66667%
}
.offset-6{
    margin-left:50%
}
.offset-7{
    margin-left:58.33333%
}
.offset-8{
    margin-left:66.66667%
}
.offset-9{
    margin-left:75%
}
.offset-10{
    margin-left:83.33333%
}
.offset-11{
    margin-left:91.66667%
}
.g-0,.gx-0{
    --bs-gutter-x:0
}
.g-0,.gy-0{
    --bs-gutter-y:0
}
.g-1,.gx-1{
    --bs-gutter-x:0.25rem
}
.g-1,.gy-1{
    --bs-gutter-y:0.25rem
}
.g-2,.gx-2{
    --bs-gutter-x:0.5rem
}
.g-2,.gy-2{
    --bs-gutter-y:0.5rem
}
.g-3,.gx-3{
    --bs-gutter-x:1rem
}
.g-3,.gy-3{
    --bs-gutter-y:1rem
}
.g-4,.gx-4{
    --bs-gutter-x:1.5rem
}
.g-4,.gy-4{
    --bs-gutter-y:1.5rem
}
.g-5,.gx-5{
    --bs-gutter-x:3rem
}
.g-5,.gy-5{
    --bs-gutter-y:3rem
}
@media (min-width:576px){
    .col-sm{
        flex:1 0 0%
    }
    .row-cols-sm-auto>*{
        flex:0 0 auto;
        width:auto
    }
    .row-cols-sm-1>*{
        flex:0 0 auto;
        width:100%
    }
    .row-cols-sm-2>*{
        flex:0 0 auto;
        width:50%
    }
    .row-cols-sm-3>*{
        flex:0 0 auto;
        width:33.33333%
    }
    .row-cols-sm-4>*{
        flex:0 0 auto;
        width:25%
    }
    .row-cols-sm-5>*{
        flex:0 0 auto;
        width:20%
    }
    .row-cols-sm-6>*{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-sm-auto{
        flex:0 0 auto;
        width:auto
    }
    .col-sm-1{
        flex:0 0 auto;
        width:8.33333%
    }
    .col-sm-2{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-sm-3{
        flex:0 0 auto;
        width:25%
    }
    .col-sm-4{
        flex:0 0 auto;
        width:33.33333%
    }
    .col-sm-5{
        flex:0 0 auto;
        width:41.66667%
    }
    .col-sm-6{
        flex:0 0 auto;
        width:50%
    }
    .col-sm-7{
        flex:0 0 auto;
        width:58.33333%
    }
    .col-sm-8{
        flex:0 0 auto;
        width:66.66667%
    }
    .col-sm-9{
        flex:0 0 auto;
        width:75%
    }
    .col-sm-10{
        flex:0 0 auto;
        width:83.33333%
    }
    .col-sm-11{
        flex:0 0 auto;
        width:91.66667%
    }
    .col-sm-12{
        flex:0 0 auto;
        width:100%
    }
    .offset-sm-0{
        margin-left:0
    }
    .offset-sm-1{
        margin-left:8.33333%
    }
    .offset-sm-2{
        margin-left:16.66667%
    }
    .offset-sm-3{
        margin-left:25%
    }
    .offset-sm-4{
        margin-left:33.33333%
    }
    .offset-sm-5{
        margin-left:41.66667%
    }
    .offset-sm-6{
        margin-left:50%
    }
    .offset-sm-7{
        margin-left:58.33333%
    }
    .offset-sm-8{
        margin-left:66.66667%
    }
    .offset-sm-9{
        margin-left:75%
    }
    .offset-sm-10{
        margin-left:83.33333%
    }
    .offset-sm-11{
        margin-left:91.66667%
    }
    .g-sm-0,.gx-sm-0{
        --bs-gutter-x:0
    }
    .g-sm-0,.gy-sm-0{
        --bs-gutter-y:0
    }
    .g-sm-1,.gx-sm-1{
        --bs-gutter-x:0.25rem
    }
    .g-sm-1,.gy-sm-1{
        --bs-gutter-y:0.25rem
    }
    .g-sm-2,.gx-sm-2{
        --bs-gutter-x:0.5rem
    }
    .g-sm-2,.gy-sm-2{
        --bs-gutter-y:0.5rem
    }
    .g-sm-3,.gx-sm-3{
        --bs-gutter-x:1rem
    }
    .g-sm-3,.gy-sm-3{
        --bs-gutter-y:1rem
    }
    .g-sm-4,.gx-sm-4{
        --bs-gutter-x:1.5rem
    }
    .g-sm-4,.gy-sm-4{
        --bs-gutter-y:1.5rem
    }
    .g-sm-5,.gx-sm-5{
        --bs-gutter-x:3rem
    }
    .g-sm-5,.gy-sm-5{
        --bs-gutter-y:3rem
    }
}
@media (min-width:768px){
    .col-md{
        flex:1 0 0%
    }
    .row-cols-md-auto>*{
        flex:0 0 auto;
        width:auto
    }
    .row-cols-md-1>*{
        flex:0 0 auto;
        width:100%
    }
    .row-cols-md-2>*{
        flex:0 0 auto;
        width:50%
    }
    .row-cols-md-3>*{
        flex:0 0 auto;
        width:33.33333%
    }
    .row-cols-md-4>*{
        flex:0 0 auto;
        width:25%
    }
    .row-cols-md-5>*{
        flex:0 0 auto;
        width:20%
    }
    .row-cols-md-6>*{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-md-auto{
        flex:0 0 auto;
        width:auto
    }
    .col-md-1{
        flex:0 0 auto;
        width:8.33333%
    }
    .col-md-2{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-md-3{
        flex:0 0 auto;
        width:25%
    }
    .col-md-4{
        flex:0 0 auto;
        width:33.33333%
    }
    .col-md-5{
        flex:0 0 auto;
        width:41.66667%
    }
    .col-md-6{
        flex:0 0 auto;
        width:50%
    }
    .col-md-7{
        flex:0 0 auto;
        width:58.33333%
    }
    .col-md-8{
        flex:0 0 auto;
        width:66.66667%
    }
    .col-md-9{
        flex:0 0 auto;
        width:75%
    }
    .col-md-10{
        flex:0 0 auto;
        width:83.33333%
    }
    .col-md-11{
        flex:0 0 auto;
        width:91.66667%
    }
    .col-md-12{
        flex:0 0 auto;
        width:100%
    }
    .offset-md-0{
        margin-left:0
    }
    .offset-md-1{
        margin-left:8.33333%
    }
    .offset-md-2{
        margin-left:16.66667%
    }
    .offset-md-3{
        margin-left:25%
    }
    .offset-md-4{
        margin-left:33.33333%
    }
    .offset-md-5{
        margin-left:41.66667%
    }
    .offset-md-6{
        margin-left:50%
    }
    .offset-md-7{
        margin-left:58.33333%
    }
    .offset-md-8{
        margin-left:66.66667%
    }
    .offset-md-9{
        margin-left:75%
    }
    .offset-md-10{
        margin-left:83.33333%
    }
    .offset-md-11{
        margin-left:91.66667%
    }
    .g-md-0,.gx-md-0{
        --bs-gutter-x:0
    }
    .g-md-0,.gy-md-0{
        --bs-gutter-y:0
    }
    .g-md-1,.gx-md-1{
        --bs-gutter-x:0.25rem
    }
    .g-md-1,.gy-md-1{
        --bs-gutter-y:0.25rem
    }
    .g-md-2,.gx-md-2{
        --bs-gutter-x:0.5rem
    }
    .g-md-2,.gy-md-2{
        --bs-gutter-y:0.5rem
    }
    .g-md-3,.gx-md-3{
        --bs-gutter-x:1rem
    }
    .g-md-3,.gy-md-3{
        --bs-gutter-y:1rem
    }
    .g-md-4,.gx-md-4{
        --bs-gutter-x:1.5rem
    }
    .g-md-4,.gy-md-4{
        --bs-gutter-y:1.5rem
    }
    .g-md-5,.gx-md-5{
        --bs-gutter-x:3rem
    }
    .g-md-5,.gy-md-5{
        --bs-gutter-y:3rem
    }
}
@media (min-width:992px){
    .col-lg{
        flex:1 0 0%
    }
    .row-cols-lg-auto>*{
        flex:0 0 auto;
        width:auto
    }
    .row-cols-lg-1>*{
        flex:0 0 auto;
        width:100%
    }
    .row-cols-lg-2>*{
        flex:0 0 auto;
        width:50%
    }
    .row-cols-lg-3>*{
        flex:0 0 auto;
        width:33.33333%
    }
    .row-cols-lg-4>*{
        flex:0 0 auto;
        width:25%
    }
    .row-cols-lg-5>*{
        flex:0 0 auto;
        width:20%
    }
    .row-cols-lg-6>*{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-lg-auto{
        flex:0 0 auto;
        width:auto
    }
    .col-lg-1{
        flex:0 0 auto;
        width:8.33333%
    }
    .col-lg-2{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-lg-3{
        flex:0 0 auto;
        width:25%
    }
    .col-lg-4{
        flex:0 0 auto;
        width:33.33333%
    }
    .col-lg-5{
        flex:0 0 auto;
        width:41.66667%
    }
    .col-lg-6{
        flex:0 0 auto;
        width:50%
    }
    .col-lg-7{
        flex:0 0 auto;
        width:58.33333%
    }
    .col-lg-8{
        flex:0 0 auto;
        width:66.66667%
    }
    .col-lg-9{
        flex:0 0 auto;
        width:75%
    }
    .col-lg-10{
        flex:0 0 auto;
        width:83.33333%
    }
    .col-lg-11{
        flex:0 0 auto;
        width:91.66667%
    }
    .col-lg-12{
        flex:0 0 auto;
        width:100%
    }
    .offset-lg-0{
        margin-left:0
    }
    .offset-lg-1{
        margin-left:8.33333%
    }
    .offset-lg-2{
        margin-left:16.66667%
    }
    .offset-lg-3{
        margin-left:25%
    }
    .offset-lg-4{
        margin-left:33.33333%
    }
    .offset-lg-5{
        margin-left:41.66667%
    }
    .offset-lg-6{
        margin-left:50%
    }
    .offset-lg-7{
        margin-left:58.33333%
    }
    .offset-lg-8{
        margin-left:66.66667%
    }
    .offset-lg-9{
        margin-left:75%
    }
    .offset-lg-10{
        margin-left:83.33333%
    }
    .offset-lg-11{
        margin-left:91.66667%
    }
    .g-lg-0,.gx-lg-0{
        --bs-gutter-x:0
    }
    .g-lg-0,.gy-lg-0{
        --bs-gutter-y:0
    }
    .g-lg-1,.gx-lg-1{
        --bs-gutter-x:0.25rem
    }
    .g-lg-1,.gy-lg-1{
        --bs-gutter-y:0.25rem
    }
    .g-lg-2,.gx-lg-2{
        --bs-gutter-x:0.5rem
    }
    .g-lg-2,.gy-lg-2{
        --bs-gutter-y:0.5rem
    }
    .g-lg-3,.gx-lg-3{
        --bs-gutter-x:1rem
    }
    .g-lg-3,.gy-lg-3{
        --bs-gutter-y:1rem
    }
    .g-lg-4,.gx-lg-4{
        --bs-gutter-x:1.5rem
    }
    .g-lg-4,.gy-lg-4{
        --bs-gutter-y:1.5rem
    }
    .g-lg-5,.gx-lg-5{
        --bs-gutter-x:3rem
    }
    .g-lg-5,.gy-lg-5{
        --bs-gutter-y:3rem
    }
}
@media (min-width:1200px){
    .col-xl{
        flex:1 0 0%
    }
    .row-cols-xl-auto>*{
        flex:0 0 auto;
        width:auto
    }
    .row-cols-xl-1>*{
        flex:0 0 auto;
        width:100%
    }
    .row-cols-xl-2>*{
        flex:0 0 auto;
        width:50%
    }
    .row-cols-xl-3>*{
        flex:0 0 auto;
        width:33.33333%
    }
    .row-cols-xl-4>*{
        flex:0 0 auto;
        width:25%
    }
    .row-cols-xl-5>*{
        flex:0 0 auto;
        width:20%
    }
    .row-cols-xl-6>*{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-xl-auto{
        flex:0 0 auto;
        width:auto
    }
    .col-xl-1{
        flex:0 0 auto;
        width:8.33333%
    }
    .col-xl-2{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-xl-3{
        flex:0 0 auto;
        width:25%
    }
    .col-xl-4{
        flex:0 0 auto;
        width:33.33333%
    }
    .col-xl-5{
        flex:0 0 auto;
        width:41.66667%
    }
    .col-xl-6{
        flex:0 0 auto;
        width:50%
    }
    .col-xl-7{
        flex:0 0 auto;
        width:58.33333%
    }
    .col-xl-8{
        flex:0 0 auto;
        width:66.66667%
    }
    .col-xl-9{
        flex:0 0 auto;
        width:75%
    }
    .col-xl-10{
        flex:0 0 auto;
        width:83.33333%
    }
    .col-xl-11{
        flex:0 0 auto;
        width:91.66667%
    }
    .col-xl-12{
        flex:0 0 auto;
        width:100%
    }
    .offset-xl-0{
        margin-left:0
    }
    .offset-xl-1{
        margin-left:8.33333%
    }
    .offset-xl-2{
        margin-left:16.66667%
    }
    .offset-xl-3{
        margin-left:25%
    }
    .offset-xl-4{
        margin-left:33.33333%
    }
    .offset-xl-5{
        margin-left:41.66667%
    }
    .offset-xl-6{
        margin-left:50%
    }
    .offset-xl-7{
        margin-left:58.33333%
    }
    .offset-xl-8{
        margin-left:66.66667%
    }
    .offset-xl-9{
        margin-left:75%
    }
    .offset-xl-10{
        margin-left:83.33333%
    }
    .offset-xl-11{
        margin-left:91.66667%
    }
    .g-xl-0,.gx-xl-0{
        --bs-gutter-x:0
    }
    .g-xl-0,.gy-xl-0{
        --bs-gutter-y:0
    }
    .g-xl-1,.gx-xl-1{
        --bs-gutter-x:0.25rem
    }
    .g-xl-1,.gy-xl-1{
        --bs-gutter-y:0.25rem
    }
    .g-xl-2,.gx-xl-2{
        --bs-gutter-x:0.5rem
    }
    .g-xl-2,.gy-xl-2{
        --bs-gutter-y:0.5rem
    }
    .g-xl-3,.gx-xl-3{
        --bs-gutter-x:1rem
    }
    .g-xl-3,.gy-xl-3{
        --bs-gutter-y:1rem
    }
    .g-xl-4,.gx-xl-4{
        --bs-gutter-x:1.5rem
    }
    .g-xl-4,.gy-xl-4{
        --bs-gutter-y:1.5rem
    }
    .g-xl-5,.gx-xl-5{
        --bs-gutter-x:3rem
    }
    .g-xl-5,.gy-xl-5{
        --bs-gutter-y:3rem
    }
}
@media (min-width:1400px){
    .col-xxl{
        flex:1 0 0%
    }
    .row-cols-xxl-auto>*{
        flex:0 0 auto;
        width:auto
    }
    .row-cols-xxl-1>*{
        flex:0 0 auto;
        width:100%
    }
    .row-cols-xxl-2>*{
        flex:0 0 auto;
        width:50%
    }
    .row-cols-xxl-3>*{
        flex:0 0 auto;
        width:33.33333%
    }
    .row-cols-xxl-4>*{
        flex:0 0 auto;
        width:25%
    }
    .row-cols-xxl-5>*{
        flex:0 0 auto;
        width:20%
    }
    .row-cols-xxl-6>*{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-xxl-auto{
        flex:0 0 auto;
        width:auto
    }
    .col-xxl-1{
        flex:0 0 auto;
        width:8.33333%
    }
    .col-xxl-2{
        flex:0 0 auto;
        width:16.66667%
    }
    .col-xxl-3{
        flex:0 0 auto;
        width:25%
    }
    .col-xxl-4{
        flex:0 0 auto;
        width:33.33333%
    }
    .col-xxl-5{
        flex:0 0 auto;
        width:41.66667%
    }
    .col-xxl-6{
        flex:0 0 auto;
        width:50%
    }
    .col-xxl-7{
        flex:0 0 auto;
        width:58.33333%
    }
    .col-xxl-8{
        flex:0 0 auto;
        width:66.66667%
    }
    .col-xxl-9{
        flex:0 0 auto;
        width:75%
    }
    .col-xxl-10{
        flex:0 0 auto;
        width:83.33333%
    }
    .col-xxl-11{
        flex:0 0 auto;
        width:91.66667%
    }
    .col-xxl-12{
        flex:0 0 auto;
        width:100%
    }
    .offset-xxl-0{
        margin-left:0
    }
    .offset-xxl-1{
        margin-left:8.33333%
    }
    .offset-xxl-2{
        margin-left:16.66667%
    }
    .offset-xxl-3{
        margin-left:25%
    }
    .offset-xxl-4{
        margin-left:33.33333%
    }
    .offset-xxl-5{
        margin-left:41.66667%
    }
    .offset-xxl-6{
        margin-left:50%
    }
    .offset-xxl-7{
        margin-left:58.33333%
    }
    .offset-xxl-8{
        margin-left:66.66667%
    }
    .offset-xxl-9{
        margin-left:75%
    }
    .offset-xxl-10{
        margin-left:83.33333%
    }
    .offset-xxl-11{
        margin-left:91.66667%
    }
    .g-xxl-0,.gx-xxl-0{
        --bs-gutter-x:0
    }
    .g-xxl-0,.gy-xxl-0{
        --bs-gutter-y:0
    }
    .g-xxl-1,.gx-xxl-1{
        --bs-gutter-x:0.25rem
    }
    .g-xxl-1,.gy-xxl-1{
        --bs-gutter-y:0.25rem
    }
    .g-xxl-2,.gx-xxl-2{
        --bs-gutter-x:0.5rem
    }
    .g-xxl-2,.gy-xxl-2{
        --bs-gutter-y:0.5rem
    }
    .g-xxl-3,.gx-xxl-3{
        --bs-gutter-x:1rem
    }
    .g-xxl-3,.gy-xxl-3{
        --bs-gutter-y:1rem
    }
    .g-xxl-4,.gx-xxl-4{
        --bs-gutter-x:1.5rem
    }
    .g-xxl-4,.gy-xxl-4{
        --bs-gutter-y:1.5rem
    }
    .g-xxl-5,.gx-xxl-5{
        --bs-gutter-x:3rem
    }
    .g-xxl-5,.gy-xxl-5{
        --bs-gutter-y:3rem
    }
}
.d-inline{
    display:inline!important
}
.d-inline-block{
    display:inline-block!important
}
.d-block{
    display:block!important
}
.d-grid{
    display:grid!important
}
.d-table{
    display:table!important
}
.d-table-row{
    display:table-row!important
}
.d-table-cell{
    display:table-cell!important
}
/* .d-flex{
    display:flex!important
} */
.d-inline-flex{
    display:inline-flex!important
}
.d-none{
    display:none!important
}
.flex-fill{
    flex:1 1 auto!important
}
.flex-row{
    flex-direction:row!important
}
.flex-column{
    flex-direction:column!important
}
.flex-row-reverse{
    flex-direction:row-reverse!important
}
.flex-column-reverse{
    flex-direction:column-reverse!important
}
.flex-grow-0{
    flex-grow:0!important
}
.flex-grow-1{
    flex-grow:1!important
}
.flex-shrink-0{
    flex-shrink:0!important
}
.flex-shrink-1{
    flex-shrink:1!important
}
.flex-wrap{
    flex-wrap:wrap!important
}
.flex-nowrap{
    flex-wrap:nowrap!important
}
.flex-wrap-reverse{
    flex-wrap:wrap-reverse!important
}
.justify-content-start{
    justify-content:flex-start!important
}
.justify-content-end{
    justify-content:flex-end!important
}
.justify-content-center{
    justify-content:center!important
}
.justify-content-between{
    justify-content:space-between!important
}
.justify-content-around{
    justify-content:space-around!important
}
.justify-content-evenly{
    justify-content:space-evenly!important
}
.align-items-start{
    align-items:flex-start!important
}
.align-items-end{
    align-items:flex-end!important
}
.align-items-center{
    align-items:center!important
}
.align-items-baseline{
    align-items:baseline!important
}
.align-items-stretch{
    align-items:stretch!important
}
.align-content-start{
    align-content:flex-start!important
}
.align-content-end{
    align-content:flex-end!important
}
.align-content-center{
    align-content:center!important
}
.align-content-between{
    align-content:space-between!important
}
.align-content-around{
    align-content:space-around!important
}
.align-content-stretch{
    align-content:stretch!important
}
.align-self-auto{
    align-self:auto!important
}
.align-self-start{
    align-self:flex-start!important
}
.align-self-end{
    align-self:flex-end!important
}
.align-self-center{
    align-self:center!important
}
.align-self-baseline{
    align-self:baseline!important
}
.align-self-stretch{
    align-self:stretch!important
}
.order-first{
    order:-1!important
}
.order-0{
    order:0!important
}
.order-1{
    order:1!important
}
.order-2{
    order:2!important
}
.order-3{
    order:3!important
}
.order-4{
    order:4!important
}
.order-5{
    order:5!important
}
.order-last{
    order:6!important
}
.m-0{
    margin:0!important
}
.m-1{
    margin:.25rem!important
}
.m-2{
    margin:.5rem!important
}
.m-3{
    margin:1rem!important
}
.m-4{
    margin:1.5rem!important
}
.m-5{
    margin:3rem!important
}
.m-auto{
    margin:auto!important
}
.mx-0{
    margin-right:0!important;
    margin-left:0!important
}
.mx-1{
    margin-right:.25rem!important;
    margin-left:.25rem!important
}
.mx-2{
    margin-right:.5rem!important;
    margin-left:.5rem!important
}
.mx-3{
    margin-right:1rem!important;
    margin-left:1rem!important
}
.mx-4{
    margin-right:1.5rem!important;
    margin-left:1.5rem!important
}
.mx-5{
    margin-right:3rem!important;
    margin-left:3rem!important
}
.mx-auto{
    margin-right:auto!important;
    margin-left:auto!important
}
.my-0{
    margin-top:0!important;
    margin-bottom:0!important
}
.my-1{
    margin-top:.25rem!important;
    margin-bottom:.25rem!important
}
.my-2{
    margin-top:.5rem!important;
    margin-bottom:.5rem!important
}
.my-3{
    margin-top:1rem!important;
    margin-bottom:1rem!important
}
.my-4{
    margin-top:1.5rem!important;
    margin-bottom:1.5rem!important
}
.my-5{
    margin-top:3rem!important;
    margin-bottom:3rem!important
}
.my-auto{
    margin-top:auto!important;
    margin-bottom:auto!important
}
.mt-0{
    margin-top:0!important
}
.mt-1{
    margin-top:.25rem!important
}
.mt-2{
    margin-top:.5rem!important
}
.mt-3{
    margin-top:1rem!important
}
.mt-4{
    margin-top:1.5rem!important
}
.mt-5{
    margin-top:3rem!important
}
.mt-auto{
    margin-top:auto!important
}
.me-0{
    margin-right:0!important
}
.me-1{
    margin-right:.25rem!important
}
.me-2{
    margin-right:.5rem!important
}
.me-3{
    margin-right:1rem!important
}
.me-4{
    margin-right:1.5rem!important
}
.me-5{
    margin-right:3rem!important
}
.me-auto{
    margin-right:auto!important
}
.mb-0{
    margin-bottom:0!important
}
.mb-1{
    margin-bottom:.25rem!important
}
.mb-2{
    margin-bottom:.5rem!important
}
.mb-3{
    margin-bottom:1rem!important
}
.mb-4{
    margin-bottom:1.5rem!important
}
.mb-5{
    margin-bottom:3rem!important
}
.mb-auto{
    margin-bottom:auto!important
}
.ms-0{
    margin-left:0!important
}
.ms-1{
    margin-left:.25rem!important
}
.ms-2{
    margin-left:.5rem!important
}
.ms-3{
    margin-left:1rem!important
}
.ms-4{
    margin-left:1.5rem!important
}
.ms-5{
    margin-left:3rem!important
}
.ms-auto{
    margin-left:auto!important
}
.p-0{
    padding:0!important
}
.p-1{
    padding:.25rem!important
}
.p-2{
    padding:.5rem!important
}
.p-3{
    padding:1rem!important
}
.p-4{
    padding:1.5rem!important
}
.p-5{
    padding:3rem!important
}
.px-0{
    padding-right:0!important;
    padding-left:0!important
}
.px-1{
    padding-right:.25rem!important;
    padding-left:.25rem!important
}
.px-2{
    padding-right:.5rem!important;
    padding-left:.5rem!important
}
.px-3{
    padding-right:1rem!important;
    padding-left:1rem!important
}
.px-4{
    padding-right:1.5rem!important;
    padding-left:1.5rem!important
}
.px-5{
    padding-right:3rem!important;
    padding-left:3rem!important
}
.py-0{
    padding-top:0!important;
    padding-bottom:0!important
}
.py-1{
    padding-top:.25rem!important;
    padding-bottom:.25rem!important
}
.py-2{
    padding-top:.5rem!important;
    padding-bottom:.5rem!important
}
.py-3{
    padding-top:1rem!important;
    padding-bottom:1rem!important
}
.py-4{
    padding-top:1.5rem!important;
    padding-bottom:1.5rem!important
}
.py-5{
    padding-top:3rem!important;
    padding-bottom:3rem!important
}
.pt-0{
    padding-top:0!important
}
.pt-1{
    padding-top:.25rem!important
}
.pt-2{
    padding-top:.5rem!important
}
.pt-3{
    padding-top:1rem!important
}
.pt-4{
    padding-top:1.5rem!important
}
.pt-5{
    padding-top:3rem!important
}
.pe-0{
    padding-right:0!important
}
.pe-1{
    padding-right:.25rem!important
}
.pe-2{
    padding-right:.5rem!important
}
.pe-3{
    padding-right:1rem!important
}
.pe-4{
    padding-right:1.5rem!important
}
.pe-5{
    padding-right:3rem!important
}
.pb-0{
    padding-bottom:0!important
}
.pb-1{
    padding-bottom:.25rem!important
}
.pb-2{
    padding-bottom:.5rem!important
}
.pb-3{
    padding-bottom:1rem!important
}
.pb-4{
    padding-bottom:1.5rem!important
}
.pb-5{
    padding-bottom:3rem!important
}
.ps-0{
    padding-left:0!important
}
.ps-1{
    padding-left:.25rem!important
}
.ps-2{
    padding-left:.5rem!important
}
.ps-3{
    padding-left:1rem!important
}
.ps-4{
    padding-left:1.5rem!important
}
.ps-5{
    padding-left:3rem!important
}
@media (min-width:576px){
    .d-sm-inline{
        display:inline!important
    }
    .d-sm-inline-block{
        display:inline-block!important
    }
    .d-sm-block{
        display:block!important
    }
    .d-sm-grid{
        display:grid!important
    }
    .d-sm-table{
        display:table!important
    }
    .d-sm-table-row{
        display:table-row!important
    }
    .d-sm-table-cell{
        display:table-cell!important
    }
    .d-sm-flex{
        display:flex!important
    }
    .d-sm-inline-flex{
        display:inline-flex!important
    }
    .d-sm-none{
        display:none!important
    }
    .flex-sm-fill{
        flex:1 1 auto!important
    }
    .flex-sm-row{
        flex-direction:row!important
    }
    .flex-sm-column{
        flex-direction:column!important
    }
    .flex-sm-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-sm-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-sm-grow-0{
        flex-grow:0!important
    }
    .flex-sm-grow-1{
        flex-grow:1!important
    }
    .flex-sm-shrink-0{
        flex-shrink:0!important
    }
    .flex-sm-shrink-1{
        flex-shrink:1!important
    }
    .flex-sm-wrap{
        flex-wrap:wrap!important
    }
    .flex-sm-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-sm-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .justify-content-sm-start{
        justify-content:flex-start!important
    }
    .justify-content-sm-end{
        justify-content:flex-end!important
    }
    .justify-content-sm-center{
        justify-content:center!important
    }
    .justify-content-sm-between{
        justify-content:space-between!important
    }
    .justify-content-sm-around{
        justify-content:space-around!important
    }
    .justify-content-sm-evenly{
        justify-content:space-evenly!important
    }
    .align-items-sm-start{
        align-items:flex-start!important
    }
    .align-items-sm-end{
        align-items:flex-end!important
    }
    .align-items-sm-center{
        align-items:center!important
    }
    .align-items-sm-baseline{
        align-items:baseline!important
    }
    .align-items-sm-stretch{
        align-items:stretch!important
    }
    .align-content-sm-start{
        align-content:flex-start!important
    }
    .align-content-sm-end{
        align-content:flex-end!important
    }
    .align-content-sm-center{
        align-content:center!important
    }
    .align-content-sm-between{
        align-content:space-between!important
    }
    .align-content-sm-around{
        align-content:space-around!important
    }
    .align-content-sm-stretch{
        align-content:stretch!important
    }
    .align-self-sm-auto{
        align-self:auto!important
    }
    .align-self-sm-start{
        align-self:flex-start!important
    }
    .align-self-sm-end{
        align-self:flex-end!important
    }
    .align-self-sm-center{
        align-self:center!important
    }
    .align-self-sm-baseline{
        align-self:baseline!important
    }
    .align-self-sm-stretch{
        align-self:stretch!important
    }
    .order-sm-first{
        order:-1!important
    }
    .order-sm-0{
        order:0!important
    }
    .order-sm-1{
        order:1!important
    }
    .order-sm-2{
        order:2!important
    }
    .order-sm-3{
        order:3!important
    }
    .order-sm-4{
        order:4!important
    }
    .order-sm-5{
        order:5!important
    }
    .order-sm-last{
        order:6!important
    }
    .m-sm-0{
        margin:0!important
    }
    .m-sm-1{
        margin:.25rem!important
    }
    .m-sm-2{
        margin:.5rem!important
    }
    .m-sm-3{
        margin:1rem!important
    }
    .m-sm-4{
        margin:1.5rem!important
    }
    .m-sm-5{
        margin:3rem!important
    }
    .m-sm-auto{
        margin:auto!important
    }
    .mx-sm-0{
        margin-right:0!important;
        margin-left:0!important
    }
    .mx-sm-1{
        margin-right:.25rem!important;
        margin-left:.25rem!important
    }
    .mx-sm-2{
        margin-right:.5rem!important;
        margin-left:.5rem!important
    }
    .mx-sm-3{
        margin-right:1rem!important;
        margin-left:1rem!important
    }
    .mx-sm-4{
        margin-right:1.5rem!important;
        margin-left:1.5rem!important
    }
    .mx-sm-5{
        margin-right:3rem!important;
        margin-left:3rem!important
    }
    .mx-sm-auto{
        margin-right:auto!important;
        margin-left:auto!important
    }
    .my-sm-0{
        margin-top:0!important;
        margin-bottom:0!important
    }
    .my-sm-1{
        margin-top:.25rem!important;
        margin-bottom:.25rem!important
    }
    .my-sm-2{
        margin-top:.5rem!important;
        margin-bottom:.5rem!important
    }
    .my-sm-3{
        margin-top:1rem!important;
        margin-bottom:1rem!important
    }
    .my-sm-4{
        margin-top:1.5rem!important;
        margin-bottom:1.5rem!important
    }
    .my-sm-5{
        margin-top:3rem!important;
        margin-bottom:3rem!important
    }
    .my-sm-auto{
        margin-top:auto!important;
        margin-bottom:auto!important
    }
    .mt-sm-0{
        margin-top:0!important
    }
    .mt-sm-1{
        margin-top:.25rem!important
    }
    .mt-sm-2{
        margin-top:.5rem!important
    }
    .mt-sm-3{
        margin-top:1rem!important
    }
    .mt-sm-4{
        margin-top:1.5rem!important
    }
    .mt-sm-5{
        margin-top:3rem!important
    }
    .mt-sm-auto{
        margin-top:auto!important
    }
    .me-sm-0{
        margin-right:0!important
    }
    .me-sm-1{
        margin-right:.25rem!important
    }
    .me-sm-2{
        margin-right:.5rem!important
    }
    .me-sm-3{
        margin-right:1rem!important
    }
    .me-sm-4{
        margin-right:1.5rem!important
    }
    .me-sm-5{
        margin-right:3rem!important
    }
    .me-sm-auto{
        margin-right:auto!important
    }
    .mb-sm-0{
        margin-bottom:0!important
    }
    .mb-sm-1{
        margin-bottom:.25rem!important
    }
    .mb-sm-2{
        margin-bottom:.5rem!important
    }
    .mb-sm-3{
        margin-bottom:1rem!important
    }
    .mb-sm-4{
        margin-bottom:1.5rem!important
    }
    .mb-sm-5{
        margin-bottom:3rem!important
    }
    .mb-sm-auto{
        margin-bottom:auto!important
    }
    .ms-sm-0{
        margin-left:0!important
    }
    .ms-sm-1{
        margin-left:.25rem!important
    }
    .ms-sm-2{
        margin-left:.5rem!important
    }
    .ms-sm-3{
        margin-left:1rem!important
    }
    .ms-sm-4{
        margin-left:1.5rem!important
    }
    .ms-sm-5{
        margin-left:3rem!important
    }
    .ms-sm-auto{
        margin-left:auto!important
    }
    .p-sm-0{
        padding:0!important
    }
    .p-sm-1{
        padding:.25rem!important
    }
    .p-sm-2{
        padding:.5rem!important
    }
    .p-sm-3{
        padding:1rem!important
    }
    .p-sm-4{
        padding:1.5rem!important
    }
    .p-sm-5{
        padding:3rem!important
    }
    .px-sm-0{
        padding-right:0!important;
        padding-left:0!important
    }
    .px-sm-1{
        padding-right:.25rem!important;
        padding-left:.25rem!important
    }
    .px-sm-2{
        padding-right:.5rem!important;
        padding-left:.5rem!important
    }
    .px-sm-3{
        padding-right:1rem!important;
        padding-left:1rem!important
    }
    .px-sm-4{
        padding-right:1.5rem!important;
        padding-left:1.5rem!important
    }
    .px-sm-5{
        padding-right:3rem!important;
        padding-left:3rem!important
    }
    .py-sm-0{
        padding-top:0!important;
        padding-bottom:0!important
    }
    .py-sm-1{
        padding-top:.25rem!important;
        padding-bottom:.25rem!important
    }
    .py-sm-2{
        padding-top:.5rem!important;
        padding-bottom:.5rem!important
    }
    .py-sm-3{
        padding-top:1rem!important;
        padding-bottom:1rem!important
    }
    .py-sm-4{
        padding-top:1.5rem!important;
        padding-bottom:1.5rem!important
    }
    .py-sm-5{
        padding-top:3rem!important;
        padding-bottom:3rem!important
    }
    .pt-sm-0{
        padding-top:0!important
    }
    .pt-sm-1{
        padding-top:.25rem!important
    }
    .pt-sm-2{
        padding-top:.5rem!important
    }
    .pt-sm-3{
        padding-top:1rem!important
    }
    .pt-sm-4{
        padding-top:1.5rem!important
    }
    .pt-sm-5{
        padding-top:3rem!important
    }
    .pe-sm-0{
        padding-right:0!important
    }
    .pe-sm-1{
        padding-right:.25rem!important
    }
    .pe-sm-2{
        padding-right:.5rem!important
    }
    .pe-sm-3{
        padding-right:1rem!important
    }
    .pe-sm-4{
        padding-right:1.5rem!important
    }
    .pe-sm-5{
        padding-right:3rem!important
    }
    .pb-sm-0{
        padding-bottom:0!important
    }
    .pb-sm-1{
        padding-bottom:.25rem!important
    }
    .pb-sm-2{
        padding-bottom:.5rem!important
    }
    .pb-sm-3{
        padding-bottom:1rem!important
    }
    .pb-sm-4{
        padding-bottom:1.5rem!important
    }
    .pb-sm-5{
        padding-bottom:3rem!important
    }
    .ps-sm-0{
        padding-left:0!important
    }
    .ps-sm-1{
        padding-left:.25rem!important
    }
    .ps-sm-2{
        padding-left:.5rem!important
    }
    .ps-sm-3{
        padding-left:1rem!important
    }
    .ps-sm-4{
        padding-left:1.5rem!important
    }
    .ps-sm-5{
        padding-left:3rem!important
    }
}
@media (min-width:768px){
    .d-md-inline{
        display:inline!important
    }
    .d-md-inline-block{
        display:inline-block!important
    }
    .d-md-block{
        display:block!important
    }
    .d-md-grid{
        display:grid!important
    }
    .d-md-table{
        display:table!important
    }
    .d-md-table-row{
        display:table-row!important
    }
    .d-md-table-cell{
        display:table-cell!important
    }
    .d-md-flex{
        display:flex!important
    }
    .d-md-inline-flex{
        display:inline-flex!important
    }
    .d-md-none{
        display:none!important
    }
    .flex-md-fill{
        flex:1 1 auto!important
    }
    .flex-md-row{
        flex-direction:row!important
    }
    .flex-md-column{
        flex-direction:column!important
    }
    .flex-md-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-md-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-md-grow-0{
        flex-grow:0!important
    }
    .flex-md-grow-1{
        flex-grow:1!important
    }
    .flex-md-shrink-0{
        flex-shrink:0!important
    }
    .flex-md-shrink-1{
        flex-shrink:1!important
    }
    .flex-md-wrap{
        flex-wrap:wrap!important
    }
    .flex-md-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-md-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .justify-content-md-start{
        justify-content:flex-start!important
    }
    .justify-content-md-end{
        justify-content:flex-end!important
    }
    .justify-content-md-center{
        justify-content:center!important
    }
    .justify-content-md-between{
        justify-content:space-between!important
    }
    .justify-content-md-around{
        justify-content:space-around!important
    }
    .justify-content-md-evenly{
        justify-content:space-evenly!important
    }
    .align-items-md-start{
        align-items:flex-start!important
    }
    .align-items-md-end{
        align-items:flex-end!important
    }
    .align-items-md-center{
        align-items:center!important
    }
    .align-items-md-baseline{
        align-items:baseline!important
    }
    .align-items-md-stretch{
        align-items:stretch!important
    }
    .align-content-md-start{
        align-content:flex-start!important
    }
    .align-content-md-end{
        align-content:flex-end!important
    }
    .align-content-md-center{
        align-content:center!important
    }
    .align-content-md-between{
        align-content:space-between!important
    }
    .align-content-md-around{
        align-content:space-around!important
    }
    .align-content-md-stretch{
        align-content:stretch!important
    }
    .align-self-md-auto{
        align-self:auto!important
    }
    .align-self-md-start{
        align-self:flex-start!important
    }
    .align-self-md-end{
        align-self:flex-end!important
    }
    .align-self-md-center{
        align-self:center!important
    }
    .align-self-md-baseline{
        align-self:baseline!important
    }
    .align-self-md-stretch{
        align-self:stretch!important
    }
    .order-md-first{
        order:-1!important
    }
    .order-md-0{
        order:0!important
    }
    .order-md-1{
        order:1!important
    }
    .order-md-2{
        order:2!important
    }
    .order-md-3{
        order:3!important
    }
    .order-md-4{
        order:4!important
    }
    .order-md-5{
        order:5!important
    }
    .order-md-last{
        order:6!important
    }
    .m-md-0{
        margin:0!important
    }
    .m-md-1{
        margin:.25rem!important
    }
    .m-md-2{
        margin:.5rem!important
    }
    .m-md-3{
        margin:1rem!important
    }
    .m-md-4{
        margin:1.5rem!important
    }
    .m-md-5{
        margin:3rem!important
    }
    .m-md-auto{
        margin:auto!important
    }
    .mx-md-0{
        margin-right:0!important;
        margin-left:0!important
    }
    .mx-md-1{
        margin-right:.25rem!important;
        margin-left:.25rem!important
    }
    .mx-md-2{
        margin-right:.5rem!important;
        margin-left:.5rem!important
    }
    .mx-md-3{
        margin-right:1rem!important;
        margin-left:1rem!important
    }
    .mx-md-4{
        margin-right:1.5rem!important;
        margin-left:1.5rem!important
    }
    .mx-md-5{
        margin-right:3rem!important;
        margin-left:3rem!important
    }
    .mx-md-auto{
        margin-right:auto!important;
        margin-left:auto!important
    }
    .my-md-0{
        margin-top:0!important;
        margin-bottom:0!important
    }
    .my-md-1{
        margin-top:.25rem!important;
        margin-bottom:.25rem!important
    }
    .my-md-2{
        margin-top:.5rem!important;
        margin-bottom:.5rem!important
    }
    .my-md-3{
        margin-top:1rem!important;
        margin-bottom:1rem!important
    }
    .my-md-4{
        margin-top:1.5rem!important;
        margin-bottom:1.5rem!important
    }
    .my-md-5{
        margin-top:3rem!important;
        margin-bottom:3rem!important
    }
    .my-md-auto{
        margin-top:auto!important;
        margin-bottom:auto!important
    }
    .mt-md-0{
        margin-top:0!important
    }
    .mt-md-1{
        margin-top:.25rem!important
    }
    .mt-md-2{
        margin-top:.5rem!important
    }
    .mt-md-3{
        margin-top:1rem!important
    }
    .mt-md-4{
        margin-top:1.5rem!important
    }
    .mt-md-5{
        margin-top:3rem!important
    }
    .mt-md-auto{
        margin-top:auto!important
    }
    .me-md-0{
        margin-right:0!important
    }
    .me-md-1{
        margin-right:.25rem!important
    }
    .me-md-2{
        margin-right:.5rem!important
    }
    .me-md-3{
        margin-right:1rem!important
    }
    .me-md-4{
        margin-right:1.5rem!important
    }
    .me-md-5{
        margin-right:3rem!important
    }
    .me-md-auto{
        margin-right:auto!important
    }
    .mb-md-0{
        margin-bottom:0!important
    }
    .mb-md-1{
        margin-bottom:.25rem!important
    }
    .mb-md-2{
        margin-bottom:.5rem!important
    }
    .mb-md-3{
        margin-bottom:1rem!important
    }
    .mb-md-4{
        margin-bottom:1.5rem!important
    }
    .mb-md-5{
        margin-bottom:3rem!important
    }
    .mb-md-auto{
        margin-bottom:auto!important
    }
    .ms-md-0{
        margin-left:0!important
    }
    .ms-md-1{
        margin-left:.25rem!important
    }
    .ms-md-2{
        margin-left:.5rem!important
    }
    .ms-md-3{
        margin-left:1rem!important
    }
    .ms-md-4{
        margin-left:1.5rem!important
    }
    .ms-md-5{
        margin-left:3rem!important
    }
    .ms-md-auto{
        margin-left:auto!important
    }
    .p-md-0{
        padding:0!important
    }
    .p-md-1{
        padding:.25rem!important
    }
    .p-md-2{
        padding:.5rem!important
    }
    .p-md-3{
        padding:1rem!important
    }
    .p-md-4{
        padding:1.5rem!important
    }
    .p-md-5{
        padding:3rem!important
    }
    .px-md-0{
        padding-right:0!important;
        padding-left:0!important
    }
    .px-md-1{
        padding-right:.25rem!important;
        padding-left:.25rem!important
    }
    .px-md-2{
        padding-right:.5rem!important;
        padding-left:.5rem!important
    }
    .px-md-3{
        padding-right:1rem!important;
        padding-left:1rem!important
    }
    .px-md-4{
        padding-right:1.5rem!important;
        padding-left:1.5rem!important
    }
    .px-md-5{
        padding-right:3rem!important;
        padding-left:3rem!important
    }
    .py-md-0{
        padding-top:0!important;
        padding-bottom:0!important
    }
    .py-md-1{
        padding-top:.25rem!important;
        padding-bottom:.25rem!important
    }
    .py-md-2{
        padding-top:.5rem!important;
        padding-bottom:.5rem!important
    }
    .py-md-3{
        padding-top:1rem!important;
        padding-bottom:1rem!important
    }
    .py-md-4{
        padding-top:1.5rem!important;
        padding-bottom:1.5rem!important
    }
    .py-md-5{
        padding-top:3rem!important;
        padding-bottom:3rem!important
    }
    .pt-md-0{
        padding-top:0!important
    }
    .pt-md-1{
        padding-top:.25rem!important
    }
    .pt-md-2{
        padding-top:.5rem!important
    }
    .pt-md-3{
        padding-top:1rem!important
    }
    .pt-md-4{
        padding-top:1.5rem!important
    }
    .pt-md-5{
        padding-top:3rem!important
    }
    .pe-md-0{
        padding-right:0!important
    }
    .pe-md-1{
        padding-right:.25rem!important
    }
    .pe-md-2{
        padding-right:.5rem!important
    }
    .pe-md-3{
        padding-right:1rem!important
    }
    .pe-md-4{
        padding-right:1.5rem!important
    }
    .pe-md-5{
        padding-right:3rem!important
    }
    .pb-md-0{
        padding-bottom:0!important
    }
    .pb-md-1{
        padding-bottom:.25rem!important
    }
    .pb-md-2{
        padding-bottom:.5rem!important
    }
    .pb-md-3{
        padding-bottom:1rem!important
    }
    .pb-md-4{
        padding-bottom:1.5rem!important
    }
    .pb-md-5{
        padding-bottom:3rem!important
    }
    .ps-md-0{
        padding-left:0!important
    }
    .ps-md-1{
        padding-left:.25rem!important
    }
    .ps-md-2{
        padding-left:.5rem!important
    }
    .ps-md-3{
        padding-left:1rem!important
    }
    .ps-md-4{
        padding-left:1.5rem!important
    }
    .ps-md-5{
        padding-left:3rem!important
    }
}
@media (min-width:992px){
    .d-lg-inline{
        display:inline!important
    }
    .d-lg-inline-block{
        display:inline-block!important
    }
    .d-lg-block{
        display:block!important
    }
    .d-lg-grid{
        display:grid!important
    }
    .d-lg-table{
        display:table!important
    }
    .d-lg-table-row{
        display:table-row!important
    }
    .d-lg-table-cell{
        display:table-cell!important
    }
    .d-lg-flex{
        display:flex!important
    }
    .d-lg-inline-flex{
        display:inline-flex!important
    }
    .d-lg-none{
        display:none!important
    }
    .flex-lg-fill{
        flex:1 1 auto!important
    }
    .flex-lg-row{
        flex-direction:row!important
    }
    .flex-lg-column{
        flex-direction:column!important
    }
    .flex-lg-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-lg-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-lg-grow-0{
        flex-grow:0!important
    }
    .flex-lg-grow-1{
        flex-grow:1!important
    }
    .flex-lg-shrink-0{
        flex-shrink:0!important
    }
    .flex-lg-shrink-1{
        flex-shrink:1!important
    }
    .flex-lg-wrap{
        flex-wrap:wrap!important
    }
    .flex-lg-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-lg-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .justify-content-lg-start{
        justify-content:flex-start!important
    }
    .justify-content-lg-end{
        justify-content:flex-end!important
    }
    .justify-content-lg-center{
        justify-content:center!important
    }
    .justify-content-lg-between{
        justify-content:space-between!important
    }
    .justify-content-lg-around{
        justify-content:space-around!important
    }
    .justify-content-lg-evenly{
        justify-content:space-evenly!important
    }
    .align-items-lg-start{
        align-items:flex-start!important
    }
    .align-items-lg-end{
        align-items:flex-end!important
    }
    .align-items-lg-center{
        align-items:center!important
    }
    .align-items-lg-baseline{
        align-items:baseline!important
    }
    .align-items-lg-stretch{
        align-items:stretch!important
    }
    .align-content-lg-start{
        align-content:flex-start!important
    }
    .align-content-lg-end{
        align-content:flex-end!important
    }
    .align-content-lg-center{
        align-content:center!important
    }
    .align-content-lg-between{
        align-content:space-between!important
    }
    .align-content-lg-around{
        align-content:space-around!important
    }
    .align-content-lg-stretch{
        align-content:stretch!important
    }
    .align-self-lg-auto{
        align-self:auto!important
    }
    .align-self-lg-start{
        align-self:flex-start!important
    }
    .align-self-lg-end{
        align-self:flex-end!important
    }
    .align-self-lg-center{
        align-self:center!important
    }
    .align-self-lg-baseline{
        align-self:baseline!important
    }
    .align-self-lg-stretch{
        align-self:stretch!important
    }
    .order-lg-first{
        order:-1!important
    }
    .order-lg-0{
        order:0!important
    }
    .order-lg-1{
        order:1!important
    }
    .order-lg-2{
        order:2!important
    }
    .order-lg-3{
        order:3!important
    }
    .order-lg-4{
        order:4!important
    }
    .order-lg-5{
        order:5!important
    }
    .order-lg-last{
        order:6!important
    }
    .m-lg-0{
        margin:0!important
    }
    .m-lg-1{
        margin:.25rem!important
    }
    .m-lg-2{
        margin:.5rem!important
    }
    .m-lg-3{
        margin:1rem!important
    }
    .m-lg-4{
        margin:1.5rem!important
    }
    .m-lg-5{
        margin:3rem!important
    }
    .m-lg-auto{
        margin:auto!important
    }
    .mx-lg-0{
        margin-right:0!important;
        margin-left:0!important
    }
    .mx-lg-1{
        margin-right:.25rem!important;
        margin-left:.25rem!important
    }
    .mx-lg-2{
        margin-right:.5rem!important;
        margin-left:.5rem!important
    }
    .mx-lg-3{
        margin-right:1rem!important;
        margin-left:1rem!important
    }
    .mx-lg-4{
        margin-right:1.5rem!important;
        margin-left:1.5rem!important
    }
    .mx-lg-5{
        margin-right:3rem!important;
        margin-left:3rem!important
    }
    .mx-lg-auto{
        margin-right:auto!important;
        margin-left:auto!important
    }
    .my-lg-0{
        margin-top:0!important;
        margin-bottom:0!important
    }
    .my-lg-1{
        margin-top:.25rem!important;
        margin-bottom:.25rem!important
    }
    .my-lg-2{
        margin-top:.5rem!important;
        margin-bottom:.5rem!important
    }
    .my-lg-3{
        margin-top:1rem!important;
        margin-bottom:1rem!important
    }
    .my-lg-4{
        margin-top:1.5rem!important;
        margin-bottom:1.5rem!important
    }
    .my-lg-5{
        margin-top:3rem!important;
        margin-bottom:3rem!important
    }
    .my-lg-auto{
        margin-top:auto!important;
        margin-bottom:auto!important
    }
    .mt-lg-0{
        margin-top:0!important
    }
    .mt-lg-1{
        margin-top:.25rem!important
    }
    .mt-lg-2{
        margin-top:.5rem!important
    }
    .mt-lg-3{
        margin-top:1rem!important
    }
    .mt-lg-4{
        margin-top:1.5rem!important
    }
    .mt-lg-5{
        margin-top:3rem!important
    }
    .mt-lg-auto{
        margin-top:auto!important
    }
    .me-lg-0{
        margin-right:0!important
    }
    .me-lg-1{
        margin-right:.25rem!important
    }
    .me-lg-2{
        margin-right:.5rem!important
    }
    .me-lg-3{
        margin-right:1rem!important
    }
    .me-lg-4{
        margin-right:1.5rem!important
    }
    .me-lg-5{
        margin-right:3rem!important
    }
    .me-lg-auto{
        margin-right:auto!important
    }
    .mb-lg-0{
        margin-bottom:0!important
    }
    .mb-lg-1{
        margin-bottom:.25rem!important
    }
    .mb-lg-2{
        margin-bottom:.5rem!important
    }
    .mb-lg-3{
        margin-bottom:1rem!important
    }
    .mb-lg-4{
        margin-bottom:1.5rem!important
    }
    .mb-lg-5{
        margin-bottom:3rem!important
    }
    .mb-lg-auto{
        margin-bottom:auto!important
    }
    .ms-lg-0{
        margin-left:0!important
    }
    .ms-lg-1{
        margin-left:.25rem!important
    }
    .ms-lg-2{
        margin-left:.5rem!important
    }
    .ms-lg-3{
        margin-left:1rem!important
    }
    .ms-lg-4{
        margin-left:1.5rem!important
    }
    .ms-lg-5{
        margin-left:3rem!important
    }
    .ms-lg-auto{
        margin-left:auto!important
    }
    .p-lg-0{
        padding:0!important
    }
    .p-lg-1{
        padding:.25rem!important
    }
    .p-lg-2{
        padding:.5rem!important
    }
    .p-lg-3{
        padding:1rem!important
    }
    .p-lg-4{
        padding:1.5rem!important
    }
    .p-lg-5{
        padding:3rem!important
    }
    .px-lg-0{
        padding-right:0!important;
        padding-left:0!important
    }
    .px-lg-1{
        padding-right:.25rem!important;
        padding-left:.25rem!important
    }
    .px-lg-2{
        padding-right:.5rem!important;
        padding-left:.5rem!important
    }
    .px-lg-3{
        padding-right:1rem!important;
        padding-left:1rem!important
    }
    .px-lg-4{
        padding-right:1.5rem!important;
        padding-left:1.5rem!important
    }
    .px-lg-5{
        padding-right:3rem!important;
        padding-left:3rem!important
    }
    .py-lg-0{
        padding-top:0!important;
        padding-bottom:0!important
    }
    .py-lg-1{
        padding-top:.25rem!important;
        padding-bottom:.25rem!important
    }
    .py-lg-2{
        padding-top:.5rem!important;
        padding-bottom:.5rem!important
    }
    .py-lg-3{
        padding-top:1rem!important;
        padding-bottom:1rem!important
    }
    .py-lg-4{
        padding-top:1.5rem!important;
        padding-bottom:1.5rem!important
    }
    .py-lg-5{
        padding-top:3rem!important;
        padding-bottom:3rem!important
    }
    .pt-lg-0{
        padding-top:0!important
    }
    .pt-lg-1{
        padding-top:.25rem!important
    }
    .pt-lg-2{
        padding-top:.5rem!important
    }
    .pt-lg-3{
        padding-top:1rem!important
    }
    .pt-lg-4{
        padding-top:1.5rem!important
    }
    .pt-lg-5{
        padding-top:3rem!important
    }
    .pe-lg-0{
        padding-right:0!important
    }
    .pe-lg-1{
        padding-right:.25rem!important
    }
    .pe-lg-2{
        padding-right:.5rem!important
    }
    .pe-lg-3{
        padding-right:1rem!important
    }
    .pe-lg-4{
        padding-right:1.5rem!important
    }
    .pe-lg-5{
        padding-right:3rem!important
    }
    .pb-lg-0{
        padding-bottom:0!important
    }
    .pb-lg-1{
        padding-bottom:.25rem!important
    }
    .pb-lg-2{
        padding-bottom:.5rem!important
    }
    .pb-lg-3{
        padding-bottom:1rem!important
    }
    .pb-lg-4{
        padding-bottom:1.5rem!important
    }
    .pb-lg-5{
        padding-bottom:3rem!important
    }
    .ps-lg-0{
        padding-left:0!important
    }
    .ps-lg-1{
        padding-left:.25rem!important
    }
    .ps-lg-2{
        padding-left:.5rem!important
    }
    .ps-lg-3{
        padding-left:1rem!important
    }
    .ps-lg-4{
        padding-left:1.5rem!important
    }
    .ps-lg-5{
        padding-left:3rem!important
    }
}
@media (min-width:1200px){
    .d-xl-inline{
        display:inline!important
    }
    .d-xl-inline-block{
        display:inline-block!important
    }
    .d-xl-block{
        display:block!important
    }
    .d-xl-grid{
        display:grid!important
    }
    .d-xl-table{
        display:table!important
    }
    .d-xl-table-row{
        display:table-row!important
    }
    .d-xl-table-cell{
        display:table-cell!important
    }
    .d-xl-flex{
        display:flex!important
    }
    .d-xl-inline-flex{
        display:inline-flex!important
    }
    .d-xl-none{
        display:none!important
    }
    .flex-xl-fill{
        flex:1 1 auto!important
    }
    .flex-xl-row{
        flex-direction:row!important
    }
    .flex-xl-column{
        flex-direction:column!important
    }
    .flex-xl-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-xl-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-xl-grow-0{
        flex-grow:0!important
    }
    .flex-xl-grow-1{
        flex-grow:1!important
    }
    .flex-xl-shrink-0{
        flex-shrink:0!important
    }
    .flex-xl-shrink-1{
        flex-shrink:1!important
    }
    .flex-xl-wrap{
        flex-wrap:wrap!important
    }
    .flex-xl-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-xl-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .justify-content-xl-start{
        justify-content:flex-start!important
    }
    .justify-content-xl-end{
        justify-content:flex-end!important
    }
    .justify-content-xl-center{
        justify-content:center!important
    }
    .justify-content-xl-between{
        justify-content:space-between!important
    }
    .justify-content-xl-around{
        justify-content:space-around!important
    }
    .justify-content-xl-evenly{
        justify-content:space-evenly!important
    }
    .align-items-xl-start{
        align-items:flex-start!important
    }
    .align-items-xl-end{
        align-items:flex-end!important
    }
    .align-items-xl-center{
        align-items:center!important
    }
    .align-items-xl-baseline{
        align-items:baseline!important
    }
    .align-items-xl-stretch{
        align-items:stretch!important
    }
    .align-content-xl-start{
        align-content:flex-start!important
    }
    .align-content-xl-end{
        align-content:flex-end!important
    }
    .align-content-xl-center{
        align-content:center!important
    }
    .align-content-xl-between{
        align-content:space-between!important
    }
    .align-content-xl-around{
        align-content:space-around!important
    }
    .align-content-xl-stretch{
        align-content:stretch!important
    }
    .align-self-xl-auto{
        align-self:auto!important
    }
    .align-self-xl-start{
        align-self:flex-start!important
    }
    .align-self-xl-end{
        align-self:flex-end!important
    }
    .align-self-xl-center{
        align-self:center!important
    }
    .align-self-xl-baseline{
        align-self:baseline!important
    }
    .align-self-xl-stretch{
        align-self:stretch!important
    }
    .order-xl-first{
        order:-1!important
    }
    .order-xl-0{
        order:0!important
    }
    .order-xl-1{
        order:1!important
    }
    .order-xl-2{
        order:2!important
    }
    .order-xl-3{
        order:3!important
    }
    .order-xl-4{
        order:4!important
    }
    .order-xl-5{
        order:5!important
    }
    .order-xl-last{
        order:6!important
    }
    .m-xl-0{
        margin:0!important
    }
    .m-xl-1{
        margin:.25rem!important
    }
    .m-xl-2{
        margin:.5rem!important
    }
    .m-xl-3{
        margin:1rem!important
    }
    .m-xl-4{
        margin:1.5rem!important
    }
    .m-xl-5{
        margin:3rem!important
    }
    .m-xl-auto{
        margin:auto!important
    }
    .mx-xl-0{
        margin-right:0!important;
        margin-left:0!important
    }
    .mx-xl-1{
        margin-right:.25rem!important;
        margin-left:.25rem!important
    }
    .mx-xl-2{
        margin-right:.5rem!important;
        margin-left:.5rem!important
    }
    .mx-xl-3{
        margin-right:1rem!important;
        margin-left:1rem!important
    }
    .mx-xl-4{
        margin-right:1.5rem!important;
        margin-left:1.5rem!important
    }
    .mx-xl-5{
        margin-right:3rem!important;
        margin-left:3rem!important
    }
    .mx-xl-auto{
        margin-right:auto!important;
        margin-left:auto!important
    }
    .my-xl-0{
        margin-top:0!important;
        margin-bottom:0!important
    }
    .my-xl-1{
        margin-top:.25rem!important;
        margin-bottom:.25rem!important
    }
    .my-xl-2{
        margin-top:.5rem!important;
        margin-bottom:.5rem!important
    }
    .my-xl-3{
        margin-top:1rem!important;
        margin-bottom:1rem!important
    }
    .my-xl-4{
        margin-top:1.5rem!important;
        margin-bottom:1.5rem!important
    }
    .my-xl-5{
        margin-top:3rem!important;
        margin-bottom:3rem!important
    }
    .my-xl-auto{
        margin-top:auto!important;
        margin-bottom:auto!important
    }
    .mt-xl-0{
        margin-top:0!important
    }
    .mt-xl-1{
        margin-top:.25rem!important
    }
    .mt-xl-2{
        margin-top:.5rem!important
    }
    .mt-xl-3{
        margin-top:1rem!important
    }
    .mt-xl-4{
        margin-top:1.5rem!important
    }
    .mt-xl-5{
        margin-top:3rem!important
    }
    .mt-xl-auto{
        margin-top:auto!important
    }
    .me-xl-0{
        margin-right:0!important
    }
    .me-xl-1{
        margin-right:.25rem!important
    }
    .me-xl-2{
        margin-right:.5rem!important
    }
    .me-xl-3{
        margin-right:1rem!important
    }
    .me-xl-4{
        margin-right:1.5rem!important
    }
    .me-xl-5{
        margin-right:3rem!important
    }
    .me-xl-auto{
        margin-right:auto!important
    }
    .mb-xl-0{
        margin-bottom:0!important
    }
    .mb-xl-1{
        margin-bottom:.25rem!important
    }
    .mb-xl-2{
        margin-bottom:.5rem!important
    }
    .mb-xl-3{
        margin-bottom:1rem!important
    }
    .mb-xl-4{
        margin-bottom:1.5rem!important
    }
    .mb-xl-5{
        margin-bottom:3rem!important
    }
    .mb-xl-auto{
        margin-bottom:auto!important
    }
    .ms-xl-0{
        margin-left:0!important
    }
    .ms-xl-1{
        margin-left:.25rem!important
    }
    .ms-xl-2{
        margin-left:.5rem!important
    }
    .ms-xl-3{
        margin-left:1rem!important
    }
    .ms-xl-4{
        margin-left:1.5rem!important
    }
    .ms-xl-5{
        margin-left:3rem!important
    }
    .ms-xl-auto{
        margin-left:auto!important
    }
    .p-xl-0{
        padding:0!important
    }
    .p-xl-1{
        padding:.25rem!important
    }
    .p-xl-2{
        padding:.5rem!important
    }
    .p-xl-3{
        padding:1rem!important
    }
    .p-xl-4{
        padding:1.5rem!important
    }
    .p-xl-5{
        padding:3rem!important
    }
    .px-xl-0{
        padding-right:0!important;
        padding-left:0!important
    }
    .px-xl-1{
        padding-right:.25rem!important;
        padding-left:.25rem!important
    }
    .px-xl-2{
        padding-right:.5rem!important;
        padding-left:.5rem!important
    }
    .px-xl-3{
        padding-right:1rem!important;
        padding-left:1rem!important
    }
    .px-xl-4{
        padding-right:1.5rem!important;
        padding-left:1.5rem!important
    }
    .px-xl-5{
        padding-right:3rem!important;
        padding-left:3rem!important
    }
    .py-xl-0{
        padding-top:0!important;
        padding-bottom:0!important
    }
    .py-xl-1{
        padding-top:.25rem!important;
        padding-bottom:.25rem!important
    }
    .py-xl-2{
        padding-top:.5rem!important;
        padding-bottom:.5rem!important
    }
    .py-xl-3{
        padding-top:1rem!important;
        padding-bottom:1rem!important
    }
    .py-xl-4{
        padding-top:1.5rem!important;
        padding-bottom:1.5rem!important
    }
    .py-xl-5{
        padding-top:3rem!important;
        padding-bottom:3rem!important
    }
    .pt-xl-0{
        padding-top:0!important
    }
    .pt-xl-1{
        padding-top:.25rem!important
    }
    .pt-xl-2{
        padding-top:.5rem!important
    }
    .pt-xl-3{
        padding-top:1rem!important
    }
    .pt-xl-4{
        padding-top:1.5rem!important
    }
    .pt-xl-5{
        padding-top:3rem!important
    }
    .pe-xl-0{
        padding-right:0!important
    }
    .pe-xl-1{
        padding-right:.25rem!important
    }
    .pe-xl-2{
        padding-right:.5rem!important
    }
    .pe-xl-3{
        padding-right:1rem!important
    }
    .pe-xl-4{
        padding-right:1.5rem!important
    }
    .pe-xl-5{
        padding-right:3rem!important
    }
    .pb-xl-0{
        padding-bottom:0!important
    }
    .pb-xl-1{
        padding-bottom:.25rem!important
    }
    .pb-xl-2{
        padding-bottom:.5rem!important
    }
    .pb-xl-3{
        padding-bottom:1rem!important
    }
    .pb-xl-4{
        padding-bottom:1.5rem!important
    }
    .pb-xl-5{
        padding-bottom:3rem!important
    }
    .ps-xl-0{
        padding-left:0!important
    }
    .ps-xl-1{
        padding-left:.25rem!important
    }
    .ps-xl-2{
        padding-left:.5rem!important
    }
    .ps-xl-3{
        padding-left:1rem!important
    }
    .ps-xl-4{
        padding-left:1.5rem!important
    }
    .ps-xl-5{
        padding-left:3rem!important
    }
}
@media (min-width:1400px){
    .d-xxl-inline{
        display:inline!important
    }
    .d-xxl-inline-block{
        display:inline-block!important
    }
    .d-xxl-block{
        display:block!important
    }
    .d-xxl-grid{
        display:grid!important
    }
    .d-xxl-table{
        display:table!important
    }
    .d-xxl-table-row{
        display:table-row!important
    }
    .d-xxl-table-cell{
        display:table-cell!important
    }
    .d-xxl-flex{
        display:flex!important
    }
    .d-xxl-inline-flex{
        display:inline-flex!important
    }
    .d-xxl-none{
        display:none!important
    }
    .flex-xxl-fill{
        flex:1 1 auto!important
    }
    .flex-xxl-row{
        flex-direction:row!important
    }
    .flex-xxl-column{
        flex-direction:column!important
    }
    .flex-xxl-row-reverse{
        flex-direction:row-reverse!important
    }
    .flex-xxl-column-reverse{
        flex-direction:column-reverse!important
    }
    .flex-xxl-grow-0{
        flex-grow:0!important
    }
    .flex-xxl-grow-1{
        flex-grow:1!important
    }
    .flex-xxl-shrink-0{
        flex-shrink:0!important
    }
    .flex-xxl-shrink-1{
        flex-shrink:1!important
    }
    .flex-xxl-wrap{
        flex-wrap:wrap!important
    }
    .flex-xxl-nowrap{
        flex-wrap:nowrap!important
    }
    .flex-xxl-wrap-reverse{
        flex-wrap:wrap-reverse!important
    }
    .justify-content-xxl-start{
        justify-content:flex-start!important
    }
    .justify-content-xxl-end{
        justify-content:flex-end!important
    }
    .justify-content-xxl-center{
        justify-content:center!important
    }
    .justify-content-xxl-between{
        justify-content:space-between!important
    }
    .justify-content-xxl-around{
        justify-content:space-around!important
    }
    .justify-content-xxl-evenly{
        justify-content:space-evenly!important
    }
    .align-items-xxl-start{
        align-items:flex-start!important
    }
    .align-items-xxl-end{
        align-items:flex-end!important
    }
    .align-items-xxl-center{
        align-items:center!important
    }
    .align-items-xxl-baseline{
        align-items:baseline!important
    }
    .align-items-xxl-stretch{
        align-items:stretch!important
    }
    .align-content-xxl-start{
        align-content:flex-start!important
    }
    .align-content-xxl-end{
        align-content:flex-end!important
    }
    .align-content-xxl-center{
        align-content:center!important
    }
    .align-content-xxl-between{
        align-content:space-between!important
    }
    .align-content-xxl-around{
        align-content:space-around!important
    }
    .align-content-xxl-stretch{
        align-content:stretch!important
    }
    .align-self-xxl-auto{
        align-self:auto!important
    }
    .align-self-xxl-start{
        align-self:flex-start!important
    }
    .align-self-xxl-end{
        align-self:flex-end!important
    }
    .align-self-xxl-center{
        align-self:center!important
    }
    .align-self-xxl-baseline{
        align-self:baseline!important
    }
    .align-self-xxl-stretch{
        align-self:stretch!important
    }
    .order-xxl-first{
        order:-1!important
    }
    .order-xxl-0{
        order:0!important
    }
    .order-xxl-1{
        order:1!important
    }
    .order-xxl-2{
        order:2!important
    }
    .order-xxl-3{
        order:3!important
    }
    .order-xxl-4{
        order:4!important
    }
    .order-xxl-5{
        order:5!important
    }
    .order-xxl-last{
        order:6!important
    }
    .m-xxl-0{
        margin:0!important
    }
    .m-xxl-1{
        margin:.25rem!important
    }
    .m-xxl-2{
        margin:.5rem!important
    }
    .m-xxl-3{
        margin:1rem!important
    }
    .m-xxl-4{
        margin:1.5rem!important
    }
    .m-xxl-5{
        margin:3rem!important
    }
    .m-xxl-auto{
        margin:auto!important
    }
    .mx-xxl-0{
        margin-right:0!important;
        margin-left:0!important
    }
    .mx-xxl-1{
        margin-right:.25rem!important;
        margin-left:.25rem!important
    }
    .mx-xxl-2{
        margin-right:.5rem!important;
        margin-left:.5rem!important
    }
    .mx-xxl-3{
        margin-right:1rem!important;
        margin-left:1rem!important
    }
    .mx-xxl-4{
        margin-right:1.5rem!important;
        margin-left:1.5rem!important
    }
    .mx-xxl-5{
        margin-right:3rem!important;
        margin-left:3rem!important
    }
    .mx-xxl-auto{
        margin-right:auto!important;
        margin-left:auto!important
    }
    .my-xxl-0{
        margin-top:0!important;
        margin-bottom:0!important
    }
    .my-xxl-1{
        margin-top:.25rem!important;
        margin-bottom:.25rem!important
    }
    .my-xxl-2{
        margin-top:.5rem!important;
        margin-bottom:.5rem!important
    }
    .my-xxl-3{
        margin-top:1rem!important;
        margin-bottom:1rem!important
    }
    .my-xxl-4{
        margin-top:1.5rem!important;
        margin-bottom:1.5rem!important
    }
    .my-xxl-5{
        margin-top:3rem!important;
        margin-bottom:3rem!important
    }
    .my-xxl-auto{
        margin-top:auto!important;
        margin-bottom:auto!important
    }
    .mt-xxl-0{
        margin-top:0!important
    }
    .mt-xxl-1{
        margin-top:.25rem!important
    }
    .mt-xxl-2{
        margin-top:.5rem!important
    }
    .mt-xxl-3{
        margin-top:1rem!important
    }
    .mt-xxl-4{
        margin-top:1.5rem!important
    }
    .mt-xxl-5{
        margin-top:3rem!important
    }
    .mt-xxl-auto{
        margin-top:auto!important
    }
    .me-xxl-0{
        margin-right:0!important
    }
    .me-xxl-1{
        margin-right:.25rem!important
    }
    .me-xxl-2{
        margin-right:.5rem!important
    }
    .me-xxl-3{
        margin-right:1rem!important
    }
    .me-xxl-4{
        margin-right:1.5rem!important
    }
    .me-xxl-5{
        margin-right:3rem!important
    }
    .me-xxl-auto{
        margin-right:auto!important
    }
    .mb-xxl-0{
        margin-bottom:0!important
    }
    .mb-xxl-1{
        margin-bottom:.25rem!important
    }
    .mb-xxl-2{
        margin-bottom:.5rem!important
    }
    .mb-xxl-3{
        margin-bottom:1rem!important
    }
    .mb-xxl-4{
        margin-bottom:1.5rem!important
    }
    .mb-xxl-5{
        margin-bottom:3rem!important
    }
    .mb-xxl-auto{
        margin-bottom:auto!important
    }
    .ms-xxl-0{
        margin-left:0!important
    }
    .ms-xxl-1{
        margin-left:.25rem!important
    }
    .ms-xxl-2{
        margin-left:.5rem!important
    }
    .ms-xxl-3{
        margin-left:1rem!important
    }
    .ms-xxl-4{
        margin-left:1.5rem!important
    }
    .ms-xxl-5{
        margin-left:3rem!important
    }
    .ms-xxl-auto{
        margin-left:auto!important
    }
    .p-xxl-0{
        padding:0!important
    }
    .p-xxl-1{
        padding:.25rem!important
    }
    .p-xxl-2{
        padding:.5rem!important
    }
    .p-xxl-3{
        padding:1rem!important
    }
    .p-xxl-4{
        padding:1.5rem!important
    }
    .p-xxl-5{
        padding:3rem!important
    }
    .px-xxl-0{
        padding-right:0!important;
        padding-left:0!important
    }
    .px-xxl-1{
        padding-right:.25rem!important;
        padding-left:.25rem!important
    }
    .px-xxl-2{
        padding-right:.5rem!important;
        padding-left:.5rem!important
    }
    .px-xxl-3{
        padding-right:1rem!important;
        padding-left:1rem!important
    }
    .px-xxl-4{
        padding-right:1.5rem!important;
        padding-left:1.5rem!important
    }
    .px-xxl-5{
        padding-right:3rem!important;
        padding-left:3rem!important
    }
    .py-xxl-0{
        padding-top:0!important;
        padding-bottom:0!important
    }
    .py-xxl-1{
        padding-top:.25rem!important;
        padding-bottom:.25rem!important
    }
    .py-xxl-2{
        padding-top:.5rem!important;
        padding-bottom:.5rem!important
    }
    .py-xxl-3{
        padding-top:1rem!important;
        padding-bottom:1rem!important
    }
    .py-xxl-4{
        padding-top:1.5rem!important;
        padding-bottom:1.5rem!important
    }
    .py-xxl-5{
        padding-top:3rem!important;
        padding-bottom:3rem!important
    }
    .pt-xxl-0{
        padding-top:0!important
    }
    .pt-xxl-1{
        padding-top:.25rem!important
    }
    .pt-xxl-2{
        padding-top:.5rem!important
    }
    .pt-xxl-3{
        padding-top:1rem!important
    }
    .pt-xxl-4{
        padding-top:1.5rem!important
    }
    .pt-xxl-5{
        padding-top:3rem!important
    }
    .pe-xxl-0{
        padding-right:0!important
    }
    .pe-xxl-1{
        padding-right:.25rem!important
    }
    .pe-xxl-2{
        padding-right:.5rem!important
    }
    .pe-xxl-3{
        padding-right:1rem!important
    }
    .pe-xxl-4{
        padding-right:1.5rem!important
    }
    .pe-xxl-5{
        padding-right:3rem!important
    }
    .pb-xxl-0{
        padding-bottom:0!important
    }
    .pb-xxl-1{
        padding-bottom:.25rem!important
    }
    .pb-xxl-2{
        padding-bottom:.5rem!important
    }
    .pb-xxl-3{
        padding-bottom:1rem!important
    }
    .pb-xxl-4{
        padding-bottom:1.5rem!important
    }
    .pb-xxl-5{
        padding-bottom:3rem!important
    }
    .ps-xxl-0{
        padding-left:0!important
    }
    .ps-xxl-1{
        padding-left:.25rem!important
    }
    .ps-xxl-2{
        padding-left:.5rem!important
    }
    .ps-xxl-3{
        padding-left:1rem!important
    }
    .ps-xxl-4{
        padding-left:1.5rem!important
    }
    .ps-xxl-5{
        padding-left:3rem!important
    }
}
@media print{
    .d-print-inline{
        display:inline!important
    }
    .d-print-inline-block{
        display:inline-block!important
    }
    .d-print-block{
        display:block!important
    }
    .d-print-grid{
        display:grid!important
    }
    .d-print-table{
        display:table!important
    }
    .d-print-table-row{
        display:table-row!important
    }
    .d-print-table-cell{
        display:table-cell!important
    }
    .d-print-flex{
        display:flex!important
    }
    .d-print-inline-flex{
        display:inline-flex!important
    }
    .d-print-none{
        display:none!important
    }
}
.glightbox-container{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:999999!important;
    -ms-touch-action:none;
    touch-action:none;
    -webkit-text-size-adjust:100%;
    -moz-text-size-adjust:100%;
    -ms-text-size-adjust:100%;
    text-size-adjust:100%;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    outline:0;
    overflow:hidden
}
.glightbox-container.inactive{
    display:none
}
.glightbox-container .gcontainer{
    position:relative;
    width:100%;
    height:100%;
    z-index:9999;
    overflow:hidden
}
.glightbox-container .gslider{
    -webkit-transition:-webkit-transform .4s ease;
    transition:-webkit-transform .4s ease;
    transition:transform .4s ease;
    transition:transform .4s ease,-webkit-transform .4s ease;
    height:100%;
    left:0;
    top:0;
    position:relative;
    overflow:hidden;
    display:-webkit-box!important;
    display:-ms-flexbox!important;
    display:flex!important;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
}
.glightbox-container .gslide,.glightbox-container .gslider{
    width:100%;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.glightbox-container .gslide{
    position:absolute;
    opacity:1;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    opacity:0
}
.glightbox-container .gslide.current{
    opacity:1;
    z-index:99999;
    position:relative
}
.glightbox-container .gslide.prev{
    opacity:1;
    z-index:9999
}
.glightbox-container .gslide-inner-content{
    width:100%
}
.glightbox-container .ginner-container{
    position:relative;
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    max-width:100%;
    margin:auto;
    height:100vh
}
.glightbox-container .ginner-container.gvideo-container{
    width:100%
}
.glightbox-container .ginner-container.desc-bottom,.glightbox-container .ginner-container.desc-top{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
}
.glightbox-container .ginner-container.desc-left,.glightbox-container .ginner-container.desc-right{
    max-width:100%!important
}
.gslide iframe,.gslide video{
    outline:0!important;
    border:none;
    min-height:165px;
    -webkit-overflow-scrolling:touch;
    -ms-touch-action:auto;
    touch-action:auto
}
.gslide-image{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.gslide-image img{
    max-height:100vh;
    display:block;
    padding:0;
    float:none;
    outline:0;
    border:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    max-width:100vw;
    width:auto;
    height:auto;
    -o-object-fit:cover;
    object-fit:cover;
    -ms-touch-action:none;
    touch-action:none;
    margin:auto;
    min-width:200px
}
.desc-bottom .gslide-image img,.desc-top .gslide-image img{
    width:auto
}
.desc-left .gslide-image img,.desc-right .gslide-image img{
    width:auto;
    max-width:100%
}
.gslide-image img.zoomable{
    position:relative
}
.gslide-image img.dragging{
    cursor:-webkit-grabbing!important;
    cursor:grabbing!important;
    -webkit-transition:none;
    transition:none
}
.gslide-video{
    position:relative;
    max-width:100vh;
    width:100%!important
}
.gslide-video .gvideo-wrapper{
    width:100%;
    margin:auto
}
.gslide-video:before{
    content:"";
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    background:rgba(255,0,0,.34);
    display:none
}
.gslide-video.playing:before{
    display:none
}
.gslide-video.fullscreen{
    max-width:100%!important;
    min-width:100%;
    height:75vh
}
.gslide-video.fullscreen video{
    max-width:100%!important;
    width:100%!important
}
.gslide-inline{
    background:#fff;
    text-align:left;
    max-height:calc(100vh - 40px);
    overflow:auto;
    max-width:100%
}
.gslide-inline .ginlined-content{
    padding:20px;
    width:100%
}
.gslide-inline .dragging{
    cursor:-webkit-grabbing!important;
    cursor:grabbing!important;
    -webkit-transition:none;
    transition:none
}
.ginlined-content{
    overflow:auto;
    display:block!important;
    opacity:1
}
.gslide-external{
    width:100%;
    min-width:100%;
    background:#fff;
    padding:0;
    overflow:auto;
    max-height:75vh;
    height:100%
}
.gslide-external,.gslide-media{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.gslide-media{
    width:auto
}
.zoomed .gslide-media{
    -webkit-box-shadow:none!important;
    box-shadow:none!important
}
.desc-bottom .gslide-media,.desc-top .gslide-media{
    margin:0 auto;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
}
.gslide-description{
    position:relative;
    -webkit-box-flex:1;
    -ms-flex:1 0 100%;
    flex:1 0 100%
}
.gslide-description.description-left,.gslide-description.description-right{
    max-width:100%
}
.gslide-description.description-bottom,.gslide-description.description-top{
    margin:0 auto;
    width:100%
}
.gslide-description p{
    margin-bottom:12px
}
.gslide-description p:last-child{
    margin-bottom:0
}
.glightbox-button-hidden,.zoomed .gslide-description{
    display:none
}
.glightbox-mobile .glightbox-container .gslide-description{
    height:auto!important;
    width:100%;
    background:0 0;
    position:absolute;
    bottom:15px;
    max-width:100vw!important;
    -webkit-box-ordinal-group:3!important;
    -ms-flex-order:2!important;
    order:2!important;
    max-height:78vh;
    overflow:auto!important;
    background:-webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.75)));
    background:linear-gradient(180deg,transparent 0,rgba(0,0,0,.75));
    -webkit-transition:opacity .3s linear;
    transition:opacity .3s linear;
    padding:19px 11px 50px
}
.glightbox-mobile .glightbox-container .gslide-title{
    color:#fff;
    font-size:1em
}
.glightbox-mobile .glightbox-container .gslide-desc{
    color:#a1a1a1
}
.glightbox-mobile .glightbox-container .gslide-desc a{
    color:#fff;
    font-weight:700
}
.glightbox-mobile .glightbox-container .gslide-desc *{
    color:inherit
}
.glightbox-mobile .glightbox-container .gslide-desc string{
    color:#fff
}
.glightbox-mobile .glightbox-container .gslide-desc .desc-more{
    color:#fff;
    opacity:.4
}
.gdesc-open .gslide-media{
    -webkit-transition:opacity .5s ease;
    transition:opacity .5s ease;
    opacity:.4
}
.gdesc-open .gdesc-inner{
    padding-bottom:30px
}
.gdesc-closed .gslide-media{
    -webkit-transition:opacity .5s ease;
    transition:opacity .5s ease;
    opacity:1
}
.greset{
    -webkit-transition:all .3s ease;
    transition:all .3s ease
}
.gabsolute{
    position:absolute
}
.grelative{
    position:relative
}
.glightbox-desc{
    display:none!important
}
.glightbox-open{
    overflow:hidden
}
.gloader{
    height:25px;
    width:25px;
    -webkit-animation:lightboxLoader .8s linear infinite;
    animation:lightboxLoader .8s linear infinite;
    border:2px solid #fff;
    border-right-color:transparent;
    border-radius:50%;
    position:absolute;
    display:block;
    z-index:9999;
    left:0;
    right:0;
    margin:0 auto;
    top:47%
}
.goverlay{
    width:100%;
    height:calc(100vh + 1px);
    position:fixed;
    top:-1px;
    left:0;
    will-change:opacity
}
.glightbox-mobile .goverlay,.goverlay{
    background:#000
}
.gclose,.gnext,.gprev{
    z-index:99999;
    cursor:pointer;
    width:26px;
    height:44px;
    border:none;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
}
.gclose svg,.gnext svg,.gprev svg{
    display:block;
    width:25px;
    height:auto;
    margin:0;
    padding:0
}
.gclose.disabled,.gnext.disabled,.gprev.disabled{
    opacity:.1
}
.gclose .garrow,.gnext .garrow,.gprev .garrow{
    stroke:#fff
}
iframe.wait-autoplay{
    opacity:0
}
.glightbox-closing .gclose,.glightbox-closing .gnext,.glightbox-closing .gprev{
    opacity:0!important
}
.glightbox-clean .gslide-description{
    background:#fff
}
.glightbox-clean .gdesc-inner{
    padding:22px 20px
}
.glightbox-clean .gslide-title{
    font-size:1em;
    font-weight:400;
    font-family:arial;
    color:#000;
    margin-bottom:19px;
    line-height:1.4em
}
.glightbox-clean .gslide-desc{
    font-size:.86em;
    margin-bottom:0;
    font-family:arial;
    line-height:1.4em
}
.glightbox-clean .gslide-video{
    background:#000
}
.glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev{
    background-color:rgba(0,0,0,.75);
    border-radius:4px
}
.glightbox-clean .gclose path,.glightbox-clean .gnext path,.glightbox-clean .gprev path{
    fill:#fff
}
.glightbox-clean button:focus:not(.focused):not(.disabled){
    outline:0
}
.glightbox-clean .gprev{
    left:30px
}
.glightbox-clean .gnext,.glightbox-clean .gprev{
    position:absolute;
    top:-100%;
    width:40px;
    height:50px
}
.glightbox-clean .gnext{
    right:30px
}
.glightbox-clean .gclose{
    width:35px;
    height:35px;
    top:15px;
    right:10px;
    position:absolute
}
.glightbox-clean .gclose svg{
    width:18px;
    height:auto
}
.glightbox-clean .gclose:hover{
    opacity:1
}
.gfadeIn{
    -webkit-animation:gfadeIn .5s ease;
    animation:gfadeIn .5s ease
}
.gfadeOut{
    -webkit-animation:gfadeOut .5s ease;
    animation:gfadeOut .5s ease
}
.gslideOutLeft{
    -webkit-animation:gslideOutLeft .3s ease;
    animation:gslideOutLeft .3s ease
}
.gslideInLeft{
    -webkit-animation:gslideInLeft .3s ease;
    animation:gslideInLeft .3s ease
}
.gslideOutRight{
    -webkit-animation:gslideOutRight .3s ease;
    animation:gslideOutRight .3s ease
}
.gslideInRight{
    -webkit-animation:gslideInRight .3s ease;
    animation:gslideInRight .3s ease
}
.gzoomIn{
    -webkit-animation:gzoomIn .5s ease;
    animation:gzoomIn .5s ease
}
.gzoomOut{
    -webkit-animation:gzoomOut .5s ease;
    animation:gzoomOut .5s ease
}
@-webkit-keyframes lightboxLoader{
    0%{
        -webkit-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(1turn);
        transform:rotate(1turn)
    }
}
@keyframes lightboxLoader{
    0%{
        -webkit-transform:rotate(0);
        transform:rotate(0)
    }
    to{
        -webkit-transform:rotate(1turn);
        transform:rotate(1turn)
    }
}
@-webkit-keyframes gfadeIn{
    0%{
        opacity:0
    }
    to{
        opacity:1
    }
}
@keyframes gfadeIn{
    0%{
        opacity:0
    }
    to{
        opacity:1
    }
}
@-webkit-keyframes gfadeOut{
    0%{
        opacity:1
    }
    to{
        opacity:0
    }
}
@keyframes gfadeOut{
    0%{
        opacity:1
    }
    to{
        opacity:0
    }
}
@-webkit-keyframes gslideInLeft{
    0%{
        opacity:0;
        -webkit-transform:translate3d(-60%,0,0);
        transform:translate3d(-60%,0,0)
    }
    to{
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
        opacity:1
    }
}
@keyframes gslideInLeft{
    0%{
        opacity:0;
        -webkit-transform:translate3d(-60%,0,0);
        transform:translate3d(-60%,0,0)
    }
    to{
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
        opacity:1
    }
}
@-webkit-keyframes gslideOutLeft{
    0%{
        opacity:1;
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0)
    }
    to{
        -webkit-transform:translate3d(-60%,0,0);
        transform:translate3d(-60%,0,0);
        opacity:0;
        visibility:hidden
    }
}
@keyframes gslideOutLeft{
    0%{
        opacity:1;
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0)
    }
    to{
        -webkit-transform:translate3d(-60%,0,0);
        transform:translate3d(-60%,0,0);
        opacity:0;
        visibility:hidden
    }
}
@-webkit-keyframes gslideInRight{
    0%{
        opacity:0;
        visibility:visible;
        -webkit-transform:translate3d(60%,0,0);
        transform:translate3d(60%,0,0)
    }
    to{
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
        opacity:1
    }
}
@keyframes gslideInRight{
    0%{
        opacity:0;
        visibility:visible;
        -webkit-transform:translate3d(60%,0,0);
        transform:translate3d(60%,0,0)
    }
    to{
        -webkit-transform:translateZ(0);
        transform:translateZ(0);
        opacity:1
    }
}
@-webkit-keyframes gslideOutRight{
    0%{
        opacity:1;
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0)
    }
    to{
        -webkit-transform:translate3d(60%,0,0);
        transform:translate3d(60%,0,0);
        opacity:0
    }
}
@keyframes gslideOutRight{
    0%{
        opacity:1;
        visibility:visible;
        -webkit-transform:translateZ(0);
        transform:translateZ(0)
    }
    to{
        -webkit-transform:translate3d(60%,0,0);
        transform:translate3d(60%,0,0);
        opacity:0
    }
}
@-webkit-keyframes gzoomIn{
    0%{
        opacity:0;
        -webkit-transform:scale3d(.3,.3,.3);
        transform:scale3d(.3,.3,.3)
    }
    to{
        opacity:1
    }
}
@keyframes gzoomIn{
    0%{
        opacity:0;
        -webkit-transform:scale3d(.3,.3,.3);
        transform:scale3d(.3,.3,.3)
    }
    to{
        opacity:1
    }
}
@-webkit-keyframes gzoomOut{
    0%{
        opacity:1
    }
    50%{
        opacity:0;
        -webkit-transform:scale3d(.3,.3,.3);
        transform:scale3d(.3,.3,.3)
    }
    to{
        opacity:0
    }
}
@keyframes gzoomOut{
    0%{
        opacity:1
    }
    50%{
        opacity:0;
        -webkit-transform:scale3d(.3,.3,.3);
        transform:scale3d(.3,.3,.3)
    }
    to{
        opacity:0
    }
}
@media (min-width:769px){
    .glightbox-container .ginner-container{
        width:auto;
        height:auto;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
        -ms-flex-direction:row;
        flex-direction:row
    }
    .glightbox-container .ginner-container.desc-top .gslide-description{
        -webkit-box-ordinal-group:1;
        -ms-flex-order:0;
        order:0
    }
    .glightbox-container .ginner-container.desc-top .gslide-image,.glightbox-container .ginner-container.desc-top .gslide-image img{
        -webkit-box-ordinal-group:2;
        -ms-flex-order:1;
        order:1
    }
    .glightbox-container .ginner-container.desc-left .gslide-description{
        -webkit-box-ordinal-group:1;
        -ms-flex-order:0;
        order:0
    }
    .glightbox-container .ginner-container.desc-left .gslide-image{
        -webkit-box-ordinal-group:2;
        -ms-flex-order:1;
        order:1
    }
    .gslide-image img{
        max-height:97vh;
        max-width:100%
    }
    .gslide-image img.zoomable{
        cursor:-webkit-zoom-in;
        cursor:zoom-in
    }
    .zoomed .gslide-image img.zoomable{
        cursor:-webkit-grab;
        cursor:grab
    }
    .gslide-inline{
        max-height:95vh
    }
    .gslide-external{
        max-height:100vh
    }
    .gslide-description.description-left,.gslide-description.description-right{
        max-width:275px
    }
    .glightbox-open{
        height:auto
    }
    .goverlay{
        background:rgba(0,0,0,.92)
    }
    .glightbox-clean .gslide-media{
        -webkit-box-shadow:1px 2px 9px 0 rgba(0,0,0,.65);
        box-shadow:1px 2px 9px 0 rgba(0,0,0,.65)
    }
    .glightbox-clean .description-left .gdesc-inner,.glightbox-clean .description-right .gdesc-inner{
        position:absolute;
        height:100%;
        overflow-y:auto
    }
    .glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev{
        background-color:rgba(0,0,0,.32)
    }
    .glightbox-clean .gclose:hover,.glightbox-clean .gnext:hover,.glightbox-clean .gprev:hover{
        background-color:rgba(0,0,0,.7)
    }
    .glightbox-clean .gnext,.glightbox-clean .gprev{
        top:45%
    }
}
@media (min-width:992px){
    .glightbox-clean .gclose{
        opacity:.7;
        right:20px
    }
}
@media screen and (max-height:420px){
    .goverlay{
        background:#000
    }
}
@font-face{
    font-family:icomoon;
    src:url();
    src:url() format("embedded-opentype"),url() format("truetype"),url() format("woff"),url() format("svg");
    font-weight:400;
    font-style:normal;
    font-display:block
}
[class*=" icon-"],[class^=icon-]{
    font-family:icomoon!important;
    /* speak:never; */
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.icon-menu-2:before{
    content:"\e95e"
}
.icon-play:before{
    content:"\e95d"
}
.icon-trash:before{
    content:"\e95b"
}
.icon-day-night:before{
    content:"\e95c"
}
.icon-share:before{
    content:"\e951"
}
.icon-clock:before{
    content:"\e952"
}
.icon-customer:before{
    content:"\e953"
}
.icon-route:before{
    content:"\e954"
}
.icon-access-denied:before{
    content:"\e955"
}
.icon-compass:before{
    content:"\e956"
}
.icon-calendar-2:before{
    content:"\e957"
}
.icon-placeholder:before{
    content:"\e958"
}
.icon-group:before{
    content:"\e959"
}
.icon-calendar:before{
    content:"\e95a"
}
.icon-location-pin:before{
    content:"\e946"
}
.icon-nearby:before{
    content:"\e947"
}
.icon-plans:before{
    content:"\e948"
}
.icon-price-label:before{
    content:"\e949"
}
.icon-ticket:before{
    content:"\e94a"
}
.icon-food:before{
    content:"\e94d"
}
.icon-dislike:before{
    content:"\e94e"
}
.icon-like:before{
    content:"\e94f"
}
.icon-airplane:before{
    content:"\e950"
}
.icon-living-room:before{
    content:"\e945"
}
.icon-location-2:before{
    content:"\e941"
}
.icon-pedestrian:before{
    content:"\e942"
}
.icon-award:before{
    content:"\e943"
}
.icon-location:before{
    content:"\e944"
}
.icon-up-down:before{
    content:"\e940"
}
.icon-newsletter:before{
    content:"\e93f"
}
.icon-close:before{
    content:"\e93e"
}
.icon-kitchen:before{
    content:"\e939"
}
.icon-man:before{
    content:"\e93a"
}
.icon-no-smoke:before{
    content:"\e93b"
}
.icon-parking:before{
    content:"\e93c"
}
.icon-wifi:before{
    content:"\e93d"
}
.icon-city-2:before{
    content:"\e931"
}
.icon-tv:before{
    content:"\e932"
}
.icon-washing-machine:before{
    content:"\e933"
}
.icon-juice:before{
    content:"\e934"
}
.icon-shield:before{
    content:"\e935"
}
.icon-bell-ring:before{
    content:"\e936"
}
.icon-bathtub:before{
    content:"\e937"
}
.icon-check:before{
    content:"\e938"
}
.icon-arrow-right:before{
    content:"\e930"
}
.icon-edit:before{
    content:"\e92c"
}
.icon-eye:before{
    content:"\e92d"
}
.icon-trash-2:before{
    content:"\e92e"
}
.icon-upload-file:before{
    content:"\e92f"
}
.icon-email-2:before{
    content:"\e94b"
}
.icon-notification:before{
    content:"\e94c"
}
.icon-arrow-left:before{
    content:"\e92b"
}
.icon-arrow-sm-left:before{
    content:"\e929"
}
.icon-arrow-sm-right:before{
    content:"\e92a"
}
.icon-fire:before{
    content:"\e900"
}
.icon-globe:before{
    content:"\f0ac";
    font-family: "FontAwesome";
}
.icon-heart:before{
    content:"\e902"
}
.icon-hiking:before{
    content:"\e903"
}
.icon-hiking-2:before{
    content:"\e904"
}
.icon-home:before{
    content:"\e905"
}
.icon-instagram:before{
    content:"\f16d";
    font-family: "FontAwesome";
}
.icon-jeep:before{
    content:"\e907"
}
.icon-linkedin:before{
    content:"\f08c";
    font-family: "FontAwesome";
}
.icon-luggage:before{
    content:"\e909"
}
.icon-menu:before{
    content:"\f550";
    font-family: "FontAwesome";
    color: white;
}
.icon-museum:before{
    content:"\e90b"
}
.icon-nature:before{
    content:"\e90c"
}
.icon-play-market:before{
    content:"\e90d"
}
.icon-search:before{
    content:"\f002";
    font-family:'FontAwesome';
}
.icon-ski:before{
    content:"\e90f"
}
.icon-speedometer:before{
    content:"\e910"
}
.icon-star:before{
    content:"\f005";
    font-family:'FontAwesome';
}
.icon-tickets:before{
    content:"\e912"
}
.icon-transmission:before{
    content:"\e913"
}
.icon-traveller:before{
    content:"\e914"
}
.icon-twitter:before{
    content:"\f099";
    font-family: "FontAwesome";
}
.icon-usd:before{
    content:"\24";
    font-family: "FontAwesome";
}
.icon-user:before{
    content:"\f007";
    font-family: "FontAwesome";
    color: white;
}
.icon-user-2:before{
    content:"\e918"
}
.icon-yatch:before{
    content:"\e919"
}
.icon-minus:before{
    content:"\e91a"
}
.icon-plus:before{
    content:"\e91b"
}
.icon-apple:before{
    content:"\e91c"
}
.icon-chevron-sm-down:before{
    content:"\e91d"
}
.icon-arrow-top-right:before{
    content:"\f061";
    font-family: 'FontAwesome';
    font-size: 21px;
}
.icon-beach-umbrella:before{
    content:"\e91f"
}
.icon-bed:before{
    content:"\e920"
}
.icon-camping:before{
    content:"\e921"
}
.icon-car:before{
    content:"\e922"
}
.icon-chevron-left:before{
    content:"\e923"
}
.icon-chevron-right:before{
    content:"\e924"
}
.icon-city:before{
    content:"\e925"
}
.icon-destination:before{
    content:"\f5a0";
    font-family: "FontAwesome";
}
.icon-email:before{
    content:"\e927"
}
.icon-facebook:before{
    content:"\f39e";
    font-family: "FontAwesome";
}
.noUi-target,.noUi-target *{
    -webkit-touch-callout:none;
    -webkit-tap-highlight-color:transparent;
    -webkit-user-select:none;
    -ms-touch-action:none;
    touch-action:none;
    -ms-user-select:none;
    -moz-user-select:none;
    user-select:none;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
.noUi-target{
    position:relative
}
.noUi-base,.noUi-connects{
    width:100%;
    height:100%;
    position:relative;
    z-index:1
}
.noUi-connects{
    overflow:hidden;
    z-index:0
}
.noUi-connect,.noUi-origin{
    will-change:transform;
    position:absolute;
    z-index:1;
    top:0;
    right:0;
    height:100%;
    width:100%;
    -ms-transform-origin:0 0;
    -webkit-transform-origin:0 0;
    -webkit-transform-style:preserve-3d;
    transform-origin:0 0;
    transform-style:flat
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin{
    left:0;
    right:auto
}
.noUi-vertical .noUi-origin{
    top:-100%;
    width:0
}
.noUi-horizontal .noUi-origin{
    height:0
}
.noUi-handle{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    position:absolute
}
.noUi-touch-area{
    height:100%;
    width:100%
}
.noUi-state-tap .noUi-connect,.noUi-state-tap .noUi-origin{
    -webkit-transition:transform .3s;
    transition:transform .3s
}
.noUi-state-drag *{
    cursor:inherit!important
}
.noUi-horizontal{
    height:18px
}
.noUi-horizontal .noUi-handle{
    width:34px;
    height:28px;
    right:-17px;
    top:-6px
}
.noUi-vertical{
    width:18px
}
.noUi-vertical .noUi-handle{
    width:28px;
    height:34px;
    right:-6px;
    bottom:-17px
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle{
    left:-17px;
    right:auto
}
.noUi-target{
    background:#fafafa;
    border-radius:4px;
    border:1px solid #d3d3d3;
    box-shadow:inset 0 1px 1px #f0f0f0,0 3px 6px -5px #bbb
}
.noUi-connects{
    border-radius:3px
}
.noUi-connect{
    background:#3fb8af
}
.noUi-draggable{
    cursor:ew-resize
}
.noUi-vertical .noUi-draggable{
    cursor:ns-resize
}
.noUi-handle{
    border:1px solid #d9d9d9;
    border-radius:3px;
    background:#fff;
    cursor:default;
    box-shadow:inset 0 0 1px #fff,inset 0 1px 7px #ebebeb,0 3px 6px -3px #bbb
}
.noUi-active{
    box-shadow:inset 0 0 1px #fff,inset 0 1px 7px #ddd,0 3px 6px -3px #bbb
}
.noUi-handle:after,.noUi-handle:before{
    content:"";
    display:block;
    position:absolute;
    height:14px;
    width:1px;
    background:#e8e7e6;
    left:14px;
    top:6px
}
.noUi-handle:after{
    left:17px
}
.noUi-vertical .noUi-handle:after,.noUi-vertical .noUi-handle:before{
    width:14px;
    height:1px;
    left:6px;
    top:14px
}
.noUi-vertical .noUi-handle:after{
    top:17px
}
[disabled] .noUi-connect{
    background:#b8b8b8
}
[disabled].noUi-handle,[disabled] .noUi-handle,[disabled].noUi-target{
    cursor:not-allowed
}
.noUi-pips,.noUi-pips *{
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
.noUi-pips{
    position:absolute;
    color:#999
}
.noUi-value{
    position:absolute;
    white-space:nowrap;
    text-align:center
}
.noUi-value-sub{
    color:#ccc;
    font-size:10px
}
.noUi-marker{
    position:absolute;
    background:#ccc
}
.noUi-marker-large,.noUi-marker-sub{
    background:#aaa
}
.noUi-pips-horizontal{
    padding:10px 0;
    height:80px;
    top:100%;
    left:0;
    width:100%
}
.noUi-value-horizontal{
    -webkit-transform:translate(-50%,50%);
    transform:translate(-50%,50%)
}
.noUi-rtl .noUi-value-horizontal{
    -webkit-transform:translate(50%,50%);
    transform:translate(50%,50%)
}
.noUi-marker-horizontal.noUi-marker{
    margin-left:-1px;
    width:2px;
    height:5px
}
.noUi-marker-horizontal.noUi-marker-sub{
    height:10px
}
.noUi-marker-horizontal.noUi-marker-large{
    height:15px
}
.noUi-pips-vertical{
    padding:0 10px;
    height:100%;
    top:0;
    left:100%
}
.noUi-value-vertical{
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    padding-left:25px
}
.noUi-rtl .noUi-value-vertical{
    -webkit-transform:translateY(50%);
    transform:translateY(50%)
}
.noUi-marker-vertical.noUi-marker{
    width:5px;
    height:2px;
    margin-top:-1px
}
.noUi-marker-vertical.noUi-marker-sub{
    width:10px
}
.noUi-marker-vertical.noUi-marker-large{
    width:15px
}
.noUi-tooltip{
    display:block;
    position:absolute;
    border:1px solid #d9d9d9;
    border-radius:3px;
    background:#fff;
    color:#000;
    padding:5px;
    text-align:center;
    white-space:nowrap
}
.noUi-horizontal .noUi-tooltip{
    -webkit-transform:translate(-50%);
    transform:translate(-50%);
    left:50%;
    bottom:120%
}
.noUi-vertical .noUi-tooltip{
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
    top:50%;
    right:120%
}
.noUi-horizontal .noUi-origin>.noUi-tooltip{
    -webkit-transform:translate(50%);
    transform:translate(50%);
    left:auto;
    bottom:10px
}
.noUi-vertical .noUi-origin>.noUi-tooltip{
    -webkit-transform:translateY(-18px);
    transform:translateY(-18px);
    top:auto;
    right:28px
}
.swiper-container{
    margin:0 auto;
    position:relative;
    overflow:hidden;
    list-style:none;
    padding:0;
    z-index:1
}
.swiper-container-no-flexbox .swiper-slide{
    float:left
}
.swiper-container-vertical>.swiper-wrapper{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -webkit-flex-direction:column;
    -ms-flex-direction:column;
    flex-direction:column
}
.swiper-wrapper{
    position:relative;
    width:100%;
    height:100%;
    z-index:1;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    -o-transition-property:transform;
    transition-property:transform;
    transition-property:transform,-webkit-transform;
    -webkit-box-sizing:content-box;
    box-sizing:content-box
}
.swiper-container-android .swiper-slide,.swiper-wrapper{
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
}
.swiper-container-multirow>.swiper-wrapper{
    -webkit-flex-wrap:wrap;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap
}
.swiper-container-free-mode>.swiper-wrapper{
    -webkit-transition-timing-function:ease-out;
    -o-transition-timing-function:ease-out;
    transition-timing-function:ease-out;
    margin:0 auto
}
.swiper-slide{
    -webkit-flex-shrink:0;
    -ms-flex-negative:0;
    flex-shrink:0;
    width:100%;
    height:100%;
    position:relative;
    -webkit-transition-property:-webkit-transform;
    transition-property:-webkit-transform;
    -o-transition-property:transform;
    transition-property:transform;
    transition-property:transform,-webkit-transform
}
.swiper-slide-invisible-blank{
    visibility:hidden
}
.swiper-container-autoheight,.swiper-container-autoheight .swiper-slide{
    height:auto
}
.swiper-container-autoheight .swiper-wrapper{
    -webkit-box-align:start;
    -webkit-align-items:flex-start;
    -ms-flex-align:start;
    align-items:flex-start;
    -webkit-transition-property:height,-webkit-transform;
    transition-property:height,-webkit-transform;
    -o-transition-property:transform,height;
    transition-property:transform,height;
    transition-property:transform,height,-webkit-transform
}
.swiper-container-3d{
    -webkit-perspective:1200px;
    perspective:1200px
}
.swiper-container-3d .swiper-cube-shadow,.swiper-container-3d .swiper-slide,.swiper-container-3d .swiper-slide-shadow-bottom,.swiper-container-3d .swiper-slide-shadow-left,.swiper-container-3d .swiper-slide-shadow-right,.swiper-container-3d .swiper-slide-shadow-top,.swiper-container-3d .swiper-wrapper{
    -webkit-transform-style:preserve-3d;
    transform-style:preserve-3d
}
.swiper-container-3d .swiper-slide-shadow-bottom,.swiper-container-3d .swiper-slide-shadow-left,.swiper-container-3d .swiper-slide-shadow-right,.swiper-container-3d .swiper-slide-shadow-top{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    pointer-events:none;
    z-index:10
}
.swiper-container-3d .swiper-slide-shadow-left{
    background-image:-webkit-gradient(linear,right top,left top,from(rgba(0,0,0,.5)),to(transparent));
    background-image:-webkit-linear-gradient(right,rgba(0,0,0,.5),transparent);
    background-image:-o-linear-gradient(right,rgba(0,0,0,.5),transparent);
    background-image:linear-gradient(270deg,rgba(0,0,0,.5),transparent)
}
.swiper-container-3d .swiper-slide-shadow-right{
    background-image:-webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.5)),to(transparent));
    background-image:-webkit-linear-gradient(left,rgba(0,0,0,.5),transparent);
    background-image:-o-linear-gradient(left,rgba(0,0,0,.5),transparent);
    background-image:linear-gradient(90deg,rgba(0,0,0,.5),transparent)
}
.swiper-container-3d .swiper-slide-shadow-top{
    background-image:-webkit-gradient(linear,left bottom,left top,from(rgba(0,0,0,.5)),to(transparent));
    background-image:-webkit-linear-gradient(bottom,rgba(0,0,0,.5),transparent);
    background-image:-o-linear-gradient(bottom,rgba(0,0,0,.5),transparent);
    background-image:linear-gradient(0deg,rgba(0,0,0,.5),transparent)
}
.swiper-container-3d .swiper-slide-shadow-bottom{
    background-image:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.5)),to(transparent));
    background-image:-webkit-linear-gradient(top,rgba(0,0,0,.5),transparent);
    background-image:-o-linear-gradient(top,rgba(0,0,0,.5),transparent);
    background-image:linear-gradient(180deg,rgba(0,0,0,.5),transparent)
}
.swiper-container-wp8-horizontal,.swiper-container-wp8-horizontal>.swiper-wrapper{
    -ms-touch-action:pan-y;
    touch-action:pan-y
}
.swiper-container-wp8-vertical,.swiper-container-wp8-vertical>.swiper-wrapper{
    -ms-touch-action:pan-x;
    touch-action:pan-x
}
.swiper-button-next,.swiper-button-prev{
    position:absolute;
    top:50%;
    width:27px;
    height:44px;
    margin-top:-22px;
    z-index:10;
    cursor:pointer;
    background-size:27px 44px;
    background-position:50%;
    background-repeat:no-repeat
}
.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{
    opacity:.35;
    cursor:auto;
    pointer-events:none
}
.swiper-button-prev,.swiper-container-rtl .swiper-button-next{
    background-image:url();
    left:10px;
    right:auto
}
.swiper-button-next,.swiper-container-rtl .swiper-button-prev{
    background-image:url();
    right:10px;
    left:auto
}
.swiper-button-prev.swiper-button-white,.swiper-container-rtl .swiper-button-next.swiper-button-white{
    background-image:url()
}
.swiper-button-next.swiper-button-white,.swiper-container-rtl .swiper-button-prev.swiper-button-white{
    background-image:url()
}
.swiper-button-prev.swiper-button-black,.swiper-container-rtl .swiper-button-next.swiper-button-black{
    background-image:url()
}
.swiper-button-next.swiper-button-black,.swiper-container-rtl .swiper-button-prev.swiper-button-black{
    background-image:url()
}
.swiper-button-lock{
    display:none
}
.swiper-pagination{
    position:absolute;
    text-align:center;
    -webkit-transition:opacity .3s;
    -o-transition:.3s opacity;
    transition:opacity .3s;
    -webkit-transform:translateZ(0);
    transform:translateZ(0);
    z-index:10
}
.swiper-pagination.swiper-pagination-hidden{
    opacity:0
}
.swiper-container-horizontal>.swiper-pagination-bullets,.swiper-pagination-custom,.swiper-pagination-fraction{
    bottom:10px;
    left:0;
    width:100%
}
.swiper-pagination-bullets-dynamic{
    overflow:hidden;
    font-size:0
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    -webkit-transform:scale(.33);
    -ms-transform:scale(.33);
    transform:scale(.33);
    position:relative
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main{
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    transform:scale(1)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev{
    -webkit-transform:scale(.66);
    -ms-transform:scale(.66);
    transform:scale(.66)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev{
    -webkit-transform:scale(.33);
    -ms-transform:scale(.33);
    transform:scale(.33)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next{
    -webkit-transform:scale(.66);
    -ms-transform:scale(.66);
    transform:scale(.66)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next{
    -webkit-transform:scale(.33);
    -ms-transform:scale(.33);
    transform:scale(.33)
}
.swiper-pagination-bullet{
    width:8px;
    height:8px;
    display:inline-block;
    border-radius:100%;
    background:#000;
    opacity:.2
}
button.swiper-pagination-bullet{
    border:none;
    margin:0;
    padding:0;
    -webkit-box-shadow:none;
    box-shadow:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none
}
.swiper-pagination-clickable .swiper-pagination-bullet{
    cursor:pointer
}
.swiper-pagination-bullet-active{
    opacity:1;
    background:#007aff
}
.swiper-container-vertical>.swiper-pagination-bullets{
    right:10px;
    top:50%;
    -webkit-transform:translate3d(0,-50%,0);
    transform:translate3d(0,-50%,0)
}
.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet{
    margin:6px 0;
    display:block
}
.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
    top:50%;
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    transform:translateY(-50%);
    width:8px
}
.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    display:inline-block;
    -webkit-transition:top .2s,-webkit-transform .2s;
    transition:top .2s,-webkit-transform .2s;
    -o-transition:.2s transform,.2s top;
    transition:transform .2s,top .2s;
    transition:transform .2s,top .2s,-webkit-transform .2s
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
    margin:0 4px
}
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
    left:50%;
    -webkit-transform:translateX(-50%);
    -ms-transform:translateX(-50%);
    transform:translateX(-50%);
    white-space:nowrap
}
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    -webkit-transition:left .2s,-webkit-transform .2s;
    transition:left .2s,-webkit-transform .2s;
    -o-transition:.2s transform,.2s left;
    transition:transform .2s,left .2s;
    transition:transform .2s,left .2s,-webkit-transform .2s
}
.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
    -webkit-transition:right .2s,-webkit-transform .2s;
    transition:right .2s,-webkit-transform .2s;
    -o-transition:.2s transform,.2s right;
    transition:transform .2s,right .2s;
    transition:transform .2s,right .2s,-webkit-transform .2s
}
.swiper-pagination-progressbar{
    background:rgba(0,0,0,.25);
    position:absolute
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    background:#007aff;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    -webkit-transform:scale(0);
    -ms-transform:scale(0);
    transform:scale(0);
    -webkit-transform-origin:left top;
    -ms-transform-origin:left top;
    transform-origin:left top
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    -webkit-transform-origin:right top;
    -ms-transform-origin:right top;
    transform-origin:right top
}
.swiper-container-horizontal>.swiper-pagination-progressbar,.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite{
    width:100%;
    height:4px;
    left:0;
    top:0
}
.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,.swiper-container-vertical>.swiper-pagination-progressbar{
    width:4px;
    height:100%;
    left:0;
    top:0
}
.swiper-pagination-white .swiper-pagination-bullet-active{
    background:#fff
}
.swiper-pagination-progressbar.swiper-pagination-white{
    background:hsla(0,0%,100%,.25)
}
.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill{
    background:#fff
}
.swiper-pagination-black .swiper-pagination-bullet-active{
    background:#000
}
.swiper-pagination-progressbar.swiper-pagination-black{
    background:rgba(0,0,0,.25)
}
.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill{
    background:#000
}
.swiper-pagination-lock{
    display:none
}
.swiper-scrollbar{
    border-radius:10px;
    position:relative;
    -ms-touch-action:none;
    background:rgba(0,0,0,.1)
}
.swiper-container-horizontal>.swiper-scrollbar{
    position:absolute;
    left:1%;
    bottom:3px;
    z-index:50;
    height:5px;
    width:98%
}
.swiper-container-vertical>.swiper-scrollbar{
    position:absolute;
    right:3px;
    top:1%;
    z-index:50;
    width:5px;
    height:98%
}
.swiper-scrollbar-drag{
    height:100%;
    width:100%;
    position:relative;
    background:rgba(0,0,0,.5);
    border-radius:10px;
    left:0;
    top:0
}
.swiper-scrollbar-cursor-drag{
    cursor:move
}
.swiper-scrollbar-lock{
    display:none
}

.swiper-zoom-container{
    width:100%;
    height:100%;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -webkit-align-items:center;
    -ms-flex-align:center;
    align-items:center;
    text-align:center
}
.swiper-zoom-container>canvas,.swiper-zoom-container>img,.swiper-zoom-container>svg{
    max-width:100%;
    max-height:100%;
    -o-object-fit:contain;
    object-fit:contain
}
.swiper-slide-zoomed{
    cursor:move
}
.swiper-lazy-preloader{
    width:42px;
    height:42px;
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-21px;
    margin-top:-21px;
    z-index:10;
    -webkit-transform-origin:50%;
    -ms-transform-origin:50%;
    transform-origin:50%;
    -webkit-animation:swiper-preloader-spin 1s steps(12) infinite;
    animation:swiper-preloader-spin 1s steps(12) infinite
}
.swiper-lazy-preloader:after{
    display:block;
    content:"";
    width:100%;
    height:100%;
    background-image:url();
    background-position:50%;
    background-size:100%;
    background-repeat:no-repeat
}
.swiper-lazy-preloader-white:after{
    background-image:url()
}
@-webkit-keyframes swiper-preloader-spin{
    to{
        -webkit-transform:rotate(1turn);
        transform:rotate(1turn)
    }
}
@keyframes swiper-preloader-spin{
    to{
        -webkit-transform:rotate(1turn);
        transform:rotate(1turn)
    }
}
.swiper-container .swiper-notification{
    position:absolute;
    left:0;
    top:0;
    pointer-events:none;
    opacity:0;
    z-index:-1000
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide{
    -webkit-transition-timing-function:ease-out;
    -o-transition-timing-function:ease-out;
    transition-timing-function:ease-out
}
.swiper-container-fade .swiper-slide{
    pointer-events:none;
    -webkit-transition-property:opacity;
    -o-transition-property:opacity;
    transition-property:opacity
}
.swiper-container-fade .swiper-slide .swiper-slide{
    pointer-events:none
}
.swiper-container-fade .swiper-slide-active,.swiper-container-fade .swiper-slide-active .swiper-slide-active{
    pointer-events:auto
}
.swiper-container-cube{
    overflow:visible
}
.swiper-container-cube .swiper-slide{
    pointer-events:none;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    z-index:1;
    visibility:hidden;
    -webkit-transform-origin:0 0;
    -ms-transform-origin:0 0;
    transform-origin:0 0;
    width:100%;
    height:100%
}
.swiper-container-cube .swiper-slide .swiper-slide{
    pointer-events:none
}
.swiper-container-cube.swiper-container-rtl .swiper-slide{
    -webkit-transform-origin:100% 0;
    -ms-transform-origin:100% 0;
    transform-origin:100% 0
}
.swiper-container-cube .swiper-slide-active,.swiper-container-cube .swiper-slide-active .swiper-slide-active{
    pointer-events:auto
}
.swiper-container-cube .swiper-slide-active,.swiper-container-cube .swiper-slide-next,.swiper-container-cube .swiper-slide-next+.swiper-slide,.swiper-container-cube .swiper-slide-prev{
    pointer-events:auto;
    visibility:visible
}
.swiper-container-cube .swiper-slide-shadow-bottom,.swiper-container-cube .swiper-slide-shadow-left,.swiper-container-cube .swiper-slide-shadow-right,.swiper-container-cube .swiper-slide-shadow-top{
    z-index:0;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden
}
.swiper-container-cube .swiper-cube-shadow{
    position:absolute;
    left:0;
    bottom:0;
    width:100%;
    height:100%;
    background:#000;
    opacity:.6;
    -webkit-filter:blur(50px);
    filter:blur(50px);
    z-index:0
}
.swiper-container-flip{
    overflow:visible
}
.swiper-container-flip .swiper-slide{
    pointer-events:none;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    z-index:1
}
.swiper-container-flip .swiper-slide .swiper-slide{
    pointer-events:none
}
.swiper-container-flip .swiper-slide-active,.swiper-container-flip .swiper-slide-active .swiper-slide-active{
    pointer-events:auto
}
.swiper-container-flip .swiper-slide-shadow-bottom,.swiper-container-flip .swiper-slide-shadow-left,.swiper-container-flip .swiper-slide-shadow-right,.swiper-container-flip .swiper-slide-shadow-top{
    z-index:0;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden
}
.swiper-container-coverflow .swiper-wrapper{
    -ms-perspective:1200px
}
